import { Component, OnInit } from '@angular/core';
import { RecentlyService } from '../../../app/pages/recently-spacifii/recently.service';

@Component({
  selector: 'app-we-create-great-things',
  templateUrl: './we-create-great-things.component.html',
  styleUrls: ['./we-create-great-things.component.scss']
})
export class WeCreateGreatThingsComponent implements OnInit {
  defaultImage="https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png";
  recentlySpacifiis = [
    {
      img : "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png"
    },
    {
      img : "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/19.jpg"
    },
    {
      img : "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/20.jpg"
    },
    {
      img : "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/21.jpg"
    }
  ];
  recentlySpacifiiProjectObj: any;

  
  constructor(
    private recentService: RecentlyService
  ) { }

  ngOnInit() {
    let _filter = {
      "page": 1,
      "size": 1000
    }
    this.getRecentlySpacifii(_filter);
  }

  getRecentlySpacifii(_filter){
    this.recentService.getRecentlySpacifii(_filter).subscribe((res:any)=>{
      this.recentlySpacifiiProjectObj = res.payload;
      this.recentlySpacifiis = this.recentlySpacifiiProjectObj.content;
    },
    (error)=>{
      console.log("Error while feaching recently...");
    })
  }
}

