import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-estimate',
  templateUrl: './home-estimate.component.html',
  styleUrls: ['./home-estimate.component.scss']
})
export class HomeEstimateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
