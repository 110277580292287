import {Component, ViewChild, AfterViewInit, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';


@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
})

export class SliderComponent implements AfterViewInit {

    public show: boolean = true;
    public slides = [
        {
            image: 'https://spacifii-images.s3.ap-south-1.amazonaws.com/frontend/banner31.webp',
            heading:
                {
                    name: 'Your SPACE...',
                    name1: 'Now Done Your Way',
                    fontSize: '40px'
                }
            ,
            subheading1: 'Stop guessing, Pick the one you like, we bring',
            subheading2: 'you a wide range of design inspiration',
            subheading3: 'from the Best interior designer',
            desc: '',
            buttons: [],
            buttonss: [
                {
                    name: 'Free Consultation',
                    tooltip: 'Free Consultation',
                    actionType: 'Click',
                    actionURL: '/contact-us',
                    isExternal: false
                },
                // {
                //     name: 'Want your space executed, Click Here!',
                //     tooltip: 'Start the Process!',
                //     actionType: 'Click',
                //     actionURL: '/recently',
                //     isExternal: false
                // }
            ]
        }
       // {
           
        //     image: '/assets/banner1.jpg',
        //     heading:
        //         {
        //             name: 'SPACIFII',
        //             cssClass: ''
        //         }
        //     ,
        //     subheading: 'CREATING SPACES THAT MATTER',
        //     desc: '',
        //     buttons: [],
        //     buttonss: [{
        //         name: 'About Us',
        //         tooltip: 'Read what we are Doing',
        //         actionType: 'Click',
        //         actionURL: '/about-us',
        //         isExternal: false
        //     }, {
        //         name: 'How it Works',
        //         tooltip: 'Read about how we do things',
        //         actionType: 'Click',
        //         actionURL: '/how-it-works',
        //         isExternal: 'false'
        //     }, {
        //         name: 'Customer Registration',
        //         tooltip: 'Register Now and get SPACIFIED NOW',
        //         actionType: 'Click',
        //         actionURL: '/registration/customer',
        //         isExternal: 'false'
        //     },
        //         // {
        //         //     name: 'Partner Registration',
        //         //     tooltip: 'Register Now and get SPACIFIED NOW',
        //         //     actionType: 'Click',
        //         //     actionURL: '/registration/partner',
        //         //     isExternal: 'false'
        //         // }
        //     ]
        // },
        // {
        //     image: '/assets/banner2.webp',
        //     heading:
        //         {
        //             name: 'TIE',
        //             fontSize: '40px'
        //         }
        //     ,
        //     subheading: 'Technology in Interiors & Execution',
        //     desc: 'hassel-free estimate based 2',
        //     buttons: [],
        //     buttonss: [{
        //         name: 'Our Solutions',
        //         tooltip: 'Check out our Technology',
        //         actionType: 'Click',
        //         actionURL: '/about-us',
        //         isExternal: false
        //     }, {
        //         name: 'How it Works',
        //         tooltip: 'Read about how we employ technology to change your spaces',
        //         actionType: 'Click',
        //         actionURL: '/how-it-works',
        //         isExternal: 'false'
        //     }, {
        //         name: 'Customer Benefits',
        //         tooltip: 'Register Now and get SPACIFIED NOW',
        //         actionType: 'Click',
        //         actionURL: '/registration/customer',
        //         isExternal: 'false'
        //     }
        //         , {
        //             name: 'Partner Nenefits',
        //             tooltip: 'Register Now and get SPACIFIED NOW',
        //             actionType: 'Click',
        //             actionURL: '/registration/partner',
        //             isExternal: 'false'
        //         }
        //     ]
        // },

      

    ];
    public type: string = 'component';
    public disabled: boolean = false;
    public config: SwiperConfigInterface = {};
    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true,
        hideOnClick: false
    };
    @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

    ngAfterViewInit() {
        this.config = {
            direction: 'horizontal',
            slidesPerView: 1,
            navigation: true,
            pagination: this.pagination,
            loop: true,
            effect: 'fade',
            autoplay: true,
            allowTouchMove:true
        };
    }


    constructor() {
    }

    GetTarget(isExternal: boolean | string) {
        if (isExternal) {
            return '_blank';
        } else {
            return "_self";
        }
    }
}
