import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-hoverstyle',
  templateUrl: './hoverstyle.component.html',
  styleUrls: ['./hoverstyle.component.scss']
})
export class HoverstyleComponent implements OnInit {
  @Input() singlePageObject:any;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
  getSantizeVideoUrl(_inData) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(_inData.replace("watch?v=", "embed/"));
    }

}
