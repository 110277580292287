import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-financing-options',
  templateUrl: './financing-options.component.html',
  styleUrls: ['./financing-options.component.scss']
})
export class FinancingOptionsComponent implements OnInit {
  purposes = [];
  customerFinanceForm: FormGroup;
  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<FinancingOptionsComponent>,
              private _formBuilder: FormBuilder,
              private router: Router,
              private toastr: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.customerFinanceForm = this._formBuilder.group({
      firstname: ['', [Validators.required, Validators.minLength(6)]],
      lastname: ['', [Validators.required, Validators.minLength(6)]],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[6-9]\\d{9}')]],
      email: ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      pan: ['', [Validators.required, Validators.minLength(6)]],
      aadhaar: ['', [Validators.required, Validators.minLength(6)]],
      dob: ['', Validators.required],
      city: ['', Validators.required],
      duration: ['', Validators.required],
      financed:['', Validators.required],
      loanAmount:['', Validators.required],
    });

    this.customerFinanceForm.patchValue(
        {
          loanAmount: this.data.amount
        }
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
  customerSubmit() {
    
  }
}
