import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecentlyService } from '../recently.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-recently-spacifii-image-detail',
  templateUrl: './recently-spacifii-image-detail.component.html',
  styleUrls: ['./recently-spacifii-image-detail.component.scss'],
  
})
export class RecentlySpacifiiImageDetailComponent implements OnInit {

  projectId: any;
  recentlyProjectObj: any;
  defaultProfileImage: any;
  projectComments: any = [];
  
  constructor(
    private route:ActivatedRoute,
    private recentlyService : RecentlyService,
  ) { 
    this.defaultProfileImage = environment.defaultProfileImage;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.projectId = params['projectId'];
      this.getRecentlySpacifiiProject(this.projectId);
		});
  }

  getRecentlySpacifiiProject(_projectId){
    this.recentlyService.getRecentlyProjectById(_projectId).subscribe((res:any)=>{
      this.recentlyProjectObj = res.payload;
    //  console.log(this.recentlyProjectObj);
    },
    (error:any)=>{
      console.log("Error Get project ======>> ", error);
    })
  }

  replyFormSubmit(){

  }
}
