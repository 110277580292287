import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddressComponent } from './address/address.component';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { ContactComponent } from './contact/contact.component';
import { EditEmailComponent } from './edit-email/edit-email.component';
import { EditMobileComponent } from './edit-mobile/edit-mobile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BankInformationComponent } from './bank-information/bank-information.component';
import { DocumentsComponent } from './documents/documents.component';
import { PersonalProfileComponent } from './personal-profile/personal-profile.component';

const routes: Routes = [
  { path: 'profile/address', component: AddressComponent},
  { path: 'profile/business-profile', component: BusinessProfileComponent},
  { path: 'profile/personal-profile', component: PersonalProfileComponent},
  { path: 'profile/contact', component: ContactComponent},
  { path: 'profile/edit-email', component:EditEmailComponent},
  { path: 'profile/edit-mobile', component:EditMobileComponent},
  { path: 'profile/change-password', component:ChangePasswordComponent},
  { path: 'profile/bank-information', component:BankInformationComponent},
  { path: 'profile/documents', component:DocumentsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
