import { Component, OnInit, Input } from '@angular/core';
import { ProjectDetailService } from '../../../app/services/project-detail.service';
import { FormBuilder, FormGroup, Validator, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';


@Component({
    selector: 'project-comments',
    templateUrl: './project-comments.component.html',
    styleUrls: ['./project-comments.component.scss']
})
export class ProjectCommentsComponent implements OnInit {

    @Input() projectid: string;
    commentForm: FormGroup;
    replyForm: FormGroup;
    projectComments: any = [];
    defaultProfileImage: string;
    booleanView: boolean = false;

    constructor(
        private projectdetailService: ProjectDetailService,
        private formBuilder: FormBuilder,
    ) {
        this.defaultProfileImage = environment.defaultProfileImage;
        this.commentForm = this.formBuilder.group({
            details: ['', Validators.required],
            projectId: [this.projectid, Validators.required],
            mediaUserActionType: ['COMMENT', Validators.required],
            parentCommentId: [null]
        });

        this.replyForm = this.formBuilder.group({
            details: ['', Validators.required],
            projectId: [this.projectid, Validators.required],
            mediaUserActionType: ['COMMENT', Validators.required],
            parentCommentId: [null]
        });
    }

    ngOnInit() {
        this.getComments();
        this.commentForm.reset();
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.replyForm.controls[controlName].hasError(errorName);
    };

    public hasErrorComment = (controlName: string, errorName: string) => {
        return this.commentForm.controls[controlName].hasError(errorName);
    };

    getComments() {
        this.projectdetailService.getAllCommentsForProject(this.projectid).subscribe((res: any) => {
            this.projectComments = res.payload ? res.payload : [];
        },
            (error: any) => {
              //  console.log('Error get comment===> ', error);
            });
    }

    commentFormSubmit() {
        this.commentForm.controls['mediaUserActionType'].setValue('COMMENT');
        this.commentForm.controls['projectId'].setValue(this.projectid);
        if (this.commentForm.invalid) {
            return false;
        }
        let _commentObj = this.commentForm.value;
        this.projectdetailService.postProjectUserAction(_commentObj).subscribe((res: any) => {
            this.commentForm.reset();
            this.getComments();
        },
            (error: any) => {
              //  console.log('Error => ', error);
            });
    }


    replyComment() {
        this.booleanView = true;
    }

    deleteComment() {

    }

    editComment() {

    }
    replyFormSubmit(_parent: any) {
        if(this.replyForm.invalid){
            return false;
        }
        this.replyForm.controls['mediaUserActionType'].setValue('COMMENT');
        this.replyForm.controls['projectId'].setValue(this.projectid);
        this.replyForm.controls['parentCommentId'].setValue(_parent.projectComment.commentId);
        if (this.replyForm.invalid) {
            return false;
        }
        let _commentObj = this.replyForm.value;
        this.projectdetailService.postProjectUserAction(_commentObj).subscribe((res: any) => {
            this.replyForm.reset();
            this.getComments();
        },
            (error: any) => {
                //console.log("Error => ", error);
            });
    }
}
