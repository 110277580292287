import { Component, OnInit, Input } from '@angular/core';
import {MasterService} from '../../services/master.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
@Component({
	selector: 'app-now-trending',
	templateUrl: './now-trending.component.html',
	styleUrls: ['./now-trending.component.scss']
})
export class NowTrendingComponent implements OnInit {

	searchFilterObject: any;
	searchResultObject: any;
	totalElements: any;

	videoImage: any;
	pdfImage:any;

	@Input() onPage: any;

	/*public trendings = [
		{
			image: "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/now-trending/1.jpg",
			imagecaption: "Living Room-Title 1",
			// iconClass: "assets/now-trending/image.png"

		},
		{
			image: "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/now-trending/1.jpg",
			imagecaption: "Living Room-Title 2",
			// iconClass: "assets/now-trending/image.png"
		},
		{
			image: "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/now-trending/1.jpg",
			imagecaption: "Living Room-Title 3",
			// iconClass: "assets/now-trending/image.png"
		},
		{
			image: "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/now-trending/1.jpg",
			imagecaption: "Living Room-Title 4",
			// iconClass: "assets/now-trending/image.png"
		},
		{
			image: "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/now-trending/1.jpg",
			imagecaption: "Living Room-Title 5",
			// iconClass: "assets/now-trending/image.png"
		},
		{
			image: "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/now-trending/1.jpg",
			imagecaption: "Living Room-Title 6",
			// iconClass: "assets/now-trending/image.png"
		}
	];*/

	constructor(
		private masterService: MasterService,
		private route: ActivatedRoute,
		private router: Router
	) { }

	ngOnInit() {

		this.videoImage = environment.videoImage;
		this.pdfImage = environment.pdfImage;

		this.searchFilterObject = {
			"page": 1,
			"size": 6
		};

		this.searchFilterObject.direction="DESC";
		this.searchFilterObject.sortKeys=['mediaUserActionStatistics.viewCount'];

		this.masterService.getMediaSearch(this.searchFilterObject).subscribe((res: { payload: any; }) => {
			if (res && res.payload) {
				this.searchResultObject = res.payload;
				this.totalElements = res.payload.totalElements;
			} else {
				this.searchResultObject = [];
				this.totalElements = 0;
			}
		});

	}



	viewImage(id: any) {
		//var url = '/explore/image/' + id;
		//var url = '/explore?imageid='+id;
		//url = decodeURIComponent(url);
		//console.log(url);
		//this.router.navigate([url]);
		//document.location.replace(decodeURIComponent(url));

		this.router.navigate(['/explore/showcase'], { queryParams: { imageId: id } });
	}

	viewProject(projectId: any) {
		var url = '/project/detail/'+projectId;
		this.router.navigate([url]);
	}
}

