import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'timeago' })
export class Timeago implements PipeTransform {
    transform(value:string): string {
        let currentTIme:number = new Date().getTime();
        let diff: number = currentTIme - parseInt(value);
        let finalText: string;
        let sec = diff/1000;
        if(sec > 59){
            let min = sec/60;
            if(min > 59){
                let hours = min/60;
                if(hours > 23){
                    let days = hours/24;
                    if(days > 29){
                        let months = days/30;
                        if(months > 11){
                            finalText = Math.round(months/12) + ' years';
                        }else{
                            finalText = Math.round(months) + ' months';
                        }
                    }else{
                        finalText = Math.round(days) + ' days'
                    }
                }else{
                    finalText = Math.round(hours) + ' hours'
                }
            }else{
                finalText = Math.round(min) + ' min';
            }
        }else{
            finalText = Math.round(sec) + ' sec';
        }
        return finalText + ' ago';
    }
}