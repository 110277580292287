import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { UserProfileService } from '../../../services/user-profile.service';
import { FormBuilder, FormGroup, Validators, FormControl, Form } from '@angular/forms';
import { LoginService } from '../../../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
	selector: 'app-edit-mobile',
	templateUrl: './edit-mobile.component.html',
	styleUrls: ['./edit-mobile.component.scss']
})
export class EditMobileComponent implements OnInit {

  myProfile:any;
  frmmobileUpdate: FormGroup;

  myReturnObject: any;
  validateShow: boolean = false;
  otpGenerated: boolean = false;
  resendcount:any=0;
  submittedOTPGeneration = false;
  submittedOTP = false;


  constructor(
      public localStorageService:LocalStorageService,
      public userProfileService:UserProfileService,
      private _formBuilder: FormBuilder,
      private loginService: LoginService,
      private toastr: ToastrService
  ) { }

// convenience getter for easy access to form fields
  get f() { return this.frmmobileUpdate.controls; }


  ngOnInit() {
    this.myProfile = this.loginService.getMyProfile();
    this.frmmobileUpdate = this._formBuilder.group({
      oldmobileNumber: ['', Validators.required],
      newmobileNumber: ['', Validators.required],
      newOTP: ['', Validators.required]
    });
    this.frmmobileUpdate.setValue({
      "oldmobileNumber":this.myProfile.userProfile.mobile,
      "newmobileNumber":'',
      "newOTP":'',
    })

    this.validateShow = false;
    this.otpGenerated = false;
	}

	generateOTP() {
      // stop here if form is invalid
      this.submittedOTPGeneration = true;
      if(!this.f.newmobileNumber.valid)
      {
        return;
      }
      if(this.resendcount < 3)
      {
        this.userProfileService.changeMobileStepOne(this.frmmobileUpdate.value.newmobileNumber).subscribe(res => {
          this.myReturnObject = res.payload;
          this.validateShow = true;
          this.otpGenerated = true;
          this.toastr.success("Your OTP has been sent to the new Mobile Number!","");
          this.resendcount = this.resendcount+1;
          this.submittedOTPGeneration = false;
        })
      }
      else{
        this.toastr.error("You have exceeded the Number of OTP Generation Attempts. PLease try again later","");
      }
	}


	ValdiateOTP() {

      this.submittedOTP = true;
      if (this.frmmobileUpdate.invalid) {
        return;
      }
      let otpRequest:any = {
        "mobile": "string",
        "otp": "string",
        "otpReference": "string"
      }
      otpRequest.mobile =  this.frmmobileUpdate.value.newmobileNumber;
      otpRequest.otp =  this.frmmobileUpdate.value.newOTP;
      otpRequest.otpReference =  this.myReturnObject.otpReference;
      this.userProfileService.changeMobileWithOTP(otpRequest).subscribe(res => {
            this.toastr.success("Your New Number has been updated successfully","");
            this.myProfile.userProfile = res.payload;
            this.loginService.setMyUserProfile(this.myProfile.userProfile);
            this.resendcount =0;
            this.ngOnInit();
            this.f.errors.clearValidators();
//            this.g.errors.clearValidators();
            this.submittedOTP = false;
          },
          (error) => {
           // console.log(error);
          });
	}
}
