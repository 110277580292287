import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecentlyComponent } from './recently/recently.component';
import { RecentlySpacifiiImageDetailComponent } from './recently-spacifii-image-detail/recently-spacifii-image-detail.component';
const routes: Routes = [{
  path: '',
  children: [
    {
      path: '',
      component: RecentlyComponent
    },
    {
      path: 'space/:recentlyType',
      component: RecentlyComponent
    },
    {
      path: 'space-detail/:projectId',
      component: RecentlySpacifiiImageDetailComponent
    },
    {
      path: '**',
      component: RecentlyComponent
    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecentlySpacifiiRoutingModule { }
