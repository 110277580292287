import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor() {}

  returnFormObject(form) {
    return {
      ...form,
      key: form.key,
      type: form.type ? form.type : 'input',
      templateOptions: {
        ...form.templateOptions,
        label: form.label,
        placeholder: form.placeholder
          ? form.placeholder
          : `Enter ${form.label}`,
        required: form.required ? form.required : false,
      },
    };
  }

  createFormStructure(formArray) {
    let formData = [];
    formArray.forEach((form) => {
      formData.push(this.returnFormObject(form));
    });
    return formData;
  }
}
