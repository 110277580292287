import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'short-links',
  templateUrl: './short-links.component.html',
  styleUrls: ['./short-links.component.scss']
})
export class ShortLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
