import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material.module';
import { RecentlyComponent } from './recently/recently.component';
import { RecentlySpacifiiImageDetailComponent } from './recently-spacifii-image-detail/recently-spacifii-image-detail.component';
import { RecentlySpacifiiHeaderComponent } from './recently-spacifii-header/recently-spacifii-header.component';
import { RecentlySpacifiiRoutingModule } from './recently-spacifii-routing.module';
import { SharedModuleModule } from '../../../app/shared/shared-module.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RecentlySpacifiiRoutingModule,
    SharedModuleModule
  ],
  declarations: [
    RecentlyComponent,
    RecentlySpacifiiImageDetailComponent,
    RecentlySpacifiiHeaderComponent
  ]
})

export class RecentlySpacifiiModule {

}