// import { Component,  ViewChild,  AfterViewInit} from '@angular/core';
// import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';


// @Component({
//   selector: 'app-why-people-love-us',
//   templateUrl: './why-people-love-us.component.html',
//   styleUrls: ['./why-people-love-us.component.scss']
// })
// export class WhyPeopleLoveUsComponent implements AfterViewInit {

  // public show: boolean = true;
  // firstCount = 191;
  // showTxt = "Show More";
  // last_index = 191;
  // counter = 191;
  // public showBox: boolean= true;
  // public testimonials = [
  //   {
  //     text: "My family is really pleased and impressed with the unique looks and touches you have given to our new home. Your creative ideas really turned out to be amazing which in turn made our home an exception. I was simply delighted with your prompt personal attention and quick responses and also that you were independently able to manage the renovating process while we were busy on other matters.",
  //     image: 'https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/shreysh+iyyer.webp',
  //     name: "Shreyas Iyer",
  //     detail: "CRICKETER",
  //     alt:"shreyas iyer image"
  //   },
  //   {
  //     text: "SPACIFII experts met me when I was getting my apartment furnished, They were prompt efficient coordination with different agencies and stakeholders is something that needs a special mention. There is this assuring unbelievable aura that you experience while working with ​them. ‎I am glad that I had a chance to work with ​SPACIFII and thanks for the excellent work they have​ delivered. My best wishes to them​!",
  //     image: 'https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/kulkarni.webp',
  //     name: "Girish Kulkarni",
  //     detail: "MD",
  //     alt:"girish kulkarni image"
  //   },
  //   {
  //     text: "Spacifii did a fantastic job for my beach house and  were very creative. They​ have great sense of design, proportion,space and colour and​ delivered a​n elegant outcome and timely service...",
  //     image: 'https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/adityram.webp',
  //     name: "Adityaram",
  //     detail: "ADITYARAM GROUP",
  //     alt:"adityaram image"
  //   }


  // ];

  // public type: string = 'component';

  // public disabled: boolean = false;

  // public config: SwiperConfigInterface = {};


  // private pagination: SwiperPaginationInterface = {
  //   el: '.swiper-pagination',
  //   clickable: true,

  // };

  // @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  // constructor() { }
  // ngAfterViewInit() {
  //   this.config = {
  //     slidesPerView: 1,
  //     pagination: this.pagination,
  //     autoplay: false,
  //     loop: true,
  //     effect: "slide",
  //     autoHeight: true
  //   }
  // }
  // ngOnInit(){
//     this.last_index = (this.testimonials[0].text.substring(0, 191)).lastIndexOf(' ');
//  if(this.last_index > 191)
//    this.last_index = 191;
//  this.counter = this.last_index;
//}

//   toggleSkil(){
//     if(this.counter < 191 )
//       {
//         this.counter = this.testimonials[0].text.length;
//         this.showTxt = "Show less";
//          this.showBox = false;
//       }
//       else {
//         this.counter = this.last_index;
//         this.showTxt = "Show More";
//         this.showBox = !this.showBox;
//       }
//   }
  //}
  import { Component,  ViewChild,  AfterViewInit} from '@angular/core';
  import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
  
  
  @Component({
    selector: 'app-why-people-love-us',
    templateUrl: './why-people-love-us.component.html',
    styleUrls: ['./why-people-love-us.component.scss']
  })
  export class WhyPeopleLoveUsComponent implements AfterViewInit {
  
    public show: boolean = true;
  
   public testimonials = [
    {
      text: "My family is really pleased and impressed with the unique looks and touches you have given to our new home. Your creative ideas really turned out to be amazing which in turn made our home an exception. I was simply delighted with your prompt personal attention and quick responses and also that you were independently able to manage the renovating process while we were busy on other matters.",
      image: 'https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/shreysh+iyyer.webp',
      name: "Shreyas Iyer",
      detail: "CRICKETER",
      alt:"shreyas iyer image"
    },
    {
      text: "SPACIFII experts met me when I was getting my apartment furnished, They were prompt efficient coordination with different agencies and stakeholders is something that needs a special mention. There is this assuring unbelievable aura that you experience while working with ​them. ‎I am glad that I had a chance to work with ​SPACIFII and thanks for the excellent work they have​ delivered. My best wishes to them​!",
      image: 'https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/kulkarni.webp',
      name: "Girish Kulkarni",
      detail: "MD",
      alt:"girish kulkarni image"
    },
    {
      text: "Spacifii did a fantastic job for my beach house and  were very creative. They​ have great sense of design, proportion,space and colour and​ delivered a​n elegant outcome and timely service...",
      image: 'https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/adityram.webp',
      name: "Adityaram",
      detail: "ADITYARAM GROUP",
      alt:"adityaram image"
    }
  ];
  
    public type: string = 'component';
  
    public disabled: boolean = false;
  
    public config: SwiperConfigInterface = {};
  
  
    private pagination: SwiperPaginationInterface = {
      el: '.swiper-pagination',
      clickable: true,
  
    };
  
    @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  
    ngAfterViewInit() {
      this.config = {
        slidesPerView: 1,
        pagination: this.pagination,
        autoplay: false,
        loop: true,
        effect: "slide",
        autoHeight: true
      }
    }
    
    constructor() { }
    
  }
  