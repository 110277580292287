import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormService } from "../../services/form.service";
import { FormGroup } from "@angular/forms";
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { SpacifiiService } from "../../services/spacifii.service";
import swal from 'sweetalert';
import { MatDialog, MatDialogRef } from "@angular/material";
import { LocalStorageService } from 'angular-web-storage';
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  captchaVerified: boolean;
  @ViewChild("callAPIDialog") callAPIDialog: TemplateRef<any>;
  message:string='';
  name:string = '';
  constructor(
    private formSchema: FormService,
    private router: Router,
    public dialog: MatDialog,
    private spacifiiService: SpacifiiService,
    public localStorageService:LocalStorageService,
    private loginService: LoginService
  ) { }

  showEstimate: boolean = false;
  showTrending: boolean = false;
  showPublicboards: boolean = false;
  showThings: boolean = false;
  showProduct: boolean = false;
  showArticles: boolean = false;
  showPeopleLove: boolean = false;
  showOffers: boolean = false;
  contactFormSubmitted:boolean=false;
  showB2BDialog: boolean;
  showB2CDialog: boolean;
  form = new FormGroup({});
  model: any = { name: "", number: "", email: "", comments: "",purpose:'' };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = this.formSchema.createFormStructure([
    { key: "name", label: "Your name", required: true },
    { key: "mobile", label: "Mobile number", required: true },
    { key: "email", label: "Email", required: true },
    {
      key: "purpose",
      label: "Purpose",
      required: true,
      type: "select",
      templateOptions: {
        options: [
          { value: "DESIGNING_EXECUTION", label: "Designing Execution" },
          { value: "PEXECUTION", label: "Execution only" },
          { value: "PDESINGNING", label: "Designing only" },
          { value: "SOURCING", label: "Sourcing of products" },
          { value: "3D", label: "3D rendering" },
          { value: "OTHER", label: "Other" },
        ],
      },
    },
    {
      key: "comments",
      label: "Comments",
      type: "textarea",
      templateOptions: { rows: 2, maxLength: 100 },
    },
  ]);

  showContactUsForm: boolean = false;

  async onSubmit() {
    const response=await this.spacifiiService.getDataForContactUs(this.model, "CONTACTUS");
    if(this.spacifiiService.contactUsData.responseCode===2000){
      this.contactFormSubmitted=true
      swal('Submitted!','Thank you for reaching us')
    }
    else{
      swal('Error!',"There was an error while submitting your form. Please try again later")
    }
    // this.contactFormSubmitted=true
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captchaVerified = true;
  }

  ngOnInit() {
    this.showB2CDialog = this.loginService.getPopUpB2CProfile();
    this.showB2BDialog = this.loginService.getPopUpB2BProfile();
    if(this.showB2BDialog || this.showB2CDialog !== null){
       this.dialog.closeAll();
    }else if(this.showB2BDialog || this.showB2CDialog === null){
      const dialog = this.dialog.open(this.callAPIDialog, {
        panelClass: 'cdk-image-container-pane',
    });
    dialog.afterClosed().subscribe(result => {

       });
    }
    else{
      this.dialog.closeAll();
    }
    this.generateMessage();

  }


ngAfterViewInit(){
 
}
  goToProfile(message:string)
  {
    if (message == 'B2B') {
      this.router.navigate(['/designer']);
      this.localStorageService.set('showModalDesigner',true);
      this.dialog.closeAll();
    } else {
      this.localStorageService.set('showModalCustomer',true);
      this.dialog.closeAll();
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }
  generateMessage(){
    var day = new Date();
    var hr = day.getHours();
    if (hr == 1) {
      this.message = "Good morning" + this.name + " ! 1AM and still your going! ";
    }
    if (hr == 2) {
      this.message ="Hey" + this.name + " , it is past 2AM! The bars must be closed!";
    }
    if (hr == 3) {
      this.message ="Hey" + this.name + " , it is after 3AM! Are you a vampire or what?";
    }
    if (hr == 4) {
      this.message = " 4AM? You must roam all night huh!";
    }
    if (hr == 5) {
      this.message ="Whoa! It's almost daylight and your still going!";
    }
    if (hr == 6) {
      this.message ="Hey" + this.name + " , isn't it too early to be using your computer";
    }
    if ((hr == 6) || (hr == 7) || (hr == 8) || (hr == 9) || (hr == 10)) {
      this.message ="Good Morning " + this.name + " !";
    }
    if (hr == 11) {
      this.message ="11AM... Is it time for lunch yet?";
    }
    if (hr == 12) {
      this.message ="NOON! Great, it must be time for lunch!";
    }
    if (hr == 14) {
      this.message ="It's 2PM. Have you eaten lunch yet?";
    }
    if ((hr == 15) || (hr == 16) || (hr == 13)) {
      this.message ="Good Afternoon " + this.name + " !";
    }
    if ((hr == 17) || (hr == 18) || (hr == 19) || (hr == 20) || (hr == 21) || (hr == 22)) {
      this.message ="Good Evening " + this.name + " ! Welcome to prime time on SPACIFII.COM!";
    }
    if (hr == 23) {
      this.message ="It's almost midnight...Aren't you sleepy yet?";
    }
    if (hr==0) {
      this.message ="It's midnight... do you ever sleep?";
    }
  }
}
