import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  forgotPassword: FormGroup;
  validateShow: boolean = false;
  otpGenerated: boolean = false;
  resendcount:any=0;
  submittedOTPGeneration = false;
  submittedOTP = false;
  booleanEnterOtp: boolean = false;
  booleanEnterPassword: boolean = false;
  otpReference: String;

  constructor(
      private formBuilder: FormBuilder, private loginService: LoginService,
      private toastr: ToastrService, private router: Router,
  ) { }

  get f() { return this.forgotPassword.controls; }

  ngOnInit() {
    this.forgotPassword = this.formBuilder.group({
      mobileNumber: ['', Validators.required],
      otp: ['', Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,16}')
      ]],
      /*confirmNewPassword: new FormControl('', Validators.required)
  }, (formGroup: FormGroup) => {
      return this.passwordValidator.areEqual(formGroup);

*/
      confirmNewPassword: ['', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,16}')
      ]]
    });
  }

  updatePassword() {
    var pattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{6,16}'
    var pword = this.forgotPassword.value.newPassword;
    var x = pword.match(pattern);
    if(x != null)
    {
      if(this.forgotPassword.value.newPassword == this.forgotPassword.value.confirmNewPassword){
        var mobileNumber = this.forgotPassword.value.mobileNumber;
        var passwordObject = {
          "id": mobileNumber,
          "password": this.forgotPassword.value.newPassword
        }
        this.loginService.setNewUserProfilePassword(passwordObject).subscribe(res =>{
          this.toastr.success("Password Updated, Please Login Now!","");
          this.router.navigate(["/login/customer"]);
        });
      }
      else {
        //throw some exception toast
        this.toastr.error("Passwords do not match. Key in the passwords again","");
      }
    }
    else
    {
      this.toastr.error("Password need to have a length of 6 and a maximum of 16 with 1 Capital and 1 Special Character","");
    }


    //setNewUserProfilePassword()
  }

  validateOtp() {

    if (!this.f.otp.valid) {
      return;
    }

    var otpObject = {
      "mobile": this.forgotPassword.value.mobileNumber,
      "otp": this.forgotPassword.value.otp,
      "otpReference": this.otpReference
    }

    //validateOtpForResetPassword()
    if (this.forgotPassword.value.otp != null) {
      this.loginService.validateOtpForResetPassword(otpObject).subscribe(res => {
        this.booleanEnterOtp = false;
        this.booleanEnterPassword = true;
        this.submittedOTP = true;
      })
    } else {
      return;
    }

  }

  generateOtp() {
    this.submittedOTPGeneration = true;
    if(!this.f.mobileNumber.valid)
    {
      return;
    }
    if(this.resendcount < 3) {

      var mobileNumber = this.forgotPassword.value.mobileNumber;
      if (mobileNumber != null) {

        this.loginService.resetUserProfilePassword(mobileNumber).subscribe(res => {
          this.otpReference = res.payload.otpReference;
          this.booleanEnterOtp = true;
          this.validateShow = true;
          this.otpGenerated = true;
          this.toastr.success("Your OTP has been sent to your Registered Mobile Number!","");
          this.resendcount = this.resendcount+1;
          this.booleanEnterOtp = true;
          this.submittedOTPGeneration = false;
        });
      } else {
        return;
      }
    }
    else{
      this.toastr.error("You have exceeded the Number of OTP Generation Attempts. Please try again later","");
    }
  }

}
