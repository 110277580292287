import { Component, OnInit } from '@angular/core';
import { FaqsService } from '../faqs.service';


@Component({
	selector: 'app-faqs-search',
	templateUrl: './faqs-search.component.html',
	styleUrls: ['./faqs-search.component.scss']
})
export class FaqsSearchComponent implements OnInit {

	constructor(private faqsService: FaqsService) {

	}

	faqCategories: any;
	searchResultObject: any;
	totalPages: any;
	totalElements: any;
	faqs: any;
	searchObject: any;
	currentPage: any;
	currentPageElements: any;
	ngOnInit() {
		this.searchObject = this.faqsService.getSearchObject();
		this.searchResultObject = this.faqsService.getsearchResult();
		this.totalPages = this.searchResultObject.payload.totalPages;
		this.totalElements = this.searchResultObject.payload.totalElements;
		this.currentPage = this.searchResultObject.payload.number;
		this.currentPageElements = this.searchResultObject.payload.size;
		this.faqs = this.searchResultObject.payload.content;



		


	}

}
