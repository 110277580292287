import { Injectable } from '@angular/core';
import {  LocalStorageService } from 'angular-web-storage';
@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {

  constructor(public localStorageService:LocalStorageService) { }

  //sessionStorage.setItem("tokenString")
  public setToken(tokenString){
    this.localStorageService.set("tokenString",tokenString);
    //localStorageService.set();
  }

  public removeToken(){
    this.localStorageService.remove("tokenString");
    this.localStorageService.remove("loginProfile");
  }

  public getToken(){
    return this.localStorageService.get("tokenString");
  }

}
