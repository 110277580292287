import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhyPeopleLoveUsComponent } from './why-people-love-us.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
@NgModule({
  declarations: [ WhyPeopleLoveUsComponent ],
  exports: [ WhyPeopleLoveUsComponent ],
  imports: [ CommonModule, SwiperModule ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class WhyPeopleLoveUsModule { }
