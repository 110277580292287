// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  masterApi : "https://betaapi.spacifii.com:8443/konstruct-master",
  registrationApi : "https://betaapi.spacifii.com:8443/konstruct-registration",
  loginApi :  "https://betaapi.spacifii.com:8443/konstruct-security",
  faqsApi :  "https://betaapi.spacifii.com:8443/konstruct-content",
  exploreApi : "https://betaapi.spacifii.com:8443/konstruct-explore",
  imageSharingURL:"https://beta.spacifii.com/images",
  leadManagement : "https://betaapi.spacifii.com:8443/konstruct-leadmanagement",
  //b2bSite:"http://beta.spacifii.com/business",
  spacifiiAPI:"http://206.189.135.119:8020",
  // b2bSite:"http://localhost:4200",
   b2bSite:"http://beta.spacifii.com/",
  spacifiiAPIEMAIL:'tech@spacifii.com',
  fbSharetooltip:"Share on Faceboook",
  twitterSharetooltip:"Share on Twitter",
  instaSharetooltip:"Share on Instagram",
  contactTooltipCreator:"Contact the Creator",
  projectDetail_ContactTooptip:"Contact the creator",
  projectDetail_LikeToolTip:"Like our creation",
  projectDetail_EditToolTip:"Edit the project",
  projectDetail_CommentToolTip:"Add a comment",
  projectDetail_CustomerReiewReadToolTip:"Read the customer review",
  xPloreImageSharingEmailBodyText:"Take a look at this great space that I found on SPACIFII, and let me know what you think.",
  errorMessageOnLogout:"Keeping in mind your security, we have logged you. Please login again. Thanks",
  //PAGE TITLES
  xPlorePageTitle: "Angular Project Training",
  defaultConceptBoardImage: 'https://d35l1mw02rok1y.cloudfront.net/frontend/default_concept_board.jpg',
  defaultRecentlyPojectImage: 'https://d35l1mw02rok1y.cloudfront.net/frontend/default_project.png',
  defaultProfileImage : 'https://d35l1mw02rok1y.cloudfront.net/frontend/customer/main-nav/user-image.png',



  videoImage:'https://d35l1mw02rok1y.cloudfront.net/400x250/frontend/video.jpg',
  pdfImage:'https://d35l1mw02rok1y.cloudfront.net/400x250/frontend/pdfmain.jpg',
  defaultProjectImage:'https://d35l1mw02rok1y.cloudfront.net/400x250/frontend/project_default_main.jpg',
  walkthroughImage:'https://d35l1mw02rok1y.cloudfront.net/400x250/frontend/walkthrough_img.jpg',

  videoImageFull:'https://d35l1mw02rok1y.cloudfront.net/frontend/video.jpg',
  pdfImageFull:'https://d35l1mw02rok1y.cloudfront.net/frontend/pdfmain.jpg',
  walkthroughImageFull:'https://d35l1mw02rok1y.cloudfront.net/frontend/walkthrough_img.jpg',

  showroom: true,
  conceptboard:false,
  mediaTypeConfiguration: [{
    "type" : "IMAGE",
    "showDetailsTab":true,
    "showMediaDetails" : true,
    "AddKeyWords":true,
    "showKeywordDetails" : true,
    "showCommentsTab" : true,
    "showQuestionTab" : true,
    "showColorTab" : true,
    "imagePlayer" : true,
    "image360Player" : false,
    "videoPlayer" : false,
    "pdfPlayer" : false
  },
    {
      "type" : "IMAGE360",
      "showDetailsTab":true,
      "showMediaDetails" : true,
      "AddKeyWords":true,
      "showKeywordDetails" : true,
      "showCommentsTab" : true,
      "showQuestionTab" : true,
      "showColorTab" : true,
      "imagePlayer" : false,
      "image360Player" : true,
      "videoPlayer" : false,
      "pdfPlayer" : false
    },
    {
      "type" : "VIDEO",
      "showDetailsTab":true,
      "showMediaDetails" : true,
      "AddKeyWords":true,
      "showKeywordDetails" : false,
      "showCommentsTab" : true,
      "showQuestionTab" : true,
      "showColorTab" : false,
      "imagePlayer" : false,
      "image360Player" : false,
      "videoPlayer" : true,
      "pdfPlayer" : false
    },
    {
      "type" : "PDF",
      "showDetailsTab":true,
      "showMediaDetails" : true,
      "AddKeyWords":false,
      "showKeywordDetails" : true,
      "showCommentsTab" : true,
      "showQuestionTab" : true,
      "showColorTab" : true,
      "imagePlayer" : false,
      "image360Player" : false,
      "videoPlayer" : false,
      "pdfPlayer" : true
    }
  ]

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
