import {Component, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
// import { THREE } from 'three-full';
// import * as THREE from 'three-full';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"


import {MasterService} from '../../../../app/services/master.service';
//import { ColorsComponent } from '../../showcase/colors/colors.component';
import {MatDialog} from '@angular/material';
import {ColorsComponent} from '../../showcase/colors/colors.component';

@Component({
    selector: 'collada-viewer',
    templateUrl: './collada-viewer.component.html',
    styleUrls: ['./collada-viewer.component.scss']
})
export class ColladaViewerComponent implements OnInit {

    @Input('daePath') daePath: string;

    @ViewChild('wrap') private wrapRef: ElementRef;
    @ViewChild('canvas') private canvasRef: ElementRef;
    mesh: THREE.Mesh;
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;
    controls: any;
    elf: THREE.Scene;
    loader: THREE.Loader;
    colorMap: any = {};
    canvas: HTMLCanvasElement;
    componentsArray: any = [];
    canvasWrap: any;
    constAspRatio: number = 0.532;
    three: any;
    imageSet: any[];
    selectedComponent: any;
    colors: any = [];
    loadingCanvas: boolean = true;

    constructor(
        private masterService: MasterService,
        public dialog: MatDialog
    ) {
        this.imageSet = [
            {
                name: 'Fabric',
                value: 'assets/collada/model/fabric.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/COL0.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/COL1.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/FILC_1.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/FILC_2.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/FILC_3.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/_150010BX.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/brass.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/clean_black_steel.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/french_blue.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/material_1.jpg'
            },
            {
                name: 'Fabric',
                value: 'assets/collada/model/material_2.jpg'
            }
        ];
    }

    ngOnInit() {
        this.canvasWrap = this.wrapRef.nativeElement;
        this.canvas = this.canvasRef.nativeElement;
        this.three = THREE;
        this.getSetColors();

    }

    ngAfterViewInit() {
        this.canvasWrap.style.height = this.canvasWrap.clientWidth * this.constAspRatio + 'px';
        this.init();
        let component: any = this;
        (function animate() {
            requestAnimationFrame(animate);
            component.controls.update();
            component.renderer.render(component.scene, component.camera);
        }());
        this.selectedComponent = this.componentsArray[0] || {};
    }

    changeColor(_prop, _type, _value) {
        this.colorMap[_prop] = {
            type: _type,
            value: _value
        };
        this.renderer.clear();
        this.loadCollada();
        //		renderer.render( scene, camera );
    }

    render() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        });

        //  this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(this.canvasWrap.clientWidth, this.canvasWrap.clientHeight);

        this.renderer.setClearColor(0xFFFFFF);
        //   this.renderer.setClearColor('grey');
        //  this.canvasWrap.appendChild(this.renderer.domElement);
    }

    createScene() {
        this.scene = new THREE.Scene();
    }

    createCamera() {
        this.camera = new THREE.PerspectiveCamera(30, this.canvasWrap.clientWidth / this.canvasWrap.clientHeight, 1, 1000);
        this.camera.position.set(5, 5, 5);
        this.scene.add(this.camera);
    }

    addControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableZoom = true;
        this.controls.enablePan = true;
        this.controls.maxPolarAngle = Math.PI;
    }

    addLight() {
        this.scene.add(new THREE.AmbientLight(0xFFFFFF, 0.3));
        var light = new THREE.PointLight(0xffffff, 0.8);
        this.camera.add(light);
    }

    showAxisHelper() {
        // this.scene.add(new THREE.AxisHelper(20));
    }

    createLoader() {
        var loadingManager = new THREE.LoadingManager(() => {
            this.scene.add(this.elf);
        });
        this.loader = new THREE.Loader();
    }

    loadCollada() {
        THREE.Cache.clear();
        // this.loader.load(this.daePath, (collada) => {
        //     this.elf = collada.scene;
        //     let updateComArray = this.componentsArray.length == 0 ? true : false;
        //     this.elf.traverse((child) => {
        //         if (updateComArray && child.name && child.name != '' && !this.componentsArray.some((el) => {
        //             return el.name === child.name;
        //         })) {
        //             if (child.name.substring(0, 9) == 'SPACIFII_') {
        //                 this.componentsArray.push({
        //                     name: child.name || 'NA',
        //                     type: child.type || 'NA'
        //                 });
        //             }
        //         }

        //         if (child instanceof THREE.Mesh) {
        //             if (!(child.material instanceof Array)) {
        //                 this.updateMesh(child);
        //             } else {
        //                 this.updateMesh(child, true);
        //             }
        //         }
        //     });
        //     //       elf.scale.x = elf.scale.y = elf.scale.z = 0.1;
        //     //      elf.rotation.z += 10.5;
        //     this.elf.updateMatrix();
        //     setTimeout(() => {
        //         this.loadingCanvas = false;
        //     }, 2000);
        // });
    
    }

    init() {
        this.render();
        this.createScene();
        this.createCamera();
        this.addControls();
        this.addLight();
        //this.showAxisHelper();
        this.createLoader();
        this.loadCollada();
    }

    updateMesh(_node, _isMaterialArray = false) {
        var partKeys = Object.keys(this.colorMap);
        partKeys.forEach((el) => {
            var regex = new RegExp(el, 'g');
            if (regex.test(_node.name) || regex.test(_node.parent.name)) {

                if (_isMaterialArray) {
                    _node.material.map((item) => {
                        item.needsUpdate = true;
                        item.flatShading = true;
                        if (this.colorMap[el].type == 'color') {
                            item.color.set(this.colorMap[el].value);
                        }
                        if (this.colorMap[el].type == 'image') {
                            var texture = new THREE.TextureLoader().load(this.colorMap[el].value);
                            item.map = texture;
                        }
                    });
                } else {
                    _node.material.needsUpdate = true;
                    _node.material.flatShading = true;
                    if (this.colorMap[el].type == 'color') {
                        _node.material.color.set(this.colorMap[el].value);
                    }
                    if (this.colorMap[el].type == 'image') {
                        var texture = new THREE.TextureLoader().load(this.colorMap[el].value);
                        _node.material.map = texture;
                    }
                }
            }
        });
    }

    /* Event functions */
    selectComponent(_comp) {
        this.selectedComponent = _comp || {};
    }

    getSetColors() {
        this.masterService.getExploreColors().subscribe((res: any) => {
                this.colors = res.payload;
            },
            (error: any) => {
            });
    }

    selectColor(_att) {

        const dialogRef = this.dialog.open(ColorsComponent, {
            data: {
                colors: this.colors
            },
            panelClass: 'showcaseColorModal'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.changeColor(_att, 'color', result.hashHexCode);
            }
        });

    }

}
