import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MasterService } from '../../../../src/app/services/master.service';
import { ExploreMediaService } from '../../../../src/app/services/explore-media.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-images',
	templateUrl: './images.component.html',
	styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {

	preShowProject: any = 8;
	preShowReleted: any = 8;
	exploreColorObject: any;
	childColorArray: any;
	filterArray: any;
	checkedValuesSize: any;
	startIndex: any;
	styleString: any;
	width: any;
	height:any;
	dimension:any= "10x10";

	@ViewChild('move') elementView: { nativeElement: any; };

	constructor(
		public dialog: MatDialog,
		private masterService: MasterService,
		private exploreMediaService: ExploreMediaService,
		private router: Router,
		private route: ActivatedRoute) { }

	shadesOfImage: string[] = [];
	mediaId: any;
	styles: any[];
	currentMediaObject: any;

	

	

	ngOnInit() {

		this.route.params.subscribe((params) => {
			if (params['dynamicParam']) {
				this.mediaId = [params['dynamicParam']];
				this.setCurrentMediaObject(this.mediaId);
			}

		});


		this.masterService.getExploreColors().subscribe((res: { payload: any; }) => {
			this.exploreColorObject = res.payload;
			//this.exploreColorShades = res.payload;
			//console.log(res);
			this.childColorArray = this.exploreColorObject[0].childColors;
		});

	}

	ngAfterViewInit() {
		
		this.width = this.move.nativeElement.offsetWidth;
		this.height= this.move.nativeElement.offsetHeight;
		this.dimension= this.width + "x" + this.height;
	//	console.log(this.width);
	//	console.log(this.height);
	  }
	
	  @ViewChild('move')
	  move: ElementRef;


	setCurrentMediaObject(inputzz: any) {
		this.exploreMediaService.getfullPageMedia(inputzz).subscribe(res => {
			//console.log(res);
			this.currentMediaObject = res.payload
			this.fillShades();

		});
	}


	fillShades() {


		for (let x of this.currentMediaObject.media.colorsAndHex) {
			if (x.indexOf('#') > -1) {
				this.shadesOfImage.push(x);
			}
		}
	}

}










