import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from 'angular-web-storage';
import {UserProfileService} from '../../../services/user-profile.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {

  myProfile:any;
  frmEmailUpdate: FormGroup;
  myReturnObject: any;
  validateShow: boolean = false;
  otpGenerated: boolean = false;
  resendcount:any=0;
  submittedOTPGeneration = false;
  submittedOTP = false;

  constructor(

      public localStorageService:LocalStorageService,
      public userProfileService:UserProfileService,
      private _formBuilder: FormBuilder,
      private loginService: LoginService,
      private toastr: ToastrService

  ) { }

  get f() { return this.frmEmailUpdate.controls; }

  ngOnInit() {
    this.myProfile = this.loginService.getMyProfile();

    this.frmEmailUpdate = this._formBuilder.group({
      oldEmailId: ['', Validators.required],
      newEmailId: ['', [Validators.required, Validators.email]],
      newOTP: ['', Validators.required]
    });

    this.frmEmailUpdate.setValue({
      "oldEmailId":this.myProfile.userProfile.email,
      "newEmailId":'',
      "newOTP":''
    })

    this.validateShow = false;
    this.otpGenerated = false;
  }

  generateOTP(){

    this.submittedOTPGeneration = true;
    if(!this.f.newEmailId.valid)
    {
      return;
    }
    if(this.resendcount < 3) {
      this.userProfileService.changeEmailStepOne(this.frmEmailUpdate.value.newEmailId).subscribe(res => {
        this.myReturnObject = res.payload;
        this.validateShow = true;
        this.otpGenerated = true;
        this.toastr.success("Your OTP has been sent to the new Email!","");
        this.resendcount = this.resendcount+1;
        this.submittedOTPGeneration = false;
      })
    }
    else{
      this.toastr.error("You have exceeded the Number of OTP Generation Attempts. PLease try again later","");
    }
  }


  ValdiateOTP(){

    this.submittedOTP = true;
    if (this.frmEmailUpdate.invalid) {
      return;
    }

    let otpRequest:any = {
      "email": "string",
      "otp": "string",
      "otpReference": "string"
    }

    otpRequest.email =  this.frmEmailUpdate.value.newEmailId;
    otpRequest.otp =  this.frmEmailUpdate.value.newOTP;
    otpRequest.otpReference =  this.myReturnObject.otpReference;
    this.userProfileService.changeEmailWithOTP(otpRequest).subscribe(res => {
          this.toastr.success("Your New Email has been updated successfully","");
          this.myProfile.userProfile = res.payload;
          this.loginService.setMyUserProfile(this.myProfile.userProfile);
          this.ngOnInit();
        },
        (error) => {
        //  console.log(error);
        });
  }

}
