import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { FaqsService } from '../faqs/faqs.service'
import { Router } from '@angular/router';

import {switchMap, debounceTime} from 'rxjs/operators';
import {Observable} from 'rxjs'
import { ApiResponse } from '../../../../src/app/services/api-response';


@Component({
	selector: 'app-faqs',
	templateUrl: './faqs.component.html',
	styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

	filteredSearch: Observable<ApiResponse>;
	faqSearchFormGroup: FormGroup;
	customerFaqs: any;
	businessFaqs: any;
	faqCategory: any;
	searchResults: any;
	searchObject: any;

	constructor(
		private _formBuilder: FormBuilder,
		private faqsService: FaqsService,
		private router: Router
	) { }

	
	ngOnInit() {
		this.searchObject = {
			"page": 0,
			"searchQuery": {
				"globalSearch": "LOREM"
			},
			"size": 10
		};
		this.faqSearchFormGroup = this._formBuilder.group({
			searchFaq: ['', Validators.required],
		});

		// Customer FAQs
		this.faqsService.getFaqCategoryTreeCustomer().subscribe((res: { payload: any; }) => {
			this.customerFaqs = res.payload;
			//console.log('CUSTOMER',this.customerFaqs);
		});

		// Business FAQs
		this.faqsService.getFaqCategoryTreeBusiness().subscribe((res: { payload: any; }) => {
			this.businessFaqs = res.payload;
			//console.log('BUSINESS',this.businessFaqs);
		});
	}

	searchFormGroup() {
		this.searchObject.searchQuery.globalSearch = this.faqSearchFormGroup.value.searchFaq;
		this.hitFaqService();
	}

	hitFaqService() {
		this.faqsService.searchFaq(this.searchObject).subscribe(res => {
			this.searchResults = res;
			this.faqsService.setsearchResult(res);
			this.router.navigate(['faqs-search']);
		});
	}
}