import { Component } from '@angular/core';

@Component({
  selector: 'app-how-do-we-offer',
  templateUrl: './how-do-we-offer.component.html',
  styleUrls: ['./how-do-we-offer.component.scss']
})
export class HowDoWeOfferComponent {

  recentlySpacifiis = [
    {
      img : "https://spacifii.com/wp-content/uploads/2017/04/spaceplanning-60x60.png",
      title:"Space Planning"
    },
    {
      img : "https://spacifii.com/wp-content/uploads/2017/04/furniture-60x60.png",
      title:"We Will Help You In Shopping Right Furnishings"
    },
    {
      img : "https://spacifii.com/wp-content/uploads/2017/04/modular-kitchen-60x60.png",
      title:"Selecting Kitchen & Bathroom Fixtures"
    },
    {
      img : "https://spacifii.com/wp-content/uploads/2017/04/wall-design-paint-decor-60x60.png",
      title:"Selecting Kitchen & Bathroom Fixtures"
    },
    {
      img : "https://spacifii.com/wp-content/uploads/2017/04/home-cleaning-60x60.png",
      title:"Choose a Color Scheme"
    }
  ];
  
  constructor(
  ) { }
}
