import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SpacifiiService {
  contactUsData:any
  reqBody={}
  constructor(private httpClient: HttpClient) {}

  // for dev 
   async getDataForContactUs(reqData: any, event: string) {
     let options=
     {
       headers:new HttpHeaders({
       subjectid:"11"
     })
    }
         
       this.reqBody = {
        COMMUNICATIONS_COMMUNICATIONTYPE: 'EMAIL',
        CUSTOMER_EMAIL1: environment.spacifiiAPIEMAIL,
        CUSTOMER_UNIQUECUSTOMERID: '5f7845b5f830170f14a166eb',
        NAME: reqData.name,
        EMAIL: reqData.email,
        MOBILE: reqData.mobile,
        PURPOSE: reqData.purpose,
        COMMENTS: reqData.comments,
        DATE: !reqData.date?new Date().toLocaleDateString() : reqData.date,
        TIME: !reqData.time?"contactUs-NA" :reqData.timeToCall
      };
    this.contactUsData= await  this.httpClient.post(
      `${environment.masterApi}/api/messageEngine/event/${event}/sendMessage`,
      this.reqBody,
      options
    ).toPromise();
  }


}
