import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {AddVideo} from '../../components/conceptBoard/components/upload-videos/upload-videos.component';

@Component({
  selector: 'app-spacifiivideo',
  templateUrl: './spacifiivideo.component.html',
  styleUrls: ['./spacifiivideo.component.scss']
})
export class SpacifiivideoComponent implements OnInit {

  @ViewChild("meet") meet: ElementRef;
  jitsi:any;
  projectName:any;

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<SpacifiivideoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.projectName = this.data.name;
    this.jitsi = window['JitsiMeetExternalAPI'];
  //  console.log('JITSI',this.jitsi);
    const domain = 'crm.spacifii.com';
    const options = {
      roomName: this.projectName,
      width: window.innerWidth,
      height: window.innerHeight - 100,
      parentNode: this.meet.nativeElement
    };
    const api = new this.jitsi(domain, options);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
