import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OurStoryComponent } from './components/our-story/our-story.component';
// import { ExploreComponent } from './pages/explore/explore/explore.component';
import { CustomerRegistrationComponent } from './components/registration/customer-registration/customer-registration.component';
import { PartnerRegistrationComponent } from './components/registration/partner-registration/partner-registration.component';
// import { FaqsComponent } from './pages/faqs/faqs.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
// import { FaqsDetailComponent } from './pages/faqs/faqs-detail/faqs-detail.component';
// import { FaqsSearchComponent } from './pages/faqs/faqs-search/faqs-search.component';
// import { AboutusComponent } from './pages/in-house-web/pages/aboutus/aboutus.component';
// import { OurTeamComponent } from './pages/in-house-web/pages/our-team/our-team.component';
// import { HowItWorksComponent } from './pages/in-house-web/pages/how-it-works/how-it-works.component';
// import { OfferingsComponent } from './pages/in-house-web/pages/offerings/offerings.component';
// import { WebStoryComponent } from './pages/in-house-web/pages/web-story/web-story.component';
// import { DashboardComponent } from './pages/dashboard/dashboard.component';
// import { ViewConceptBoardComponent } from './components/conceptBoard/components/view-concept-board/view-concept-board.component';
// import { UserProfileComponent } from './pages/user-profile/user-profile.component';
// import { ProjectProfileComponent } from './pages/project-profile/project-profile.component';
import { ImagesComponent } from './pages/images/images.component';
import { CustomerTrackerComponent } from './pages/customer-tracker/customer-tracker.component';
import { AllConceptBoardComponent } from './components/conceptBoard/components/all-concept-board/all-concept-board.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import {PersonalProfileComponent} from './components/settings/personal-profile/personal-profile.component';
import { EditMobileComponent } from './components/settings/edit-mobile/edit-mobile.component';
// import { ShareWithMeComponent } from './components/conceptBoard/components/share-with-me/share-with-me.component';
// import { ShortlistedImagesComponent } from './components/conceptBoard/components/shortlisted-images/shortlisted-images.component';
// import { AddPanoramaComponent } from './components/conceptBoard/components/add-panorama/add-panorama.component';
import { ProductComponent } from './components/product/product.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import {PrivacyComponent} from './pages/staticPages/legal/privacy/privacy.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { NewHomeComponent } from './pages/new-home/new-home.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomeModule'
  },
  {
    path: 'blog',
    loadChildren: './pages/blog/blog.module#BlogModule',
  },
  {
    path: 'recently',
    loadChildren: './pages/recently-spacifii/recently-spacifii.module#RecentlySpacifiiModule'
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'customer-review',
    loadChildren: './pages/review/review.module#ReviewModule'
  },
  {
    path: 'explore',
    loadChildren: './pages/explore/explore.module#ExploreModule'
  },
  {
    path: 'estimate',
    loadChildren: './pages/estimate/estimate.module#EstimateModule'
  },
  {
    path: 'project',
    loadChildren: './pages/project-profile/projectprofile.module#ProjectprofileModule'
  },
  {
    path: 'partner',
    loadChildren: './pages/user-profile/userprofile.module#UserprofileModule'
  },
  {
    path: 'faq',
    loadChildren: './pages/faqs/faq.module#FaqModule'
  },
  {
    path: 'spacifii',
    loadChildren: './pages/in-house-web/in-house.module#InHouseModule'
  },
  {
    path: 'concept-board',
    loadChildren: './components/conceptBoard/concept-board.module#ConceptBoardModule'
  },
  {
    path: 'showroom',
    loadChildren: './pages/showroom/showroom.module#ShowroomModule'
  },
  {
    path: 'customer',
    loadChildren: './pages/customer/customer.module#CustomerModule'
  },
  {
    path: 'dashboard',
    loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'designer',
    loadChildren: './pages/designer/designer.module#DesignerModule'
  },

  { path: 'our-story', component: OurStoryComponent },
  // { path: 'explore', component: ExploreComponent },
  // { path: 'explore/:dynamicParam', component: ExploreComponent },


  { path: 'registration/customer',component: CustomerRegistrationComponent},
  { path: 'quiz',component: QuizComponent},
  { path: 'newhome',component: NewHomeComponent},
  { path: 'registration/partner',component: PartnerRegistrationComponent},

  //{ path: 'dashboard' , component: DashboardComponent},
  { path: 'images/:dynamicParam', component: ImagesComponent },
  { path: 'customer-tracker' , component: CustomerTrackerComponent},
  { path: 'profile/edit-settings', component: PersonalProfileComponent },
  { path: 'profile/edit-settings', component: EditMobileComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'product', component: ProductComponent},
  { path: 'product-detail/:dynamicParam', component: ProductDetailComponent},
  { path: 'privacypolicy', component: PrivacyComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
