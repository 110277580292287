import { Component, OnInit } from '@angular/core';
import {ConceptboardService} from '../../conceptBoard/conceptboard.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-publicboards',
  templateUrl: './publicboards.component.html',
  styleUrls: ['./publicboards.component.scss']
})
export class PublicboardsComponent implements OnInit {

  globalConceptBoards:any;

  constructor(private conceptBoardService: ConceptboardService,
              private router: Router,) { }

  ngOnInit() {

    let GLOBALSearchObject = {
      "page": 1,
      "searchQuery": {
        "where": { "active": [true] }
      },
      "size": 12
    };
    // this.conceptBoardService.paginatePublicConceptBoardSearch(GLOBALSearchObject).subscribe(res => {
    //   if (res.payload.content) {
    //     this.globalConceptBoards = res.payload.content;
    //   }
    // });

  }


  navigateToConceptBoard(id) {
    this.router.navigateByUrl('/concept-board/public/'+id);
  }
}
