
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SpacifiiService } from "../../services/spacifii.service";
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { FormService } from "../../services/form.service";
import swal from 'sweetalert';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
export interface BusinessScope {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-contact-forms",
  templateUrl: "./contact-forms.component.html",
  styleUrls: ["./contact-forms.component.scss"],
})
export class ContactFormsComponent implements OnInit {
  captchaVerified: boolean;
 
  fixedBoxOffsetTop: number  = 0;
  fixedBoxOffsetTopOtherMethod: number = 0;

  @ViewChild('fixedBox') fixedBox: ElementRef;
  constructor(
    private _formBuilder: FormBuilder,
    private spacifiiService: SpacifiiService,
    private formSchema: FormService
  ) {}

  ngOnInit() {}

  form = new FormGroup({});
  model: any = { name: "", number: "", email: "", comments: "", purpose: "" };
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = this.formSchema.createFormStructure([
    { key: "name", label: "Your Name", required: true },
    { key: "mobile", label: "Mobile number", required: true },
    { key: "email", label: "Email", required: true },
    {
      key: "purpose",
      label: "Purpose",
      required: true,
      type: "select",
      templateOptions: {
        options: [
          { value: "DESIGNING_EXECUTION", label: "Designing Execution" },
          { value: "PEXECUTION", label: "Execution only" },
          { value: "PDESINGNING", label: "Designing only" },
          { value: "SOURCING", label: "Sourcing of products" },
          { value: "3D", label: "3D rendering" },
          { value: "OTHER", label: "Other" },
        ],
      },
    },
    {
      key: "comments",
      label: "Comments",
      type: "textarea",
      templateOptions: { rows: 2, maxLength: 100 },
    },
  ]);

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captchaVerified = true;
  }

  showContactUsForm: boolean = false;

   async onSubmit() {
    
    const response=await this.spacifiiService.getDataForContactUs(this.model,"CONTACTUS");
    //console.log("response",response);
    if(this.spacifiiService.contactUsData.responseCode===2000){
      
      swal('Submitted!','Thank you for reaching us')
    }else{
      swal('Error!',"There was an error while submitting your form. Please try again later")
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

  }

}
