import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';

import { FaqRoutingModule } from './faq-routing.module';
import {FaqComponent} from '../../components/estimate/pages/faq/faq.component';
import {FaqsComponent} from './faqs.component';
import {FaqsDetailComponent} from './faqs-detail/faqs-detail.component';
import {FaqsSearchComponent} from './faqs-search/faqs-search.component';
import {UserprofileRoutingModule} from '../user-profile/userprofile-routing.module';
import {ExploreModule} from '../explore/explore.module';
import {TranslateModule} from '@ngx-translate/core';
import {ExploreRoutingModule} from '../explore/explore-routing.module';
import {MaterialModule} from '../../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModuleModule} from '../../shared/shared-module.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SwiperModule} from 'ngx-swiper-wrapper';

@NgModule({
  imports: [
    CommonModule,
    FaqRoutingModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModuleModule,
    TranslateModule,
    InfiniteScrollModule,
  ],
  declarations: [
      FaqComponent,
      FaqsComponent,
      FaqsDetailComponent,
      FaqsSearchComponent

  ],
  exports:[
    FaqsComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA]

})
export class FaqModule { }
