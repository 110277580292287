import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-spacifii',
  templateUrl: './why-spacifii.component.html',
  styleUrls: ['./why-spacifii.component.scss']
})
export class WhySpacifiiComponent implements OnInit {

  public mode: string = 'viewport';
  constructor() { }

  ngOnInit() {
  }

}
