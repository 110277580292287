import { Pipe, PipeTransform } from '@angular/core';
import {CommonModule} from "@angular/common";
@Pipe({name: 'imageDimension'})
export class ImageDimension implements PipeTransform {
  
  transform(value: string, dimension:string): string {
    if(dimension == 'original'){
      return value ? value.replace('/CHANGE_MY_SIZE', ''): value;
    }else{
      return value ? value.replace('CHANGE_MY_SIZE', dimension) : value;
    }
  }
}
