import { Component, OnInit, Inject, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterService } from '../../../../src/app/services/master.service';
import { ExploreMediaService } from '../../../../src/app/services/explore-media.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConceptboardService } from '../conceptBoard/conceptboard.service';
import { LoginService } from '../../../../src/app/services/login.service';
import { LocalStorageService } from 'angular-web-storage';
import { AuthserviceService } from '../../../../src/app/services/authservice.service';
import { EstimateService } from '../estimate/estimate.service';
import { ProductComponent } from '../product/product.component';
import { Http } from '@angular/http';



export interface DialogDataFilter {
	exploreFilterObject: string;
	currentFilterArray: any;
}

@Component({
	selector: 'app-mobile-sticky-footer',
	templateUrl: './mobile-sticky-footer.component.html',
	styleUrls: ['./mobile-sticky-footer.component.scss']
})

export class MobileStickyFooterComponent implements OnInit {

	public mainCategoryShow: boolean = false;
	public subCategoryShow: boolean = false;
	public buttonName: any;
	selectedProductMenu: any;

	exploreFilterObject: any;
	exploreMenuObject: any = [];
	showRoomObject: any = [];
	propertyTypeConfigurationMasters: any = [];
	selectedTopMenu: any;
	exploreColorObject: any;
	childColorArray: any;
	filterArray: any = [];
	startIndex: any;
	conceptBoards: any;
	searchObject: any = {
		"where": { "extendedKeywords": [] }
	};
	currentChildColorArray: any[];
	searchFilterObject: any;
	searchResultObject: any;
	totalElements: any;
	itemsToRemove = 0;
	currentFilterArray: any = [];
	currentAppliedFilter: any = this.currentFilterArray;
	checkedValues = this.currentFilterArray;
	checkedValuesSize = 0;
	colorString: String[] = [];
	expandedColor : boolean = false;
	myProfile: any;	
	isLoggedIn: boolean = false;
	estimate : boolean = false;
	selectedExploreMenu: any;
	
	propertyTypeSelected: any;
	propertySubTypeSelected: any;
	selectedProperty = true;

	selectedIndex = 1;

	showDiv(index: number) {
		this.selectedIndex = index;
	}

	estimateMenus=[
		{
			id: 'all you need to know'
		},
		{
			id: 'my estimates'
		},
		{
			id: 'property type'
		},
		{
			id: 'spaces'
		},
		{
			id: 'product'
		},
		{
			id: 'the spacifii collection'
		}
	]

	constructor(
		private productComponent: ProductComponent,
		public http: Http,
		private breakpointObserver: BreakpointObserver,
		private masterService: MasterService,
		private estimateService: EstimateService,
		private exploreMediaService: ExploreMediaService,
		private router: Router,
		private conceptBoardService: ConceptboardService,
		public loginService: LoginService,
		private localStorageService: LocalStorageService,
		private authserviceService: AuthserviceService,
		private route: ActivatedRoute) {
			this.isLoggedIn = this.loginService.getLogin();
			this.myProfile = this.loginService.getMyProfile();
		 }

		 ProductElement = [
			{	"cssID" : 'TOP',
				"categoreyName": "Furniture",
				"categoreyDisplay": "Sofas",
				"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
				"childCategories": [
					{
						"cssID" : 'CHILD_1',
						"categoreyName": "Sofas & Loungers",
						"categoreyDisplay": "Sofas",
						"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
						"childCategories": [
							{
								"cssID" : 'CHILD_2',
								"categoreyName": "Three Seater Sofas",
								"categoreyDisplay": "Sofas",
		
							},
							{
								"categoreyName": "Tow Seater Sofas",
								"categoreyDisplay": "Sofas",
		
							},
							{
								"categoreyName": "One Seater Sofas",
								"categoreyDisplay": "Sofas",
		
							},
							{
								"categoreyName": "Sofas Seater",
								"categoreyDisplay": "Sofas",
		
							},
							{
								"categoreyName": "L Shaped",
								"categoreyDisplay": "Sofas",
		
							},
							{
								"categoreyName": "Sofa cum Beds",
								"categoreyDisplay": "Beds",
		
							},
							{
								"categoreyName": "Recliners",
								"categoreyDisplay": "Beds",
		
							}
						]
					},
					{
						"categoreyName": "Accent Chairs",
						"categoreyDisplay": "Chairs",
						"childCategories": [
							{
								"categoreyName": "Wing Chairs",
								"categoreyDisplay": "Chairs",
		
							},
							{
								"categoreyName": "Arm Chairs",
								"categoreyDisplay": "Chairs",
		
							},
							{
								"categoreyName": "Rocking Chairs",
								"categoreyDisplay": "Chairs",
		
							},
							{
								"categoreyName": "Cafe Chairs",
								"categoreyDisplay": "Chairs",
		
							},
							{
								"categoreyName": "Iconic Chairs",
								"categoreyDisplay": "Chairs",
		
							}
						]
					},
					{
						"categoreyName": "Bar Furniture",
						"categoreyDisplay": "Furniture",
						"childCategories": [
							{
								"categoreyName": "Bar Cabintes",
								"categoreyDisplay": "Chairs",
		
							},
							{
								"categoreyName": "Win Racks",
								"categoreyDisplay": "Chairs",
		
							},
							{
								"categoreyName": "Bar Chairs and Tools",
								"categoreyDisplay": "Chairs",
		
							},
							{
								"categoreyName": "Bar Chairs and Table Sets",
								"categoreyDisplay": "Chairs",
		
							},
						]
					},
					{
						"categoreyName": "Wardrobes",
						"categoreyDisplay": "Furniture",
						"childCategories": [
							{
								"categoreyName": "2 Door Wardrobes ",
								"categoreyDisplay": "Furniture",
		
							},
							{
								"categoreyName": "3 Door Wardrobes",
								"categoreyDisplay": "Furniture",
		
							},
							{
								"categoreyName": "4 Door Wardrobes",
								"categoreyDisplay": "Furniture",
		
							},
							{
								"categoreyName": "1 Door Wardrobes",
								"categoreyDisplay": "Furniture",
		
							},
						]
					},
					{
						"categoreyName": "Beds",
						"categoreyDisplay": "Furniture",
						"childCategories": [
							{
								"categoreyName": "Queen Sized Beds ",
								"categoreyDisplay": "Furniture",
		
							},
							{
								"categoreyName": "King Sized Beds",
								"categoreyDisplay": "Furniture",
		
							},
							{
								"categoreyName": "Single Beds Beds",
								"categoreyDisplay": "Furniture",
		
							},
							{
								"categoreyName": "Poster Beds",
								"categoreyDisplay": "Furniture",
		
							},
						]
					},
				]
			},
		
			{
				"cssID" : 'TOP',
				"categoreyName": "Modular Kitchen",
				"categoreyDisplay": "Modular Kitchen",
				"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
				"childCategories": [
					{
						"cssID" : 'CHILD_1',
						"categoreyName": "Modular Kitchens",
						"categoreyDisplay": "Modular Kitchens",
						"childCategories": [
							{
								"cssID" : 'CHILD_2',
								"categoreyName": "Straight Lines",
								"categoreyDisplay": "Straight Lines",
		
							},
							{
								"categoreyName": "Parallels",
								"categoreyDisplay": "Parallels",
		
							},
							{
								"categoreyName": "L Shaped",
								"categoreyDisplay": "L Shaped",
		
							},
							{
								"categoreyName": "U Shaped",
								"categoreyDisplay": "U Shaped",
		
							},
							{
								"categoreyName": "Island",
								"categoreyDisplay": "Island",
		
							},
						]
					},
					{
						"categoreyName": "CookTops",
						"categoreyDisplay": "CookTops",
						"childCategories": [
							{
								"categoreyName": "Gas Stoves",
								"categoreyDisplay": "Gas Stoves",
		
							},
							{
								"categoreyName": "Hobs",
								"categoreyDisplay": "Hobs",
		
							},
							{
								"categoreyName": "Induction Cooktops",
								"categoreyDisplay": "Induction Cooktops",
		
							},
						]
					},
					{
						"categoreyName": "Chimneys",
						"categoreyDisplay": "Chimneys",
						"childCategories": [
							{
								"categoreyName": "Hood Chimneys",
								"categoreyDisplay": "Hood Chimneys",
		
							},
							{
								"categoreyName": "Straight Line Chimneys",
								"categoreyDisplay": "Straight Line Chimneys",
		
							},
						]
					},
					{
						"categoreyName": "Ovens",
						"categoreyDisplay": "Ovens",
						"childCategories": [
							{
								"categoreyName": "Microwave Ovens ",
								"categoreyDisplay": "Microwave Ovens",
		
							},
							{
								"categoreyName": "OTG",
								"categoreyDisplay": "OTG",
		
							},
							{
								"categoreyName": "Built-in Ovens",
								"categoreyDisplay": "Built-in Ovens",
		
							},
						]
					},
					{
						"categoreyName": "Modular Wardrobes",
						"categoreyDisplay": "Modular Wardrobes",
						"childCategories": [
							{
								"categoreyName": "Cabinet",
								"categoreyDisplay": "Hinged",
		
							},
							{
								"categoreyName": "Sliding",
								"categoreyDisplay": "Sliding",
		
							},
							{
								"categoreyName": "Single Beds Beds",
								"categoreyDisplay": "Furniture",
		
							},
							{
								"categoreyName": "Poster Beds",
								"categoreyDisplay": "Furniture",
		
							},
						]
					},
				]
			},
		
			{
				"cssID" : 'TOP',
				"categoreyName": "Kid's Furniture",
				"categoreyDisplay": "Kid's Furniture",
				"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
				"childCategories": [
					{
						"cssID" : 'CHILD_1',
						"categoreyName": "Beds",
						"categoreyDisplay": "Beds",
						"childCategories": [
							{
								"cssID" : 'CHILD_2',
								"categoreyName": "Single Beds",
								"categoreyDisplay": "Single Beds",
		
							},
							{
								"categoreyName": "Double Beds",
								"categoreyDisplay": "Double Beds",
		
							},
							{
								"categoreyName": "Bunk Beds",
								"categoreyDisplay": "Bunk Beds",
		
							},
						]
					},
					{
						"categoreyName": "Mattresses",
						"categoreyDisplay": "Mattresses",
						"childCategories": [
							{
								"categoreyName": "Single Bed Mattresses",
								"categoreyDisplay": "Single Bed Mattresses",
		
							},
							{
								"categoreyName": "Double Bed Mattresses",
								"categoreyDisplay": "Double Bed Mattresses",
		
							},
						]
					},
					{
						"categoreyName": "Seating",
						"categoreyDisplay": "Seating",
						"childCategories": [
							{
								"categoreyName": "Bean Bags",
								"categoreyDisplay": "Bean Bags",
		
							},
							{
								"categoreyName": "Stools",
								"categoreyDisplay": "Stools",
		
							},
						]
					},
					{
						"categoreyName": "Study",
						"categoreyDisplay": "Study",
						"childCategories": [
							{
								"categoreyName": "Book Shelves",
								"categoreyDisplay": "Book Shelves",
		
							},
							{
								"categoreyName": "Book Cases",
								"categoreyDisplay": "Book Cases",
		
							},
							{
								"categoreyName": "Tables & Chair Sets",
								"categoreyDisplay": "Tables & Chair Sets",
		
							},
						]
					},
					{
						"categoreyName": "Storage Unit",
						"categoreyDisplay": "Storage Unit",
						"childCategories": [
							{
								"categoreyName": "Cabinet",
								"categoreyDisplay": "Cabinet",
		
							},
							{
								"categoreyName": "Tunks & Boxes",
								"categoreyDisplay": "Tunks & Boxes",
		
							}
						]
					},
				]
			},
		
			{
				"cssID" : 'TOP',
				"categoreyName": "Carpets & Furnishing",
				"categoreyDisplay": "Carpets & Furnishing",
				"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
				"childCategories": [
					{
						"cssID" : 'CHILD_1',
						"categoreyName": "Carpets",
						"categoreyDisplay": "Carpets",
						"childCategories": [
							{
								"cssID" : 'CHILD_2',
								"categoreyName": "Machine Made",
								"categoreyDisplay": "Machine Made",
		
							},
							{
								"categoreyName": "Hand Made",
								"categoreyDisplay": "Hand Made",
		
							},
						]
					},
					{
						"categoreyName": "Dhurries",
						"categoreyDisplay": "Dhurries",
						"childCategories": [
							{
								"categoreyName": "Cotton Dhurries",
								"categoreyDisplay": "Cotton Dhurries",
		
							},
							{
								"categoreyName": "Wool Dhurries",
								"categoreyDisplay": "Wool Dhurries",
		
							},
							{
								"categoreyName": "Jute & Hemp Dhurries",
								"categoreyDisplay": "Jute & Hemp Dhurries",
							}
						]
					},
					{
						"categoreyName": "Runners",
						"categoreyDisplay": "Runners",
						"childCategories": [
							{
								"categoreyName": "Synthetic Runners",
								"categoreyDisplay": "Synthetic Runners",
		
							},
							{
								"categoreyName": "Cotton Runners",
								"categoreyDisplay": "Cotton Runners",
		
							},
							{
								"categoreyName": "Jute Runners",
								"categoreyDisplay": "Jute Runners",
							},
							{
								"categoreyName": "Wool Runners",
								"categoreyDisplay": "Wool Runners",
							}
						]
					},
					{
						"categoreyName": "Cushions",
						"categoreyDisplay": "Cushions",
						"childCategories": [
							{
								"categoreyName": "Cushion Cover",
								"categoreyDisplay": "Cushion Cover",
		
							},
							{
								"categoreyName": "Cuddle Cushion",
								"categoreyDisplay": "Cuddle Cushion",
		
							},
							{
								"categoreyName": "Floor Cushion",
								"categoreyDisplay": "Floor Cushion",
		
							},
						]
					},
					{
						"categoreyName": "Bolsters",
						"categoreyDisplay": "Bolsters",
						"childCategories": [
							{
								"categoreyName": "Bolsters",
								"categoreyDisplay": "Bolsters",
		
							},
							{
								"categoreyName": "Bolsters Cover",
								"categoreyDisplay": "Bolsters Cover",
		
							}
						]
					},
				]
			},
		]
	ngOnInit() {
		// this.getAllpropertyTypeConfigurationMasters();
		this.showCategoryfilters();
		this.masterService.getExploreMenu().subscribe((res: { payload: any; }) => {
			this.exploreMenuObject = res.payload;
			this.selectedExploreMenu = this.exploreMenuObject[0];

		});

		this.masterService.getExploreFilter().subscribe((res: { payload: any; }) => {
			this.exploreFilterObject = res.payload;
		});

		this.masterService.getExploreColors().subscribe((res: { payload: any; }) => {
			this.exploreColorObject = res.payload;
		});

		this.masterService.getExploreColors().subscribe((res: { payload: any; }) => {
			this.exploreColorObject = res.payload;
			this.childColorArray = this.exploreColorObject[0].childColors;
		});

		this.searchFilterObject = {
			"page": 1,
			"size": 12
		};
		let searchObject = {
			"page": 1,
			"size": 4
		};

		this.route.params.subscribe((params) => {
			if (params['dynamicParam']) {
				this.filterArray = [params['dynamicParam']];
			}
			this.applyNewFilter();
		});

		if (this.loginService.isLoggedIn) {
			//this.isLoggedIn = true;
			// this.conceptBoardService.getConceptBoardViaSearch(searchObject).subscribe(res => {
			// 	if (res.payload.content) {
			// 		this.conceptBoards = res.payload.content;					
			// 	}				
			// });
		}

		//this.exploreMediaService.searchFilter(this.searchFilterObject);
		this.masterService.getExploreFilter().subscribe(res => {
			this.exploreFilterObject = res.payload;

		});

		this.searchObject.where.extendedKeywords = this.filterArray;
		if (this.searchObject.where.extendedKeywords.length > 0) {
			this.searchFilterObject.searchQuery = this.searchObject;
		} else {
			this.searchFilterObject.searchQuery = null;
		}

		this.masterService.getMediaSearch(this.searchFilterObject).subscribe((res: { payload: { totalElements: any; }; }) => {
			if (res && res.payload) {
				this.searchResultObject = res.payload;
				this.totalElements = res.payload.totalElements;
			}
		});
	}

	// getAllpropertyTypeConfigurationMasters() {
	// 	this.estimateService.getAllPropertyTypeConfigurationMaster().subscribe(res => {
	// 		this.propertyTypeConfigurationMasters = res.payload;
	// 	});
	// }

	 setPropertyType(_input: String, _input2: String,subtype: { active: boolean; }) {
	 	this.propertySubTypeSelected = _input2;
	 	this.propertyTypeSelected = _input;
	 	subtype.active = !subtype.active;  

		 
	 }

	chipRemove(_input: any, params: any) {
		var index: any;
		if (this.colorString.includes(_input)) {
			index = this.colorString.indexOf(_input);
		}
		if (index > -1) {
			this.colorString.splice(index, 1)
			this.currentChildColorArray.splice(index, 1);
		}
		if (this.filterArray.includes(_input)) {
			const indexToRemove = this.filterArray.indexOf(_input);
			this.filterArray.splice(indexToRemove, 1);
			//console.log(this.currentChildColorArray);
			String
			this.currentChildColorArray
			this.applyNewFilter();

		}

		

		//this.currentChildColorArray.splice(this.startIndex,1,_input);

	}

	logOut() {
		this.authserviceService.removeToken();
		this.isLoggedIn = false;
		this.myProfile = null;
		this.authserviceService.setToken('');
		this.loginService.setMyProfile('');
		this.loginService.logOut();
		this.setMyProfile('');
		this.router.navigate(['/home']);		
	}

	setMyProfile(_profile: any) {
		this.localStorageService.set("currentProfile", _profile);
		this.myProfile = _profile;
	}

	getMyProfile() {
		this.myProfile = this.localStorageService.get("currentProfile");
		return this.myProfile;
	}

	public setLogin(input: boolean) {
		this.isLoggedIn = input;
	}

	public getLogin() {
		return this.isLoggedIn;
	}

	resetFilters() {
		this.filterArray = [];
		this.currentChildColorArray = [];
		this.colorString = [];
		this.applyNewFilter();

	}


	setFilterArrayFromOutside(someArray: []) {
		this.filterArray = someArray;
		this.applyNewFilter;
	}

	getFilterArrayForOutsider() {
		return this.filterArray;
	}

	openExploreMenu(_group) {
		//console.log(_group);

	}

	applyColorFilterImages(_inputzz: any, event) {

		event.stopPropagation();
		this.filterArray;
		var isChecked = true;

		this.checkedValuesSize = this.filterArray.length;
		this.startIndex = this.filterArray.length;
		if (isChecked) {
			if (!this.filterArray.includes(_inputzz)) {
				this.filterArray.splice(this.startIndex, this.itemsToRemove, _inputzz);
			}
		}
		//this.filterArray = this.checkedValues;
		this.applyNewFilter();
	}


	applyNewFilter() {
		this.searchObject.where.extendedKeywords = this.filterArray;
		if (this.searchObject.where.extendedKeywords.length > 0) {
			this.searchFilterObject.searchQuery = this.searchObject;
		} else {
			this.searchFilterObject.searchQuery = null;
		}
		this.exploreMediaService.appliedFilter = this.filterArray;

		this.masterService.getMediaSearch(this.searchFilterObject).subscribe((res: { payload: any; }) => {

			if (res && res.payload) {
				this.searchResultObject = res.payload;
				this.totalElements = res.payload.totalElements;
			} else {
				this.searchResultObject = [];
				this.totalElements = 0;
			}
			//this.contentObject = this.searchResultObject ? this.searchResultObject.content : [];
		});
		//console.log(this.searchFilterObject);
	}

	getMoreObjects() {



		if (this.searchResultObject.content.length == this.totalElements) {
			return;
		}
		this.searchFilterObject.page = this.searchFilterObject.page + 1;


		this.masterService.getMediaSearch(this.searchFilterObject).subscribe((res: { payload: any; }) => {
			//console.log('MY other page RESPONSE', res);
			this.searchResultObject.content = this.searchResultObject.content.concat(res.payload.content);
			//this.contentObject = this.searchResultObject ? this.searchResultObject.content : [];
		});
	}


	onFilterChange(valuee: string, isChecked: boolean) {
		this.checkedValuesSize = this.filterArray.length;
		this.startIndex = this.filterArray.length;
		if (isChecked) {
			if (!this.filterArray.includes(valuee)) {
				this.filterArray.splice(this.startIndex, this.itemsToRemove, valuee);
			}
		} else {
			if (this.filterArray.includes(valuee)) {
				var indexToRemove = this.filterArray.indexOf(valuee);
				this.filterArray.splice(indexToRemove, 1);
			}
		}
		//this.filterArray = this.checkedValues;
		this.applyNewFilter();
	}

	selectedMenu(_menu) {
		this.selectedTopMenu = _menu;
		this.mainCategoryShow = true;
		this.expandedColor = false;
		this.estimate = false;
	}

	selectedColor(_menu){
		this.selectedTopMenu = _menu;
		this.mainCategoryShow = true;
		this.expandedColor = true;
		this.estimate = false;
		
	}
	selectedEstimateMenu(_estimateMenu){

		this.selectedTopMenu = _estimateMenu;
		this.mainCategoryShow = true;
		this.expandedColor = false;
		this.estimate = true;
		
	}

	showCategoryfilters(){

	}

	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map(result => result.matches)
		);

		selectMenuFromProduct(_menu){
			this.selectedProductMenu = _menu;
		}
	
}
