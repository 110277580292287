import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FaqsComponent} from '../faqs/faqs.component';
import {FaqsDetailComponent} from '../faqs/faqs-detail/faqs-detail.component';
import {FaqsSearchComponent} from '../faqs/faqs-search/faqs-search.component';
import {AboutusComponent} from './pages/aboutus/aboutus.component';
import {OurTeamComponent} from './pages/our-team/our-team.component';
import {HowItWorksComponent} from './pages/how-it-works/how-it-works.component';
import {OfferingsComponent} from './pages/offerings/offerings.component';
import {WebStoryComponent} from './pages/web-story/web-story.component';
import {ContactUsComponent} from '../contact-us/contact-us.component';

const routes: Routes = [{
    path: '',
    children: [
        {
            path: 'about-us',
            component: AboutusComponent
        },
        {
            path: 'our-team',
            component: OurTeamComponent
        },
        {
            path: 'how-it-works',
            component: HowItWorksComponent
        },
        {
            path: 'offerings',
            component: OfferingsComponent
        },
        {
            path: 'spacifii-story',
            component: WebStoryComponent
        },
        {
            path: 'contact-us',
            component: ContactUsComponent
        },
        {
            path: '**',
            component: FaqsComponent
        }
    ],
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InHouseRoutingModule { }

/*
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'about-us' , component: AboutusComponent},
  { path: 'our-team' , component: OurTeamComponent},
  { path: 'how-it-works' , component: HowItWorksComponent},
  { path: 'offerings' , component: OfferingsComponent},
  { path: 'spacifii-story' , component: WebStoryComponent},
*/
