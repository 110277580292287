import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss']
})
export class BusinessProfileComponent implements OnInit {

  accounts=[
    {
      label: 'Company name'
    },
    {
      label: 'Type of Ownership'
    },
    {
      label: 'Point of contact'
    },
    {
      label: 'Copmany website',
      tag: 'http://'
    },
    {
      label: 'Business Description'
    },
    {
      label: 'Enter A Meanigful Tagline, This Will Be Displayed In Your Profile',
      tag: 'Interior Desginer'
    },
    {
      label: 'Choose Your Background Image',
      tag: 'Backgroung Image',
      button:'upload'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
