import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-promise',
  templateUrl: './our-promise.component.html',
  styleUrls: ['./our-promise.component.scss']
})
export class OurPromiseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
