import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConceptboardService } from '../../../../../../src/app/components/conceptBoard/conceptboard.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';


@Component({
	selector: 'app-export-color-canvas',
	templateUrl: './export-color-canvas.component.html',
	styleUrls: ['./export-color-canvas.component.scss']
})
export class ExportColorCanvasComponent implements OnInit {

	shadesOfImage: string[] = [];
	conceptBoards: any;
	currentMediaObject: any;
	conceptBoardName: boolean;
	conceptBoardList: boolean = false;
	addNewConceptBoardFormGroup: FormGroup;
	addExploreConceptBoardFormGroup: FormGroup;
	newConceptBoardId: any;
	expanded = false;
	dataURL: any;
	img: any;
	finalImage: Blob;

	@ViewChild('myCanvas') myCanvas: ElementRef;
	public context: CanvasRenderingContext2D;
	public tiffCanvas: HTMLCanvasElement;

	constructor(
		public dialog: MatDialog,
		private _formBuilder: FormBuilder,
		private conceptBoardService: ConceptboardService,
		public dialogRef: MatDialogRef<ExportColorCanvasComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	conceptBoardMapForUser: any;
	conceptBoardKeyValueToDisplay: any[] = [];
	selecttedConceptBoardName: String;
	selecteConceptBoardId: String;

	ngOnInit() {
		//this.myCanvas.nativeElement.appendChild(this.tiffCanvas);
		this.getMyBoard();

		this.addNewConceptBoardFormGroup = this._formBuilder.group({
			folderName: ['', Validators.required]
		});
		this.addExploreConceptBoardFormGroup = this._formBuilder.group({
			conceptBoardId: ['', Validators.required],
			description: ['', Validators.required]
		});

	}

	ngAfterViewInit(): void {
		this.context = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d');
		if (this.data.source == 'EDIT') {
			this.editImagePointer()
		} else {
			this.shadePainter();
		}
	}

	editImagePointer() {
		this.dataURL = this.data.colorShade // canvas.toDataURL('image/png');
		var block = this.dataURL.split(";");
		var realData = block[1].split(",")[1];
		// Get the content type of the image
		var contentType = block[0].split(":")[1];// In this case "image/gif"
		this.finalImage = this.b64toBlob(realData, contentType);
	}

	shadePainter() {
		var x = 0;
		var y = 0;
		var w = 200;
		var h = 25;
		//var xyz = ;
		for (let i in this.data.colorShade) {
			this.context.beginPath();
			this.context.rect(x, y, w, h);
			this.context.fillStyle = this.data.colorShade[i];
			this.context.fillRect(x, y, w, h);
			y = (y + 1) + h;
		}
		let canvas = <HTMLCanvasElement>document.getElementById('color-strip');
		this.dataURL = canvas.toDataURL('image/png');
		var block = this.dataURL.split(";");
		var realData = block[1].split(",")[1];
		// Get the content type of the image
		var contentType = block[0].split(":")[1];// In this case "image/gif"
		this.finalImage = this.b64toBlob(realData, contentType);
	}

	getMyBoard() {
		this.conceptBoardService.getConceptBoardMap().subscribe(res => {
			if (res.payload) {
				this.conceptBoardMapForUser = res.payload;
				for (let c of Object.entries(this.conceptBoardMapForUser)) {
					let newObj = { "conceptBoardId": c[0], "folderName": c[1] };
					this.conceptBoardKeyValueToDisplay.push(newObj);
				}
			}
		});
	}

	setConceptBoardNameFromHtml(input: string, folderName: string) {
		document.getElementById("cbName").innerHTML = folderName;
		this.selecteConceptBoardId = input;
		this.onClickedOutside();
	}

	saveConceptBoardFormGroup(folderName: string) {
		var conceptBoardNewObject = {
			folderName: this.addNewConceptBoardFormGroup.value.folderName,
			description: "ADDED FROM EXPLORE",
			conceptBoardVisibility: "PRIVATE"
		}
		this.conceptBoardService.addNewConceptBoard(conceptBoardNewObject).subscribe(
			(res: { payload: { id: any; }; }) => {
				this.selecteConceptBoardId = res.payload.id;
				this.getMyBoard();
			}
		)
		document.getElementById("cbName").innerHTML = this.addNewConceptBoardFormGroup.value.folderName;
		this.onClickedOutside();
	}

	onEnter(event) {
		if (event.keyCode == 13) {
			this.saveMediaToConceptBoard();
		}
	}

	saveMediaToConceptBoard() {
		if (!this.selecteConceptBoardId) { return; }
		let input = new FormData();

		input.append("uploadingFiles", this.finalImage, "abcd" + ".png");
		this.conceptBoardService.addMediaToProject(input, this.selecteConceptBoardId, "IMAGE").subscribe((event) => {
		});

		/*console.log(this.addExploreConceptBoardFormGroup.value);
		var boardId = this.selecteConceptBoardId;
		//console.log(boardId);
		var imageAdd = {
			"description": this.addExploreConceptBoardFormGroup.value.description,
			"mediaId": this.currentMediaObject.id
		}
		this.conceptBoardService.addConceptBoardFromExplore(imageAdd, boardId).subscribe((res: { payload: { id: any; }; }) => {
		//	console.log("CAME HERE => ",res);
		});*!/*/
	}

	setConceptBoardName() {
		this.expanded = false;
	}

	closeModal(): void {
		this.dialogRef.close();
	}

	selectConceptBoard() {
		this.conceptBoardList = true;
	}

	onClickedOutside() {
		this.conceptBoardList = false;
	}


	b64toBlob(b64Data, contentType) {
		contentType = contentType || '';

		var sliceSize = 512;

		var byteCharacters = window.atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}
}

