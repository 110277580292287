import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estimate-home-control',
  templateUrl: './estimate-home-control.component.html',
  styleUrls: ['./estimate-home-control.component.scss']
})
export class EstimateHomeControlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
