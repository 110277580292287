import { Component, Input, OnInit } from '@angular/core';
import { AddtoCartService } from './addToCart.service';

export interface Sort {
	value: string;
	viewValue: string;
}


@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

	isActive: any ;
	isAdded:any;
	@Input() products: any = [];
    singleProduct;
	selected = 'relevance';
	brandList: string[] = [
		'Brand 1',
		'Brand 2',
		'Brand 3',
		'Brand 4',
		'Brand 5',
		'Brand 6'
	];

	prizeList: string[] = [
		'Rs.0 - 1000',
		'Rs.1000 - 2000',
		'Rs.2000 - 3000',
		'Rs.3000 - 4000',
		'Rs.4000 - 5000',
		'Rs.5000 - 6000'
	];

	materialList: string[] = [
		'Material 1',
		'Material 2',
		'Material 3',
		'Material 4',
		'Material 5',
		'Material 6'
	];

	sortCategories: Sort[] = [
		{ value: 'relevance', viewValue: 'Relevance' },
		{ value: 'newest', viewValue: 'Newest' },
		{ value: 'low-high', viewValue: 'Price: Low to High' },
		{ value: 'high-low', viewValue: 'Price: High to Low' }
	];

	getProductDetails(){
		return this.productDetails;
	}

	getProductDetailById(_input: string){

		for(let c of this.productDetails){
			if(c.id == _input){
				return c;
			}
		}

	}
	productDetails = [
		{
			id: 'three-seater-sofa-in-chestnut-brown-color',
			name: 'Three Seater Sofa In Chestnut Brown Color',
			longName: 'Long Three Seater Sofa In Chestnut Brown Color',
			description: 'Description of Three Seater Sofa In Chestnut Brown Color',
			longDescription: 'Long Dscription of Three Seater Sofa In Chestnut Brown Color',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/three-seater-sofa-in-chestnut-brown-01.jpg",
				"assets/product/three-seater-sofa-in-chestnut-brown-02.jpg",
				"assets/product/three-seater-sofa-in-chestnut-brown-03.jpg",
				"assets/product/three-seater-sofa-in-chestnut-brown-04.jpg",
				"assets/product/three-seater-sofa-in-chestnut-brown-05.jpg",
				"assets/product/three-seater-sofa-in-chestnut-brown-06.jpg"
			],
			colorImage:[
				"assets/product/three-seater-sofa-small-1.jpg",
				"assets/product/three-seater-sofa-small-2.jpg",
				"assets/product/three-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Brown'
				},
				{
					key: 'Size',
					value: 'Three Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'The collections are a series of modern designs, which are simple yet striking and represent the ideals of minimalism and cutting excess. The designs are a perfect blend of functionality and exceptional aesthetics. Each piece is crafted with passion and reflects quality and style, addressing the needs of a wide range of audience.'
		},
		{
			id: 'two-seater-sofa-in-olive-green-color',
			name: 'Two Seat Sofa In Olive Green Color',
			longName: 'Long Three Seat Sofa In Olive Green Color',
			description: 'Description of Long Three Seat Sofa In Olive Green Color',
			longDescription: 'Long Dscription of Long Three Seat Sofa In Olive Green Color',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/two-seater-sofa-in-olivea-green-01.jpg",
				"assets/product/two-seater-sofa-in-olivea-green-02.jpg",
				"assets/product/two-seater-sofa-in-olivea-green-03.jpg",
				"assets/product/two-seater-sofa-in-olivea-green-04.jpg",
				"assets/product/two-seater-sofa-in-olivea-green-05.jpg",
			],
			colorImage:[
				"assets/product/two-seater-sofa-small-1.jpg",
				"assets/product/two-seater-sofa-small-2.jpg",
				"assets/product/two-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Blue'
				},
				{
					key: 'Size',
					value: 'Two Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
		},
		{
			id: 'three-seater-sofa-in-sandy-brown-color',
			name: 'Three Seat Sofa In Sandy Brown Color',
			longName: 'Long Three Seat Sofa In Sandy Brown Color',
			description: 'Description of Long Three Seat Sofa In Sandy Brown Color',
			longDescription: 'Long Dscription of Long Three Seat Sofa In Sandy Brown Color',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/three-seater-sofa-in-sandy-brown-01.jpg",
				"assets/product/three-seater-sofa-in-sandy-brown-02.jpg",
				"assets/product/three-seater-sofa-in-sandy-brown-03.jpg",				
				"assets/product/three-seater-sofa-in-sandy-brown-04.jpg",
				"assets/product/three-seater-sofa-in-sandy-brown-05.jpg",
				"assets/product/three-seater-sofa-in-sandy-brown-06.jpg",
				"assets/product/three-seater-sofa-in-sandy-brown-07.jpg",
				"assets/product/three-seater-sofa-in-sandy-brown-08.jpg",
			],
			colorImage:[
				"assets/product/three-seater-sofa-small-1.jpg",
				"assets/product/three-seater-sofa-small-2.jpg",
				"assets/product/three-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Red'
				},
				{
					key: 'Size',
					value: 'Three Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
		},
		{
			id: 'two-seater-sofa-in-grey-color',
			name: 'Two Seat Sofa In Grey Color',
			longName: 'Long Three Seat Sofa In Grey Color',
			description: 'Description of Long Three Seat Sofa In Grey Color',
			longDescription: 'Long Dscription of Long Three Seat Sofa In Grey Color',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/two-seater-sofa-in-gray-01.jpg",
				"assets/product/two-seater-sofa-in-gray-02.jpg",
				"assets/product/two-seater-sofa-in-gray-03.jpg",
				"assets/product/two-seater-sofa-in-gray-04.jpg",
				"assets/product/two-seater-sofa-in-gray-05.jpg",
			],
			colorImage:[
				"assets/product/two-seater-sofa-small-1.jpg",
				"assets/product/two-seater-sofa-small-2.jpg",
				"assets/product/two-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Blue'
				},
				{
					key: 'Size',
					value: 'Two Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
		},
		{
			id: 'three-seater-sofa-in-ash-grey',
			name: 'Three Seater Sofa In Ash Grey Color',
			longName: 'Long Three Seat Sofa In Ash Grey Color',
			description: 'Description of Long Three Seat Sofa In Ash Grey Color',
			longDescription: 'Long Dscription of Long Three Seat Sofa In Ash Grey Color',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/three-seater-sofa-in-ash-gray-01.jpg",
				"assets/product/three-seater-sofa-in-ash-gray-02.jpg",
				"assets/product/three-seater-sofa-in-ash-gray-03.jpg",
				"assets/product/three-seater-sofa-in-ash-gray-04.jpg",
				"assets/product/three-seater-sofa-in-ash-gray-05.jpg",
			],
			colorImage:[
				"assets/product/three-seater-sofa-small-1.jpg",
				"assets/product/three-seater-sofa-small-2.jpg",
				"assets/product/three-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Red'
				},
				{
					key: 'Size',
					value: 'Three Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
		},
		{
			id: 'two-seater-sofa-in-warm-chestnut',
			name: 'Two Seat Sofa In Warm Chestnut',
			longName: 'Long Three Seat Sofa In Warm Chestnut',
			description: 'Description of Long Three Seat Sofa In Warm Chestnut',
			longDescription: 'Long Dscription of Long Three Seat Sofa In Warm Chestnut',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/two-seater-sofa-in-warm-chestnut-01.jpg",
				"assets/product/two-seater-sofa-in-warm-chestnut-02.jpg",
				"assets/product/two-seater-sofa-in-warm-chestnut-03.jpg",
				"assets/product/two-seater-sofa-in-warm-chestnut-04.jpg",
				"assets/product/two-seater-sofa-in-warm-chestnut-05.jpg",
			],
			colorImage:[
				"assets/product/two-seater-sofa-small-1.jpg",
				"assets/product/two-seater-sofa-small-2.jpg",
				"assets/product/two-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Blue'
				},
				{
					key: 'Size',
					value: 'Two Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
		},
		{
			id: 'three-seater-sofa-in-garnet-red-color',
			name: 'Three Seat Sofa In Garnet Red Color',
			longName: 'Long Three Seat Sofa In Garnet Red Color',
			description: 'Description of Long Three Seat Sofa In Garnet Red Color',
			longDescription: 'Long Dscription of Long Three Seat Sofa In Garnet Red Color',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/three-seater-sofa-in-garnet-red-01.jpg",
				"assets/product/three-seater-sofa-in-garnet-red-02.jpg",
				"assets/product/three-seater-sofa-in-garnet-red-03.jpg",
				"assets/product/three-seater-sofa-in-garnet-red-04.jpg",
				"assets/product/three-seater-sofa-in-garnet-red-05.jpg"
			],
			colorImage:[
				"assets/product/three-seater-sofa-small-1.jpg",
				"assets/product/three-seater-sofa-small-2.jpg",
				"assets/product/three-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Red'
				},
				{
					key: 'Size',
					value: 'Three Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
		},
		{
			id: 'two-seater-sofa-in-provincial-teak',
			name: 'Two Seat Sofa In Provincial Teak',
			longName: 'Long Three Seat Sofa In Provincial Teak',
			description: 'Description of Long Three Seat Sofa In Provincial Teak',
			longDescription: 'Long Dscription of Long Three Seat Sofa In Provincial Teak',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
			originalPrize: '5000',
			discountPercentage: '10',
			image: [
				"assets/product/two-seater-sofa-in-provincial-teak-01.jpg",
				"assets/product/two-seater-sofa-in-provincial-teak-02.jpg",
				"assets/product/two-seater-sofa-in-provincial-teak-03.jpg",
				"assets/product/two-seater-sofa-in-provincial-teak-04.jpg",
				"assets/product/two-seater-sofa-in-provincial-teak-05.jpg",
			],
			colorImage:[
				"assets/product/two-seater-sofa-small-1.jpg",
				"assets/product/two-seater-sofa-small-2.jpg",
				"assets/product/two-seater-sofa-small-3.jpg"
			],
			details: [
				{
					key: 'Color',
					value: 'Blue'
				},
				{
					key: 'Size',
					value: 'Two Seater'
				},
				{
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
		}
	]

	constructor(private addTocartService: AddtoCartService) { }

	ngOnInit() {
	}


	brandOnChange(event: any) {
	}

	materialOnChange(event: any) {
	}

	prizeOnChange(event: any) {
	}


	stopPropagation(event) {
		event.stopPropagation();

	}
	addToCart(event,productId){
		// if (event.) {
		// 	alert('This product is already added into cart.');
		// 	return false;
		//   }
	  
		  // Change button color to green
		//   this.products.map((item, index) => {
		// 	if (item.id === productId) {
		// 	  this.isAdded[index] = true;
		// 	}
		//   })
	  
		  this.singleProduct = this.products.filter(product => {
			return product.id === productId;
		  });
	  
		  // this.cartItems.push(this.singleProduct[0]);
		  this.addTocartService.addProductToCart(event);
	}

}
