import { Component, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterEvent, NavigationCancel} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {LoginService} from './services/login.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


export class AppComponent {

    public innerWidth: any;
    public innerHeight: any;
    menuState: string;
    scrollBtnDark: boolean = false;
    isShow: boolean;
    topPosToStartShowing = 500;
    myProfile:any;
    loading = true
    scrollPosition;
    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                
                return;
            }
            window.scrollTo(0, 0)

        });
        this.loginService.initializeOnStartup();
    
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loading = true
           
        }
        if (event instanceof NavigationEnd) {
            this.loading = false
           
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false
          //  console.log("reacherd3");
        }
        if (event instanceof NavigationError) {
            this.loading = false
            
        }


    }



    ngAfterViewInit() {
    
    }

    @HostListener('window:scroll')

    checkScroll() {
      
        // window의 scroll top
        // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
    
        this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      //  console.log(this.scrollPosition);
        if (this.scrollPosition >= this.topPosToStartShowing) {
          this.isShow = true;
         
        } else {
          this.isShow = false;
        }
        if(this.scrollPosition >2830)
        {
            this.scrollBtnDark = true;
        }else{
            this.scrollBtnDark = false;
        }

      }
    
    scrollToTop(){
        window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
          });
    }

    constructor(
        public router: Router,
        private translate: TranslateService,
        private toastr: ToastrService,
        private loginService:LoginService,
        private titleService: Title,
        vcr: ViewContainerRef
        ) {
       
        translate.setDefaultLang('en');

        router.events.subscribe((event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
                this.menuState = 'out';
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user

            }
        });

        router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event)
        })

    }

    public setTitle( newTitle: string) {
        this.titleService.setTitle( newTitle );
    }

    // ngOnInit(){
    //   alert(this.innerWidth = window.innerWidth);
    //   alert(this.innerHeight = window.innerHeight);
    // }
}

