import { Component, OnInit, ViewChild } from "@angular/core";
import {
  SwiperComponent,
  SwiperConfigInterface,
  SwiperPaginationInterface,
  SwiperScrollbarInterface,
} from "ngx-swiper-wrapper";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-what-we-offer",
  templateUrl: "./what-we-offer.component.html",
  styleUrls: ["./what-we-offer.component.scss"],
})
export class WhatWeOfferComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {}

  index = 0;

  ngOnInit() {}

  public slides = [
    {
      title: "We Design Everything we Make",
      subHeading: "Just The Way You Imagined It",
      name:
        "SPACIFII helps you to transform your home interiors, Just the way you imagined it, We customise every piece of furniture just for you. Whats more? We promise do it all.",
      img: "https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/imag2.webp",
    },
    {
      title: "WHAT YOU SEE IS WHAT YOU GET",
      subHeading: "No Tall Claims & False Promises !!",
      name:
       "We Provide Professional Project management with full home interiors uphold all our commitments with Quality Home interiors & timely delivery of your project.",
      img: "https://dev-spacifii-images.s3.ap-south-1.amazonaws.com/frontend/imag1.webp",
    }
  ];

  public type: string = "component";

  public disabled: boolean = false;

  public config: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: true,
    navigation: true,
    pagination: false,
    watchOverflow: false,
    loop: false,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: ".swiper-scrollbar",
    hide: false,
    draggable: false,
  };

  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
    hideOnClick: false,
  };

  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  // @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  public toggleType() {
    this.type = this.type === "component" ? "directive" : "component";
  }

  public toggleDisabled() {
    this.disabled = !this.disabled;
  }

  public toggleDirection() {
    this.config.direction =
      this.config.direction === "horizontal" ? "vertical" : "horizontal";
  }

  public toggleSlidesPerView() {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }

  public toggleOverlayControls() {
    if (this.config.navigation) {
      this.config.scrollbar = false;
      this.config.navigation = false;

      this.config.pagination = this.pagination;
    } else if (this.config.pagination) {
      this.config.navigation = false;
      this.config.pagination = false;

      this.config.scrollbar = this.scrollbar;
    } else {
      this.config.scrollbar = false;
      this.config.pagination = false;

      this.config.navigation = false;
    }

    if (this.type === "directive") {
      // this.directiveRef.setIndex(0);
    } else {
      // this.componentRef.directiveRef.setIndex(0);
    }
  }

  public toggleKeyboardControl() {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl() {
    this.config.mousewheel = !this.config.mousewheel;
  }

}
