import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	
	

	footerLinks: any;

	ngOnInit() {}
	

	constructor() {			
		
		this.footerLinks = {

			spacifii: [
				{
					url: "/about-us",
					name: "About us",
					tooltip: "Who we are & What we do"
				},
				// {
				// 	url: "/how-it-works",
				// 	name: "How it works",
				// 	tooltip: "See how we work"
				// },
				// {
				// 	url: "/offerings",
				// 	name: "Offering",
				// 	tooltip: "Check out our offering"

				// },
				// {
				// 	url: "/our-team",
				// 	name: "Team",
				// 	tooltip: "Check out our Team"

				// },
				// {
				// 	url: "/spacifii-story",
				// 	name: "our story",
				// 	tooltip: "a timeline of our progress"
				// }


			],
			consumers: [

				{
					url: "/recently",
					name: "Recently SPACIFIED",
					tooltip: "Check out our work"

				},
				{
					url: "/explore/showcase",
					name: "Xplore",
					tooltip: "Xplore our design gallery"
				},
				{
					url: "/designer/",
					name: "Designer",
					tooltip: "Designer Dashboard"
				},
				{
					url: "/explore/showcase/",
					name: "Bedroom Interior Designs",
					tooltip: "Bedroom Interior Designs"
				},
				{
					url: "/explore/showcase/",
					name: "Interior Design Kitchen",
					tooltip: "Interior Design Kitchen"
				},
				{
					url: "/explore/showcase/",
					name: "Interior designers for Home",
					tooltip: "Interior designers for Home"
				},
				{
					url: "/explore/showcase/",
					name: "Living Room Designs",
					tooltip: "Living room Designs"
				},
				// {
				// 	url: "/estimate",
				// 	name: "Estimate",
				// 	tooltip: "Generate your own estimate"
				// },
				// {
				// 	url: "/product",
				// 	name: "Products & Services",
				// 	tooltip: "View our Product & Service Offering"
				// },

				// {
				// 	url: "/partners",
				// 	name: "Our Partners",
				// 	tooltip: "see who we work with"
				// },

			],
			// partners: [
			// 	{
			// 		url: "/business/spacifiipartnernetwork",
			// 		name: "About Network",
			// 		tooltip: "Read about the Network"
			// 	},
			// 	{
			// 		url: "/business/faq",
			// 		name: "Partner FAQs",
			// 		tooltip: "Questions about the Network"
			// 	},
			// 	{
			// 		url: "/business/jobs",
			// 		name: "Jobs near you",
			// 		tooltip: "Need work! Explore our network"
			// 	},
			// 	{
			// 		url: "/business/insights",
			// 		name: "insights",
			// 		tooltip: "Events, Fucntions, & Promotions"
			// 	},
			// 	{
			// 		url: "/business/downloads",
			// 		name: "Downloads",
			// 		tooltip: "Additional information for you"
			// 	}
			// ],
			others: [
				{
					url: "/faqs",
					name: "FAQs",
					tooltip: "You have questions? We have the answers",
					outerLink:true
				},
				{
					url: "/blog",
					name: "blogs",
					tooltip: "Information, Learning, & Trends",
					outerLink:true
				},
				{
					url: "https://www.linkedin.com/company/spacifii",
					name: "careers",
					tooltip: "Work with us",
					outerLink:true
				},
				{
					url: "/contact-us",
					name: "contact us",
					tooltip: "Have a questions, we are just a call away!",
					outerLink:true
				},
				{
					url: "/about-us",
					name: "About us",
					tooltip: "Who we are & What we do"
				}

			],

			logos :[
				{
					image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/WHITE_LOGO.png'

				}
			],
			footerInfo:{
				mainHeading: "SPACIFII",
				subHeading1:   "Creating Spaces that Matter",
				subHeading2:  "You can also get your questions answered by using our help section.",
				spacifiiHeading: "about ",
				// partnerHeading:  "SPACIFII partner network",
				consumerHeading:  "SPACIFII consumer services",
				otherHeading:  "other",
				footerContact:  "contact us",
				footerContactAddress:  "",
				copyrightText : "Copyright © 2021 SPACIFII All rights reserved.",
				privacyPolicy: "privacy policy",
				termsOfUse: "terms of use",
				legal: "legal",
				siteMap: "sitemap",
				facebookLink: "https://www.facebook.com/spacifiinow/",
				twitterLink:"https://twitter.com/spacifiinow",
				instagramLink:"https://www.instagram.com/spacifiinow/",
				linkedInLink:"https://www.linkedin.com/company/spacifii/",
				spacifiiName:"SPACIFII"
			}
		}
	}

	newWindow(newUrl){
		window.open(newUrl,"_blank")
	}
}
