import {Component, NgModule, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {UserProfileService} from '../../../services/user-profile.service';
import {ToastrService} from 'ngx-toastr';
import {ProfilePictureComponent} from '../personal-profile/profile-picture/profile-picture.component';
import {ConfirmationBoxComponent} from '../../confirmation-box/confirmation-box.component';
import {MatDialog} from '@angular/material';

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

	addressUpdate: FormGroup;
	addressCollection:any[] = [];
	myProfile:any;
	editAddressId:any;
	addressObject:any;
	inEditMode:boolean=false;

	constructor(
		private formBuilder : FormBuilder,
		private loginService: LoginService,
		public userProfileService: UserProfileService,
		private toastr: ToastrService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.addressUpdate = this.formBuilder.group({
			addressLine1: ['',Validators.required],
			addressLine2: ['',Validators.required],
			addressLine3: [''],
			addressLine4: [''],
			country: ['',Validators.required],
			state: ['',Validators.required],
			city: ['',Validators.required],
			addressLabel: ['',Validators.required],
			lpincode: ['',]
		});

		this.myProfile = this.loginService.getMyProfile();
		this.addressCollection = this.myProfile.userProfile.addressDetails;
	}

	isFieldValid(field: string) {
	//	console.log(field);
		return !this.addressUpdate.get(field).valid && this.addressUpdate.get(field).touched;
	}

	displayFieldCss(field: string) {
		return {
			'has-error': this.isFieldValid(field),
			'has-feedback': this.isFieldValid(field)
		};
	}


	objectCreator()
	{
		if(this.inEditMode)
		{
			let addressinfoRequest: any = {
				"addressHeading": "string",
				"addressLine1": "string",
				"addressLine2": "string",
				"addressLine3": "string",
				"addressLine4": "string",
				"city": "string",
				"country": "string",
				"pinCode": "string",
				"state": "string",
				"id":"string"
			}

			addressinfoRequest.addressHeading = this.addressUpdate.value.addressLabel;
			addressinfoRequest.addressLine1 = this.addressUpdate.value.addressLine1;
			addressinfoRequest.addressLine2 = this.addressUpdate.value.addressLine2;
			addressinfoRequest.addressLine3 = this.addressUpdate.value.addressLine3;
			addressinfoRequest.addressLine4 = this.addressUpdate.value.addressLine4;
			addressinfoRequest.city = this.addressUpdate.value.city;
			addressinfoRequest.country = this.addressUpdate.value.country;
			addressinfoRequest.pinCode = this.addressUpdate.value.lpincode;
			addressinfoRequest.state = this.addressUpdate.value.state;
			addressinfoRequest.id = this.editAddressId ;

			return addressinfoRequest;
		}
		else
			{
				let addressinfoRequest: any = {
					"addressHeading": "string",
					"addressLine1": "string",
					"addressLine2": "string",
					"addressLine3": "string",
					"addressLine4": "string",
					"city": "string",
					"country": "string",
					"pinCode": "string",
					"state": "string"
				}

				addressinfoRequest.addressHeading = this.addressUpdate.value.addressLabel;
				addressinfoRequest.addressLine1 = this.addressUpdate.value.addressLine1;
				addressinfoRequest.addressLine2 = this.addressUpdate.value.addressLine2;
				addressinfoRequest.addressLine3 = this.addressUpdate.value.addressLine3;
				addressinfoRequest.addressLine4 = this.addressUpdate.value.addressLine4;
				addressinfoRequest.city = this.addressUpdate.value.city;
				addressinfoRequest.country = this.addressUpdate.value.country;
				addressinfoRequest.pinCode = this.addressUpdate.value.lpincode;
				addressinfoRequest.state = this.addressUpdate.value.state;

				return addressinfoRequest;
			}
	}

	addressInfoUpdate(){
		if (this.addressUpdate.invalid) {
			return;
		}

		var obj = this.objectCreator();
		this.userProfileService.addNewAddress(obj).subscribe(res => {
				this.toastr.success("Address Added /updated Successfully", "");
				this.loginService.setMyUserProfile(res.payload);
				this.editAddressId = "";
				this.inEditMode=false;
				this.ngOnInit();
				//this.addressUpdate.set;
			},
			(error) => {
				//console.log(error);
			});
	}

	EditAddress(id:any) {
		this.editAddressId = id;
		var index = this.addressCollection.findIndex(x => x.id === id);
		this.addressObject = this.addressCollection[index];
		this.inEditMode= true;
		this.addressUpdate.setValue({
			"addressLabel": this.addressObject.addressHeading == null ? '' : this.addressObject.addressHeading,
			"addressLine1": this.addressObject.addressLine1  == null ? '' : this.addressObject.addressLine1,
			"addressLine2": this.addressObject.addressLine2 == null ? '' : this.addressObject.addressLine2,
			"addressLine3": this.addressObject.addressLine3 == null ? '' : this.addressObject.addressLine3,
			"addressLine4": this.addressObject.addressLine4 == null ? '' : this.addressObject.addressLine4,
			"country": this.addressObject.country == null ? '' : this.addressObject.country,
			"state": this.addressObject.state == null ? '' : this.addressObject.state,
			"city": this.addressObject.city == null ? '' : this.addressObject.city,
			"lpincode": this.addressObject.pinCode == null ? '' : this.addressObject.pinCode

		})
	}

	DeleteAddress(id:any)
	{

		var todelete = false;
		const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
			data: {
				headerText: 'Delete Address',
				mainBodyText: "Are you sure you want to delete this Address ?",
				confirmationButtonText: "Delete Address"
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			todelete = result;
			if(todelete) {
				this.userProfileService.removeAddress(id).subscribe(res => {

						this.toastr.success("Address Removed Successfully", "");
						this.loginService.setMyUserProfile(res.payload);
						this.ngOnInit();
					},
					(error) => {
					//	console.log(error);
					});
			}
		});


	}

}
