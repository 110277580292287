import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SpacifiivideoComponent} from '../../shared/spacifiivideo/spacifiivideo.component';
import { SpacifiiService } from '../../services/spacifii.service';
import swal from 'sweetalert'
export interface TimeSlots {
  value: string;
  viewValue: string;
}

export interface BusinessScope {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-design-consult',
  templateUrl: './design-consult.component.html',
  styleUrls: ['./design-consult.component.scss']
})


export class DesignConsultComponent implements OnInit {

  captchaVerified = false;
  online:boolean = false;
  customerForm: FormGroup;
  times: TimeSlots[] = [
    { value: '9:00 - 11:00', viewValue: '9:00am - 11:00am' },
    { value: '11:00 - 13:00', viewValue: '11:00am - 1:00pm' },
    { value: '2:00 - 4:00', viewValue: '2:00pm - 4:00pm' },
    { value: '4:00 - 6:00', viewValue: '4:00pm - 6:00pm' },
    { value: '6:00 - 8:00', viewValue: '6:00pm - 8:00pm' }
  ];

  purposes: BusinessScope[] = [
    { value: 'DESIGNING_EXECUTION', viewValue: 'Project Design  & Execution' },
    { value: 'PEXECUTION', viewValue: 'Execution Only' },
    { value: 'PDESIGNING', viewValue: 'Designing Only' },
    { value: 'SOURCING', viewValue: 'Sourcing of Products' },
    { value: '3D', viewValue: '3D Rendering' },
    { value: 'OTHER', viewValue: 'Other' }
  ];

  constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<DesignConsultComponent>,
      private _formBuilder: FormBuilder,
      private router: Router,
      private toastr: ToastrService,
      private spacifiiService:SpacifiiService
  ) { }



  ngOnInit() {
    this.customerForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(6)]],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[6-9]\\d{9}')]],
      email: ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      date: ['', Validators.required],
      timeToCall: ['', Validators.required],
      purpose: ['', Validators.required],
      modeOfContact:['1', Validators.required],
      comments:[''],
      recaptchaReactive: new FormControl(null, Validators.required)
    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captchaVerified = true;
  }

  closeModal() {
    this.dialogRef.close();
  }

  startCall(username) {
    const dialogRef = this.dialog.open(SpacifiivideoComponent, {
      panelClass: "cdk-image-container-pane",
      data: { name: username}
    });
    dialogRef.afterClosed().subscribe(result => {

    })
  }

  async customerSubmit(){
    const response=await this.spacifiiService.getDataForContactUs(this.customerForm.value,"CONTACTUS")
    if(this.spacifiiService.contactUsData.responseCode===2000){
      swal('Submitted!','Thank you for reaching us')
    }else{
      swal('Error!',"There was an error while submitting your form. Please try again later")
    }
  }
}
