import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})


export class QuizService {
    public arr = [];
  constructor(private httpClient: HttpClient) { }

  //This Method is to add Quiz 
  quizSave(_reqData) : any {
     return this.arr.push(_reqData);
    //let url = `${environment.exploreApi}/api/`;
    //return this.httpClient.post(url,_reqData);
  }

  // Get Quiz for quiz result
  getQuizAnswer(){
     return this.arr;
    // var myUrl = `${environment.exploreApi}/api/`;
   // return this.httpClient.get(myUrl);
  }
}
