import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-our-partner',
  templateUrl: './our-partner.component.html',
  styleUrls: ['./our-partner.component.scss']
})
export class OurPartnerComponent implements AfterViewInit {

  public show: boolean = true;

  public slides = [
    { image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/our-partner/kern-council-of-governments.png' },
    { image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/our-partner/american-society.png' },
    { image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/our-partner/parsons.png'}, 
    { image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/our-partner/edison.jpg' }

  ];

  public type: string = 'component';

  public disabled: boolean = false;

  public config: SwiperConfigInterface = {};

  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  ngAfterViewInit() {
    this.config = {
      direction: 'horizontal',
      slidesPerView: 4,
      navigation: false,
      pagination: false,
      autoplay: true,
      loop: true,
      spaceBetween: 10,
      breakpoints: {
        600: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      }
    }
  }
  constructor() { }
}