import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ConceptboardService } from '../../conceptboard.service';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'app-add-concept-board',
	templateUrl: './add-concept-board.component.html',
	styleUrls: ['./add-concept-board.component.scss']
})
export class AddConceptBoardComponent implements OnInit {

	conceptBoardVisibility: string[];
	addNewConceptBoardFormGroup: FormGroup;


	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<AddConceptBoardComponent>,
		private _formBuilder: FormBuilder,
		private router: Router,
		private toastr: ToastrService,
		private conceptBoardService: ConceptboardService
	) { }

	ngOnInit() {
		this.addNewConceptBoardFormGroup = this._formBuilder.group({
			folderName: ['', Validators.required],
			description: ['', Validators.required],
			conceptBoardVisibility: ['', Validators.required],
			allowComments: [true],
			allowQuestions: [true],
		});
		this.conceptBoardVisibility = this.conceptBoardService.getConceptBoardVisibilityOption();
	}

	public hasError = (controlName: string, errorName: string) =>{
		return this.addNewConceptBoardFormGroup.controls[controlName].hasError(errorName);
	}

	saveConceptBoardFormGroup() {
		if (this.addNewConceptBoardFormGroup.invalid) {
			return;
		}

		this.conceptBoardService.addNewConceptBoard(this.addNewConceptBoardFormGroup.value).subscribe(
			(			res: { payload: { id: any; }; }) => {
				let conceptBoardId = res.payload.id;
				this.toastr.success("Your Concept Board has been Created.Get started by uploading images and sharing.","");
				this.router.navigate(['/concept-board/view/', conceptBoardId])
				this.dialogRef.close();
			},
			(error) => {
				//this.toastr.error(" An error occured while adding . Please Retry!","");
			}

			);
	}

	closeModal(): void {
		this.dialogRef.close();
	}

	

	

}
