import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

export  interface ConfirmationBoxData {
  headerText: string;
  mainBodyText: string;
  confirmationButtonText: string;
}

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

  headerText: string;
  mainBodyText: string;
  confirmationButtonText: string;

  constructor(
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close(false);
  }

  SuccessAction()
  {
    this.dialogRef.close(true);
  }
}
