import {Component, NgModule, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { LoginService } from '../../../../src/app/services/login.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';


@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	forgotPassword: FormGroup;
	validateShow: boolean = false;
	otpGenerated: boolean = false;
	resendcount:any=0;
	submittedOTPGeneration = false;
	submittedOTP = false;

	constructor(
		private formBuilder: FormBuilder, private loginService: LoginService,
		private toastr: ToastrService, private router: Router,

	) { }

	get f() { return this.forgotPassword.controls; }



	booleanEnterOtp: boolean = false;
	booleanEnterPassword: boolean = false;
	otpReference: String;

    account_validation_messages = {

        'password': [
            { type: 'required', message: 'Password is required' },
            { type: 'minlength', message: 'Password must be at least 6 characters long' },
            { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number and one special character' }
        ]
    }



	ngOnInit() {
		this.forgotPassword = this.formBuilder.group({
			mobileNumber: ['', Validators.required],
			otp: ['', Validators.required],
			newPassword:['',[Validators.required, Validators.minLength(6), Validators.pattern("((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{6,20})")]],
			confirmNewPassword:['',[Validators.required, Validators.minLength(6), Validators.pattern("((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{6,20})")]]

			/*newPassword: ['', [
                Validators.required,
                Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,16}')
            ]],
			confirmNewPassword: ['', [
                Validators.required,
                Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,16}')
            ]]*/
		});
	}

	checkPasswords() { // here we have the 'passwords' group
		let pass = this.forgotPassword.controls.newPassword.value;
		let confirmPass = this.forgotPassword.controls.confirmNewPassword.value;
		return pass === confirmPass ? null : { notSame: true }
	}

	updatePassword() {
    	var pattern = '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{6,20})'
		var pword = this.forgotPassword.value.newPassword;
    	var x = pword.match(pattern);
    	if(this.forgotPassword.invalid){return;}
    	else {
			if(this.checkPasswords()){
				this.toastr.error("The Passwords you have entered do not match. Please retry.","");
				return;
			}

			if (x != null) {
				if (this.forgotPassword.value.newPassword == this.forgotPassword.value.confirmNewPassword) {
					var mobileNumber = this.forgotPassword.value.mobileNumber;
					var passwordObject = {
						"id": mobileNumber,
						"password": this.forgotPassword.value.newPassword
					}
					this.loginService.setNewUserProfilePassword(passwordObject).subscribe(res => {
						this.toastr.success("Password Updated, Please Login Now!", "");
						this.router.navigate(["/login/customer"]);
					});
				} else {
					//throw some exception toast
					this.toastr.error("Passwords do not match. Key in the passwords again", "");
				}
			} else {
				this.toastr.error("Password need to have a length of 6 and a maximum of 20 with 1 Capital and 1 Special Character", "");
			}
		}


		//setNewUserProfilePassword()
	}

	validateOtp() {

        if (!this.f.otp.valid) {
            return;
        }

		var otpObject = {
			"mobile": this.forgotPassword.value.mobileNumber,
			"otp": this.forgotPassword.value.otp,
			"otpReference": this.otpReference
		}

		//validateOtpForResetPassword()
		if (this.forgotPassword.value.otp != null) {
			this.loginService.validateOtpForResetPassword(otpObject).subscribe(res => {
				this.booleanEnterOtp = false;
				this.booleanEnterPassword = true;
                this.submittedOTP = true;
			})
		} else {
			return;
		}

	}

	generateOtp() {
		this.submittedOTPGeneration = true;
		if(!this.f.mobileNumber.valid)
		{
			return;
		}
		if(this.resendcount < 3) {

			var mobileNumber = this.forgotPassword.value.mobileNumber;
			if (mobileNumber != null) {

				this.loginService.resetUserProfilePassword(mobileNumber).subscribe(res => {
					this.otpReference = res.payload.otpReference;
					this.booleanEnterOtp = true;
					this.validateShow = true;
					this.otpGenerated = true;
					this.toastr.success("Your OTP has been sent to your Registered Mobile Number!","");
					this.resendcount = this.resendcount+1;
					this.booleanEnterOtp = true;
					this.submittedOTPGeneration = false;
				});
			} else {
				return;
			}
		}
		else{
			this.toastr.error("You have exceeded the Number of OTP Generation Attempts. Please try again later","");
		}
		}

	public hasError = (controlName: string, errorName: string, type:string) =>{
		if(type=="PWD"){
			return this.forgotPassword.controls[controlName].hasError(errorName);
		}
	}





}
