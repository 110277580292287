import { Injectable } from '@angular/core';
import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { environment } from '../../../../src/environments/environment';

import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import { ApiResponse } from '../../../../src/app/services/api-response';


@Injectable({
	providedIn: 'root'
})

export class FaqsService {



	categoriesRes: any;
	searchResult: any;
	searchObject: any;



	constructor(private httpClient: HttpClient) { }

	
	// Customer FAQ
	getFaqCategoryTreeCustomer(){
		let url = `${environment.faqsApi}/api/faqCategory/getFaqCategoryTreeCustomer`;
		return this.httpClient.get(url);
	}

	// BUsiness FAQ
	getFaqCategoryTreeBusiness(){
		let url = `${environment.faqsApi}/api/faqCategory/getFaqCategoryTreeBusiness`;
		return this.httpClient.get(url);
	}

	getAllFaqsCategories(): any {
		let url = `${environment.faqsApi}/api/faqCategory/getFaqCategoryTree`;
		return this.httpClient.get(url);		
	}

	getFaq(category: String): any {
		this.categoriesRes = this.httpClient.get(`${environment.faqsApi}/api/faq/${category}/getFaqsForCategory`);
		return this.categoriesRes;
	}

	searchFaq(_req): any {
		this.searchObject = _req;
		return this.httpClient.post(`${environment.faqsApi}/api/faq/searchFaq`, _req);
	}

	search(_req): Observable<ApiResponse> {
		return this.httpClient.post(`${environment.faqsApi}/api/faq/searchFaq`, _req)
			.pipe(
				tap((response: ApiResponse) => {
					return response;
				})
			);
	}

	getsearchResult() {
		//console.log(this.searchResult);
		return this.searchResult;
	}
	setsearchResult(_searchResult) {
		this.searchResult = _searchResult;
	}

	getSearchObject() {
		return this.searchObject;
	}
}
