import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import {DesignConsultComponent} from '../../components/design-consult/design-consult.component';
@Component({
  selector: 'app-newHome',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss']
})
export class NewHomeComponent implements OnInit {
  data: any = [
  //   {
  //   "parent": "Inspiration",
  //   "child":
  //     [
  //       { 
  //         "name": "Space",
  //          "url": "/Space"
  //         },
  //       {
  //          "name": "Color",
  //          "url": "/color" 
  //         },
  //       { 
  //         "name": "Style",
  //         "url": "/Space" 
  //       },
  //       { 
  //         "name": "Div",
  //         "url": "/Space" 
  //       },
  //     ]
  // }, 
  
  {
    "parent": "Spacifield",
    "child":
      [
        { 
          "name": "Recently Spacifii",
           "url": "/recently"
         },
        {
           "name": "Featured Celeb",
           "url": "/explore/showcase/"
           },
        { 
          "name": "Just Started",
          "url": "/Space" 
        },
        { 
          "name": "How it works",
          "url": "/how-it-works" 
        },
      ]
  }, 
  {
    "parent": "About",
    "child":
      [
        { 
          "name": "Story",
          "url": "/about-us" 
        },
        { 
          "name": "Team",
          "url": "/Space" 
        },
        { 
          "name": "Customer Speak",
          "url": "/Space" 
        },
        { 
          "name": "In News",
          "url": "/Space" 
        },
      ]
  },
  // {
  //   "parent": "Contact Us",
  //   "child":
  //     [
  //       { 
  //         "name": "+91 9920602200",
  //         "url": "tel:+919920602200" 
  //       },
  //       { 
  //         "name": "022 489 34098/99",
  //         "url": "tel:+022 489 34098" 
  //       },
  //       { 
  //         "name": "reachus@spacifii.com",
  //         "url": "mailto: email:reachus@spacifii.com" 
  //       }
  //     ]
  // }
];


dataMobile: any = [
  //   {
  //   "parent": "Inspiration",
  //   "child":
  //     [
  //       { 
  //         "name": "Space",
  //          "url": "/Space"
  //         },
  //       {
  //          "name": "Color",
  //          "url": "/color" 
  //         },
  //       { 
  //         "name": "Style",
  //         "url": "/Space" 
  //       },
  //       { 
  //         "name": "Div",
  //         "url": "/Space" 
  //       },
  //     ]
  // }, 
  
  {
    "parent": "Spacifield",
    "child":
      [
        { 
          "name": "Recently Spacifii",
           "url": "/recently"
         },
        {
           "name": "Featured Celeb",
           "url": "/explore/showcase/"
           },
        { 
          "name": "Just Started",
          "url": "/explore/showcase/" 
        },
        { 
          "name": "How it works",
          "url": "/how-it-works" 
        },
      ]
  }, 
  {
    "parent": "About",
    "child":
      [
        { 
          "name": "Story",
          "url": "/about-us" 
        },
        { 
          "name": "Team",
          "url": "/explore/showcase/" 
        },
        { 
          "name": "Customer Speak",
          "url": "/explore/showcase/" 
        },
        { 
          "name": "In News",
          "url": "/explore/showcase/" 
        },
      ]
  },
  {
    "parent": "Contact Us",
    "child":
      [
        { 
          "name": "+91 9920602200",
          "url": "/newhome" 
        },
        { 
          "name": "022 489 34098/99",
          "url": "/newhome" 
        },
        { 
          "name": "reachus@spacifii.com",
          "url": "/newhome" 
        }
      ]
  }
];
  email:string="";
  constructor(public dialog: MatDialog) { }

  ngOnInit() {}
  toggleAccordian(event, index) {
      const element = event.target;
      element.classList.toggle("active");
      if (this.dataMobile[index].isActive) {
        this.dataMobile[index].isActive = false;
      } else {
        this.dataMobile[index].isActive = true;
      }
      const panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }

  }
  
	openB2CForm() {
		const dialogRef = this.dialog.open(DesignConsultComponent, {
			maxHeight:window.outerHeight + 'px'
			//panelClass: 'concept-modalbox'
		});
		dialogRef.afterClosed().subscribe();
	}
  onSubmit(f){
    console.log(f.value)
  }
}