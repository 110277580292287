import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FaqsService } from '../faqs.service';

@Component({
	selector: 'app-faqs-detail',
	templateUrl: './faqs-detail.component.html',
	styleUrls: ['./faqs-detail.component.scss']
})
export class FaqsDetailComponent implements OnInit {

	constructor(private route: ActivatedRoute, private router: Router, private faqsService: FaqsService) { }
	faqCategory: any;
	faqs: any[] =[];
	faqCategories: any[]=[];

	ngOnInit() {

		this.route.params.subscribe((params) => {
			this.faqCategory = decodeURIComponent(params['dynamicParam']);
		});

		this.faqsService.getFaq(this.faqCategory).subscribe(res => {
			this.faqs = res.payload;
		});

		this.faqsService.getAllFaqsCategories().subscribe(res => {
			this.faqCategories = res.payload;
		});
	}

	bindFAQ(catName:string){
		this.faqs =[];
		let category = decodeURIComponent(catName);
		this.faqsService.getFaq(category).subscribe(res => {
			this.faqs = res.payload;
		});

		this.faqsService.getAllFaqsCategories().subscribe(res => {
			this.faqCategories = res.payload;
		});
	}

	loadLink(catName:string){
		this.router.navigate(['/faqs/', catName]);
		this.bindFAQ(catName);

	}

	 isHTML(str) {
		var doc = new DOMParser().parseFromString(str, "text/html");
		return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
	}

}
