import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
@Injectable({
    providedIn:'root'
})

export class AddtoCartService{
    public cartItems = [];
    public products = new Subject();

    getProducts(): Observable<any> {
        return this.products.asObservable();
      }
    
      setProducts(products) {
        this.cartItems.push(...products);
        this.products.next(products);
      }

      addProductToCart(product) {
        this.cartItems.push(product);
        this.products.next(this.cartItems);
      }
    
      removeProductFromCart(productId) {
        this.cartItems.map((item, index) => {
          if (item.id === productId) {
            this.cartItems.splice(index, 1);
          }
        });
        this.products.next(this.cartItems);
      }
    

      emptryCart() {
        this.cartItems.length = 0;
        this.products.next(this.cartItems);
      }
    
      getTotalPrice() {
        let total = 0;
    
        this.cartItems.map(item => {
          total += parseInt(item.spacifiiPrize);
        });
    
        return total
      }

} 