import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from 'angular-web-storage';
import {UserProfileService} from '../../../services/user-profile.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  myProfile:any;
  frmContactUpdate: FormGroup;
  myReturnObject: any;
  pMobile:any="";
  sMobile:any="";
  pEmail:any ="";
  pLandline:any ="";
  sEmail:any = "";
  contactDetails:any[] =[];

  constructor(
      public localStorageService:LocalStorageService,
      public userProfileService:UserProfileService,
      private _formBuilder: FormBuilder,
      private loginService: LoginService,
      private toastr: ToastrService

  ) { }

  get f() { return this.frmContactUpdate.controls; }

  ngOnInit() {
    this.myProfile = this.loginService.getMyProfile();
    this.contactDetails = this.myProfile.userProfile.contactDetails;

    this.frmContactUpdate = this._formBuilder.group({
      pMobile: ['',Validators.required],
      sMobile: ['',Validators.required],
      pLandline: ['',Validators.required],
      pEmail: ['',Validators.required],
      sEmail: ['', [Validators.required, Validators.email]],
    });

    this.frmContactUpdate.setValue({
      "pMobile" : this.myProfile.userProfile.mobile,
      "pEmail" : this.myProfile.userProfile.email,
      "pLandline" :'',
      "sEmail" : '',
      "sMobile": '',
    })

    if(this.contactDetails != null && this.contactDetails.length > 0 )
    {
      this.frmContactUpdate.setValue({
        "pLandline" :this.contactDetails[0].landLineNumber,
        "sEmail" : this.contactDetails[0].email,
        "sMobile": this.contactDetails[0].mobile,
        "pMobile" : this.myProfile.userProfile.mobile,
        "pEmail" : this.myProfile.userProfile.email

      })

    }


  }

  UpdateContactDetails(){

    if (this.frmContactUpdate.invalid) {
      return;
    }

    let otpRequest:any = {
      "email": "string",
      "landLineNumber": "string",
      "mobile": "string"
    }

    otpRequest.email =  this.frmContactUpdate.value.sEmail;
    otpRequest.landLineNumber =  this.frmContactUpdate.value.pLandline;
    otpRequest.mobile =  this.frmContactUpdate.value.sMobile;

    this.userProfileService.updateContactDetails(otpRequest).subscribe(res => {
          this.toastr.success("Contact Details updated Successfully","");
          this.loginService.setMyProfile(res.payload);
          this.ngOnInit();
        },
        (error) => {
          //console.log(error);
        });

  }

}
