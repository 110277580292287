import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { environment } from '../../../src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { LocalStorageService } from 'angular-web-storage';


@Injectable({
	providedIn: 'root'
})
export class LoginService {

	isLoggedIn: boolean = false;
	isDesingerLoggedIn: boolean = false;
	myProfile: any;
	loginProfile:any;
	showB2BDialog: boolean;
	showB2CDialog: boolean;
	constructor(
		private httpClient: HttpClient,
		public localStorageService:LocalStorageService
	) { }

	initializeOnStartup(){
		if(this.getMyProfile() == null)
		{
			this.isLoggedIn = false;
		}
		else {
			this.isLoggedIn = true;
		}
		if(this.getDesignerProfile() == null)
		{
			this.isDesingerLoggedIn = false;
		}
		else {
			this.isDesingerLoggedIn = true;
		}
		this.getPopUpB2BProfile();
		this.getPopUpB2CProfile();

		
	}

	doLogin(_reqData): any {
		const headers = new HttpHeaders().set("Authorization", _reqData);
		return this.httpClient.get(`${environment.loginApi}/api/token/generateToken`, { headers })
	}

	getMyUserProfile(): any {
		const url = `${environment.registrationApi}/api/userProfile/getMyUserProfile`;
		return this.httpClient.get(url);	
	}

	logOut() {
		this.isLoggedIn = false;
		this.isDesingerLoggedIn = false;
		this.myProfile = null;
		this.localStorageService.set("loginProfile",'');
	}
      getPopUpB2CProfile(){
		this.showB2CDialog = this.localStorageService.get("showModalCustomer");
		return this.showB2CDialog;
	  } 
	  getPopUpB2BProfile(){
		this.showB2BDialog = this.localStorageService.get("showModalDesigner");
		return this.showB2BDialog;
	  } 
	setMyProfile(_profile:any){
		this.localStorageService.set("currentProfile",_profile);
		this.myProfile = _profile;
	  }
	  setDesignerProfile(_profile:any){  
		 if(_profile != null || '') 
		 {
			let yet =_profile.userProfile.userType;
			this.localStorageService.set("loginProfile",yet);
		 }else
		 {
			this.localStorageService.set("loginProfile",'');
		 }

	  }


  setMyUserProfile(_profile:any){
		  this.myProfile.userProfile = _profile;
		  this.localStorageService.set("currentProfile",this.myProfile); 
	  }
	
	getMyProfile(){
		this.myProfile = this.localStorageService.get("currentProfile");
		return this.myProfile;
	}
	getDesignerProfile(){
		this.loginProfile = this.localStorageService.get("loginProfile");
		return this.loginProfile;
	}
	
	setLogin(input : boolean){
		this.isLoggedIn = input;
	}

	setTokenForDesigner(input : boolean){
		this.isDesingerLoggedIn = input;
	}

	getLogin(){
		let profile = this.getMyProfile();
		this.isLoggedIn = profile?true:false;
		return this.isLoggedIn;
	}

	gotoDesigner()
	{
		let designer  = this.getDesignerProfile();
		this.isDesingerLoggedIn = designer?true:false;
		return this.isDesingerLoggedIn; 
	}
	//This is Step 2 for Reset Password
	validateOtpForResetPassword(_reqData: any):any{
		const url = `${environment.registrationApi}/api/userProfile/validateOtpForResetPassword`;
		return this.httpClient.post(url,_reqData);
	}
	

	//This is step 1 for Reset Password
	resetUserProfilePassword(_reqData:any):any {
	    var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		const url = `${environment.registrationApi}/api/userProfile/resetUserProfilePassword`;
		return this.httpClient.post(url,_reqData,options);
	}

	//This is Step  for Reset Password
	setNewUserProfilePassword(_reqData: any): any{
		const url = `${environment.registrationApi}/api/userProfile/setNewUserProfilePassword`;
		return this.httpClient.post(url,_reqData);
	}
	
	
}
