import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-objectviewer',
  templateUrl: './objectviewer.component.html',
  styleUrls: ['./objectviewer.component.scss']
})
export class ObjectviewerComponent implements OnInit {
  @Input('docData') docData: any;

  objectData:any;

  constructor() { }

  ngOnInit() {
    this.objectData = this.docData;
  }

}
