import { Component, OnInit, Input } from '@angular/core';
import { Route, Router } from '@angular/router';


export interface Select {
	routerLink: string;
	viewValue: string;
	value: string;
  }
@Component({
	selector: 'app-edit-settings',
	templateUrl: './edit-settings.component.html',
	styleUrls: ['./edit-settings.component.scss']
})
export class EditSettingsComponent implements OnInit {
	
	selects: Select[] = [
		{routerLink: '/profile/personal-profile', viewValue: 'Personal Profile',value:'personalProfile-0'},
		{routerLink: '/profile/edit-mobile', viewValue: 'Edit Primary Mobile',value:'editPrimaryMobile-1'},
		{routerLink: '/profile/edit-email', viewValue: 'Edit Primary Email',value:'editPrimaryEmail-2'},
		{routerLink: '/profile/address', viewValue: 'Address',value:'address-3'},
		{routerLink: '/profile/contact', viewValue: 'Your Contact Information',value:'yourContactInformation-4'},
		{routerLink: '/profile/change-password', viewValue: 'Change Password',value:'changePassword-5'},
		{routerLink: '/profile/bank-information', viewValue: 'Bank Information',value:'bankInformation-6'},
		{routerLink: '/profile/documents', viewValue: 'Documents',value:'documents-7'}
	  ];

	@Input() selected: string;
	constructor(
		private router: Router
	) { }

	ngOnInit() {
	}




	navigateTo(value: any) {
		if (value) {
			this.router.navigate([value]);
		}
		return false;
	}
}
