import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColladaViewerComponent } from './collada-viewer/collada-viewer.component';
import { MaterialModule } from '../../../material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [ColladaViewerComponent],
  exports:[ColladaViewerComponent],
  entryComponents:[]
})
export class ColladaModule { }
