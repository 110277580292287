import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-normalstyle',
  templateUrl: './normalstyle.component.html',
  styleUrls: ['./normalstyle.component.scss']
})
export class NormalstyleComponent implements OnInit {
  @Input() singlePageObject:any;
  displayURL: any;
  constructor(private sanitizer: DomSanitizer) { 
this.displayURL = sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/tgbNymZ7vqY');

}
getSantizeVideoUrl(_inData) {
return this.sanitizer.bypassSecurityTrustResourceUrl(_inData.replace("watch?v=", "embed/"));
}

  ngOnInit() {
  }

}
