import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UserProfileComponent} from '../user-profile/user-profile.component';
import {FaqsComponent} from './faqs.component';
import {FaqsDetailComponent} from './faqs-detail/faqs-detail.component';
import {FaqsSearchComponent} from './faqs-search/faqs-search.component';

const routes: Routes = [{
  path: '',
  children: [
    {
      path: 'home',
      component: FaqsComponent
    },
    {
      path: 'detail/:dynamicParam',
      component: FaqsDetailComponent
    },
    {
      path: 'search',
      component: FaqsSearchComponent
    },
    {
      path: '**',
      component: FaqsComponent
    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule { }


