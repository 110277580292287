import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '../../../node_modules/@angular/common/http';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExploreMediaService {

  constructor(private httpClient: HttpClient) { }

  private _appliedFilter: any;
  private _mediaSearcResults: any;
  private _searchFilter: any;

  getfullPageMedia(mediaId: any): any {
    const url = `${environment.exploreApi}/api/extra/${mediaId}/getEnvelopedB2CMediaForMedia`;
    return this.httpClient.get(url);
  }


  getAllMediaComments(mediaId: any): any {
    const url = `${environment.exploreApi}/api/mediaUserAction/${mediaId}/getAllMediaComments`;
    return this.httpClient.get(url);
  }

  //For inserting Like, Comment, Question
  postMediaUserAction(inputData: any): any {
    const url = `${environment.exploreApi}/api/mediaUserAction/addMediaUserAction`;
    return this.httpClient.post(url,inputData);
  }

  //Function to CHeck if Media Liked by me
  IsMediaLikedByMe(mediaId: any): any {
    const url = `${environment.exploreApi}/api/mediaUserAction/${mediaId}/isMediaLikedByMe`;
    return this.httpClient.get(url);
  }

  //Function to Send Emails
  shareExploreMedia(inputData: any): any {
    const url = `${environment.exploreApi}/api/mediaEmailShare/shareExploreMedia`;
    return this.httpClient.post(url,inputData);
  }

  //For inserting Like, Comment, Question
  paginateEnvelopedB2CMediaGlobalSearch(inputData: any): any {
    const url = `${environment.exploreApi}/api/extra/paginateEnvelopedB2CMediaGlobalSearch`;
    return this.httpClient.post(url,inputData);
  }


  paginateEnvelopedB2CMediaGlobalSearchViaUpload(inputData: any, page: number, size: number): any {
    /*var options = { headers: new HttpHeaders().set('Content-Type', 'multipart/form-data') };*/
    const url = `${environment.exploreApi}/api/extra/page/${page}/size/${size}/paginateEnvelopedB2CMediaGlobalSearch`;
    return this.httpClient.post(url,inputData);
  }



  get appliedFilter(): any {
    //console.log("GETTING" + this._appliedFilter);
    return this._appliedFilter;
  }

  set appliedFilter(value: any) {
    //console.log("SETTING" + value)
    this._appliedFilter = value;
  }

  get mediaSearcResults(): any {
    return this._mediaSearcResults;
  }

  set mediaSearcResults(value: any) {
    this._mediaSearcResults = value;
  }


  get searchFilter(): any {
    return this._searchFilter;
  }

  set searchFilter(value: any) {
    this._searchFilter = value;
  }
}
