import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { MaterialModule } from '../material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent, MySearchDialogComponentDialog } from './components/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common'

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { WhySpacifiiComponent } from './components/why-spacifii/why-spacifii.component';
import { CounterModule } from 'angular-circle-counter';
import { OurPartnerComponent } from './components/our-partner/our-partner.component';
import { KeyFeaturesComponent } from './components/key-features/key-features.component';

import { MobileStickyFooterComponent } from './components/mobile-sticky-footer/mobile-sticky-footer.component';

import { CustomerRegistrationComponent } from './components/registration/customer-registration/customer-registration.component';
import { PartnerRegistrationComponent } from './components/registration/partner-registration/partner-registration.component';
import { HttpClient } from '@angular/common/http';
import { AngularWebStorageModule } from 'angular-web-storage';



import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { JoinOurTeamComponent } from './components/join-our-team/join-our-team.component';
import { HelloComponent } from './components/hello/hello.component';
import { ContactFormsComponent } from './components/contact-forms/contact-forms.component';
import { FloatingActionMenuModule } from 'ng2-floating-action-menu';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { EditorModule } from '@tinymce/tinymce-angular';


import { NgxPaginationModule } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NgxMasonryModule } from 'ngx-masonry';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { InHouseModule } from './pages/in-house-web/in-house.module';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

//import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgxDragAndDropDirective } from './directives/dropzone.directive';
import { NgxThumbnailDirective } from './directives/thumbnail.directive';
import { WhyPeopleLoveUsModule } from './components/why-people-love-us/why-people-love-us/why-people-love-us.module';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ImagesComponent } from './pages/images/images.component';
import { CustomerTrackerComponent } from './pages/customer-tracker/customer-tracker.component';
import { RecentlySpacifiiModule } from './pages/recently-spacifii/recently-spacifii.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InterceptorService } from './services/interceptor.service';

import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GallerizeModule } from '@ngx-gallery/gallerize';


import { ToastrModule } from 'ngx-toastr';
import { SettingsModule } from './components/settings/settings.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';

import { ClickOutsideModule } from 'ng-click-outside';
// import { EstimateModule } from './components/estimate/estimate.module';

import { SharedModuleModule } from './shared/shared-module.module';
import { FieldErrorDisplayComponent } from './components/field-error-display/field-error-display.component';
import { ImageDimension } from './pipes/imageDimension.pipe';
import { ContentDimensionPipe } from './pipes/contentDimension.pipe';
import { OurStoryComponent } from './components/our-story/our-story.component';
import {ConceptBoardData} from './iData/concept-board-data';
import { ProductComponent } from './components/product/product.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { NgxGalleryModule } from 'ngx-gallery';
import {EmbedVideo} from 'ngx-embed-video/dist';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {HomeModule} from './pages/home/home.module';
import {CloseBoxComponent} from './components/close-box/close-box.component';
import { PrivacyComponent } from './pages/staticPages/legal/privacy/privacy.component';
import { DesignConsultComponent } from './components/design-consult/design-consult.component';
import {SpacifiivideoComponent} from './shared/spacifiivideo/spacifiivideo.component';
import { FinancingOptionsComponent } from './components/financing-options/financing-options.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TooltipModule } from 'ng2-tooltip-directive';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { QuizComponent } from './pages/quiz/quiz.component';
import { NewHomeComponent } from './pages/new-home/new-home.component';
import { AddtoCartService } from './components/product/addToCart.service';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
	direction: 'horizontal',
	slidesPerView: 'auto'
};

// @ts-ignore
// @ts-ignore
@NgModule({
	declarations: [
//MAIN COMPONENTS
		NgxDragAndDropDirective,
		NgxThumbnailDirective,
		AppComponent,
		FooterComponent,
		MainNavComponent,
		NewHomeComponent,
//GLOBAL
		MobileStickyFooterComponent,

//Our Story
		OurStoryComponent,
		WhySpacifiiComponent,
		OurPartnerComponent,

		

//Registration
		CustomerRegistrationComponent,
		PartnerRegistrationComponent,
		ForgotPasswordComponent,
		KeyFeaturesComponent,



//CONTACT US
		ContactUsComponent,
		ContactFormsComponent,

//STATIC
		JoinOurTeamComponent,


		MySearchDialogComponentDialog,

//SUSPECT
		HelloComponent,

//CONCEPT BOARD
	
		//DashboardComponent,
		ImagesComponent,
		CustomerTrackerComponent,
		ConfirmationBoxComponent,

		FieldErrorDisplayComponent,
		ContentDimensionPipe,

//PRODUCT

		ProductComponent,
		ProductDetailComponent,

		CloseBoxComponent,
		PrivacyComponent,
		DesignConsultComponent,
		FinancingOptionsComponent,
		QuizComponent,

		],
	imports: [
		NgxGalleryModule,
		NgxMaterialTimepickerModule,
		TooltipModule,
		ClickOutsideModule,
		SettingsModule,
		GalleryModule,
		LightboxModule,
		GallerizeModule,
		WhyPeopleLoveUsModule,
		BrowserModule,
		RecaptchaFormsModule,
		RecaptchaModule,
		AppRoutingModule,
		MaterialModule,
		BrowserAnimationsModule,
		LayoutModule,
		SwiperModule,
		CounterModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		HttpModule,
		InfiniteScrollModule,
		NgxPaginationModule,
		AngularWebStorageModule,
		EditorModule,
		FloatingActionMenuModule,
		NgxMasonryModule,
		NgMatSearchBarModule,
		InHouseModule,
		// EstimateModule,
		EmbedVideo.forRoot(),
		RecentlySpacifiiModule,
		CommonModule,
		HomeModule,
		SharedModuleModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			maxOpened:2,
			autoDismiss:true,
			newestOnTop:true,
			preventDuplicates: true
		}),
		FroalaEditorModule.forRoot(),
		FroalaViewModule.forRoot(),
		PdfViewerModule,
		NgxChartsModule,
		FormlyModule.forRoot(),
		FormlyMaterialModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	exports :[
		ProductComponent,
		ProductDetailComponent,
		DesignConsultComponent,
		FinancingOptionsComponent
	],

	providers: [
		{
			provide: SWIPER_CONFIG,
			useValue: DEFAULT_SWIPER_CONFIG
		},
		ImageDimension,
		ContentDimensionPipe,
		ConceptBoardData,
		AddtoCartService,
		Title,
		ProductComponent,
		DeviceDetectorService,
		{provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}
	],
	bootstrap: [AppComponent],
	entryComponents: [
		MySearchDialogComponentDialog,
		ConfirmationBoxComponent,
		DesignConsultComponent,
		SpacifiivideoComponent,
		FinancingOptionsComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA,
		 NO_ERRORS_SCHEMA],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
