import { Component, OnInit, Inject } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

export  interface CloseBoxData {
  headerText: string;
  mainBodyText: string;
  confirmationButtonText: string;
}
@Component({
  selector: 'app-close-box',
  templateUrl: './close-box.component.html',
  styleUrls: ['./close-box.component.scss']
})
export class CloseBoxComponent implements OnInit {

  headerText: string;
  mainBodyText: string;
  confirmationButtonText: string;


  constructor(
    public dialog: MatDialog,
      public dialogRef: MatDialogRef<CloseBoxComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close(false);
  }

  SuccessAction()
  {
    this.dialogRef.close(true);
  }

}
