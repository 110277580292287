import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { environment } from '../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProjectDetailService {

  constructor(private httpClient: HttpClient) { }

  //For inserting Like, Comment, Question
  postProjectUserAction(inputData: any): any {
    const url = `${environment.exploreApi}/api/projectUserAction/addProjectUserAction`;
    return this.httpClient.post(url,inputData);
  }

  getAllCommentsForProject(projectId: any): any {
    const url = `${environment.exploreApi}/api/projectUserAction/${projectId}/getAllProjectComments`;
    return this.httpClient.get(url);
  }

  IsProjectLikedByMe(projectId: any): any {
    const url = `${environment.exploreApi}/api/mediaUserAction/${projectId}/isProjectLikedByMe`;
    return this.httpClient.get(url);
  }


}
