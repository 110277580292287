import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { EditSettingsComponent } from './edit-settings/edit-settings.component';
import { AddressComponent } from './address/address.component';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { ContactComponent } from './contact/contact.component';
import { EditEmailComponent } from './edit-email/edit-email.component';
import { EditMobileComponent } from './edit-mobile/edit-mobile.component';
import { PersonalProfileComponent } from './personal-profile/personal-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BankInformationComponent } from './bank-information/bank-information.component';
import { DocumentsComponent } from './documents/documents.component';
import { MaterialModule } from '../../../../src/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsRoutingModule } from './settings-routing.module';
import { ProfilePictureComponent } from './personal-profile/profile-picture/profile-picture.component';
import { ImageCropperModule } from 'ngx-image-cropper'
//import {FieldErrorDisplayComponent} from '../field-error-display/field-error-display.component';

@NgModule({
  declarations: [
    EditSettingsComponent,    
    AddressComponent,
    BusinessProfileComponent,
    ContactComponent,
    EditEmailComponent,
    EditMobileComponent,
    PersonalProfileComponent,
    ChangePasswordComponent,
    BankInformationComponent,
    DocumentsComponent,
    PersonalProfileComponent,
    ProfilePictureComponent,
    //FieldErrorDisplayComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule
  ],
  exports:[
    SettingsRoutingModule,
    DocumentsComponent
  ],
  entryComponents:[
    ProfilePictureComponent
  ]
})
export class SettingsModule { }
