import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-recently-spacifii-header',
  templateUrl: './recently-spacifii-header.component.html',
  styleUrls: ['./recently-spacifii-header.component.scss']
})
export class RecentlySpacifiiHeaderComponent implements OnInit {

  @Input() selected: string;
  constructor() { }

  ngOnInit() {
  }

}
