import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '../../../node_modules/@angular/common/http';
import { environment } from '../../../src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UserProfileService {

	constructor(
		private httpClient: HttpClient
	) { }

	getUserProfile(profileId: any): any {
		let url = `${environment.registrationApi}/api/userProfile/${profileId}/getEnvelopedUserPublicProfile`;
		return this.httpClient.get(url);
	}

	getUserProjects(profileId: any, _reqdata: any): any {
		let url = `${environment.exploreApi}/api/extra/${profileId}/findEnvelopedProjectWithFiltersByProfileId`;
		return this.httpClient.post(url, _reqdata);
	}

	showUserProjects(projectId: any) : any {
		let url = `${environment.exploreApi}/api/extra/${projectId}/getEnvelopedProjectByProjectId`;
		return this.httpClient.get(url);
	}

	//This Method is with the New Mobile Number
	changeMobileStepOne(mobile: string) : any {
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/changeUserProfileMobile`;
		return this.httpClient.post(url,mobile,options);
	}

	//This Method is with the New Mobile Number
	changeMobileWithOTP(_reqData) : any {
		let url = `${environment.registrationApi}/api/userProfile/validateOtpForChangeMobileNumber`;
		return this.httpClient.post(url,_reqData);
	}

	//B2C Updating the Primary Email through the Edit Settings Section
	changeEmailStepOne(email: string) : any {
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/changeUserProfileEmail`;
		return this.httpClient.post(url,email,options);
	}

	//B2C Updating the Promary Mobile through the Edit Settings Section
	changeEmailWithOTP(_reqData) : any {
		let url = `${environment.registrationApi}/api/userProfile/validateOtpForChangeEmailAddress`;
		return this.httpClient.post(url,_reqData);
	}

	//B2C Updating the Contact Information through the Edit Settings Section
	updateContactDetails(_reqData) : any {
		let url = `${environment.registrationApi}/api/userProfile/addSecondaryContactDetailsToUserProfile`;
		return this.httpClient.post(url,_reqData);
	}

	//B2C Updating the Personal Information through the Edit Settings Section
	updatePersonalInformation(_reqData) : any {
		let url = `${environment.registrationApi}/api/userProfile/updateUserProfileB2C`;
		return this.httpClient.post(url,_reqData);
	}

	// Add Image To Public Profile - This Method is used to add the personal, wallpaper and company logo
	addMediaToProfile(_reqData, uploadKey): any {
		var myUrl = `${environment.exploreApi}/api/generalMedia/${uploadKey}/uploadGeneralMedia`;
		return this.httpClient.post(myUrl,_reqData);
	}

	//AddNewAddress
	addNewAddress(_reqData): any
	{
		let url = `${environment.registrationApi}/api/userProfile/addUserProfileAddressDetail`;
		return this.httpClient.post(url,_reqData);

	}

	//AddNewAddress
	removeAddress(addressId): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/removeUserProfileAddressDetail`;
		return this.httpClient.post(url,addressId,options);

	}

	//Add Profile PHoto
	changeUserProfileProfilePhoto(photoUrl): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/changeUserProfileProfilePhoto`;
		return this.httpClient.post(url,photoUrl,options);

	}

	userProfileFlowAddProfileInformation(userProfileFlowProfileInformationDto):any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/UserProfileFlowAddProfileInformation`;
		return this.httpClient.post(url,userProfileFlowProfileInformationDto,options);
	}

	//Add Profile Background Photo
	changeUserProfileProfileBackgroundPhoto(photoUrl): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/changeUserProfileProfileBackgroundPhoto`;
		return this.httpClient.post(url,photoUrl,options);

	}

	//Add/ Change Profile Descritption
	changeUserProfileProfileDescription(description): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/changeUserProfileProfileDescription`;
		return this.httpClient.post(url,description,options);

	}

	//Add/ Change Profile ID
	changeUserProfileProfileId(profileId): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/changeUserProfileProfileId`;
		return this.httpClient.post(url,profileId,options);

	}

	//Add/ Change Profile Social Settings
	changeUserProfileSocial(socialObject): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/changeUserProfileSocial`;
		return this.httpClient.post(url,socialObject,options);

	}


	//Add Lead for User Profile
	createUserProfileForLead(userProfileLeadRequestDto): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/createUserProfileForLead`;
		return this.httpClient.post(url,userProfileLeadRequestDto,options);

	}

	//Get Email For SubjectId
	getEmailIdForSubjectId(): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/getEmailIdForSubjectId`;
		return this.httpClient.post(url,options);

	}


	//Get  SubjectId for User Profile
	getSubjectIdForUserProfile(profileId): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/getSubjectIdForUserProfile`;
		return this.httpClient.post(url,profileId,options);

	}

	//Get Subject IDs for Emails
	getSubjectIdsForEmailIds(emailArray): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/getSubjectIdsForEmailIds`;
		return this.httpClient.post(url,emailArray,options);

	}

	//Get Subject IDs for Emails
	getUserEnvelopedProfileFiltered(filterRequestDto): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/getUserEnvelopedProfileFiltered`;
		return this.httpClient.post(url,filterRequestDto,options);

	}


	//Get Subject IDs for Emails
	getUserProfileBuCustomerSearchDto(mapObject): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/getUserProfileBuCustomerSearchDto`;
		return this.httpClient.post(url,mapObject,options);

	}

	//Get User Profile Map By Subject ID
	getUserProfileMapBySubjectId(dataObject): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/getUserProfileMapBySubjectId`;
		return this.httpClient.post(url,dataObject,options);

	}

	//Get User Profile Map By Subject ID
	getUserProfileViaEmailIds(emailIdArray): any
	{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		let url = `${environment.registrationApi}/api/userProfile/getUserProfileViaEmailIds`;
		return this.httpClient.post(url,emailIdArray,options);

	}

	//Get User Public Profile
	getUserPublicProfile(profileId:any)
	{
	var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
	let url = `${environment.registrationApi}/api/userProfile/${profileId}/getUserPublicProfile`;
	return this.httpClient.get(url,options);

	}
}
