import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecentlyService {

  constructor(
    private httpClient:HttpClient
  ) { }

  getRecentlySpacifii(_filterObj){
    const url = `${environment.exploreApi}/api/recentlySpacifiedProject/getPageEnvelopedRecentlySpacifiedProjectPublic`;
    return this.httpClient.post(url, _filterObj);
  }

  getRecentlyProjectById(_projectId){
    const url = `${environment.exploreApi}/api/recentlySpacifiedProject/${_projectId}/findEnvelopedRecentlySpacifiedProjectDtoExtendedByProjectId`;
    return this.httpClient.get(url);
  }
}
