import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SliderComponent } from '../../../../src/app/components/slider/slider.component';
import { OurPromiseComponent } from '../../components/our-promise/our-promise.component';
import { NowTrendingComponent } from '../../../../src/app/components/now-trending/now-trending.component';
import { WeCreateGreatThingsComponent } from '../../../../src/app/components/we-create-great-things/we-create-great-things.component';
import { WhyPeopleLoveUsModule } from '../../../../src/app/components/why-people-love-us/why-people-love-us/why-people-love-us.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MaterialModule } from '../../../../src/material.module';
import { HomeRoutingModule } from './home-routing.module';
import {SharedModuleModule} from '../../shared/shared-module.module';
import {HoverstyleComponent} from '../../components/hoverstyle/hoverstyle.component';
import {NormalstyleComponent} from '../../components/normalstyle/normalstyle.component';
import {RibbonsyleComponent} from '../../components/ribbonsyle/ribbonsyle.component';
import { HomeEstimateComponent } from '../../components/home-estimate/home-estimate.component';
import { HomeProductComponent } from '../../components/home-product/home-product.component';
import { FeaturedArticlesComponent } from '../../components/featured-articles/featured-articles.component';
import { DeferLoadDirective } from '../../defer-load.directive';
import { ColladaModule } from '../../../app/components/collada/collada.module';
import { ShortLinksComponent } from '../../components/sharedComponents/short-links/short-links.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { RecaptchaModule } from 'ng-recaptcha';
import {WhatWeOfferComponent} from '../../components/what-we-offer/what-we-offer.component'
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper'
import {	HowDoWeOfferComponent} from '../../components/how-do-we-offer/how-do-we-offer.component'
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  autoplay:true
};
 
@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    WhyPeopleLoveUsModule,
    ColladaModule,
    SwiperModule,
    MaterialModule,
    SharedModuleModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    RecaptchaModule
  ],
  declarations: [
    HomeComponent,
    SliderComponent,

    OurPromiseComponent,
    NowTrendingComponent,
    WeCreateGreatThingsComponent,
    NormalstyleComponent,
    RibbonsyleComponent,
    HomeEstimateComponent,
    HomeProductComponent,
    HoverstyleComponent,
    NormalstyleComponent,
    RibbonsyleComponent,
    FeaturedArticlesComponent,
    DeferLoadDirective,
    ShortLinksComponent,
    WhatWeOfferComponent,
    HowDoWeOfferComponent
  ],
  providers: [  
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [

  ],
  exports:[
    FeaturedArticlesComponent,
    HoverstyleComponent,
    NormalstyleComponent,
    RibbonsyleComponent
  ]

})

export class HomeModule { }
