import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { OfferingsComponent } from './pages/offerings/offerings.component';
import { WebStoryComponent } from './pages/web-story/web-story.component';
import { OurTeamComponent } from './pages/our-team/our-team.component';
import { InhouseHeadersComponent } from './pages/inhouse-headers/inhouse-headers.component';
import { RouterLink } from '@angular/router';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from '../../../material.module';
import { WhyPeopleLoveUsModule } from '../../components/why-people-love-us/why-people-love-us/why-people-love-us.module';
import {InHouseRoutingModule} from './in-house-routing.module';
import { FaqModule } from '../faqs/faq.module';



@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    WhyPeopleLoveUsModule,
    InHouseRoutingModule,
    FaqModule
  ],
  declarations: [
    AboutusComponent,
    HowItWorksComponent,
    OfferingsComponent ,
    OurTeamComponent ,
    InhouseHeadersComponent,
    WebStoryComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class InHouseModule { }
