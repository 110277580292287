import { Injectable, Input } from '@angular/core';
import { HttpClient } from '../../../../node_modules/@angular/common/http';
import { environment } from '../../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})




export class BlogService {

  constructor(private httpClient: HttpClient) { }


  getBlogCategories():any{
    let url = `${environment.faqsApi}/api/blogCategory/getBlogCategoryTree`;
    return this.httpClient.get(url);
  }

  // getFeaturedHomePost():any{
  //   let url = `${environment.faqsApi}/api/post/getFeaturedHomePost`;
  //   return this.httpClient.get(url);
  // }
  getBlogByID(id)
  {
		const url = `assets/blog/data/${id}.json`;
		return this.httpClient.get(url);	
  }
  getFeaturedBlog(_req):any{
    let url = `${environment.faqsApi}/api/post/getFeaturedFilterPost`;
    return this.httpClient.post(url,_req);
  }

  getMostViewedBlog(_req):any{
    let url = `${environment.faqsApi}/api/post/getMostViewedBlogPaginated`;
    return this.httpClient.post(url,_req);
  }

  getRecentlyAddedBlog(_req):any{
    let url = `${environment.faqsApi}/api/post/getRecentlyAddedBlogPaginated`;
    return this.httpClient.post(url,_req);
  }


  getFilterBlog(_req):any{
    let url = `${environment.faqsApi}/api/post/getBlogFilterSearch`;
    return this.httpClient.post(url,_req);
  }

  getSearchBlog(_req):any{
    let url = `${environment.faqsApi}/api/post/getBlogGlobalSearch`;
    return this.httpClient.post(url,_req);
  }

  postBlogComment(_req):any{
    let url = `${environment.faqsApi}/api/blogUserAction/addMediaUserAction`;
    return this.httpClient.post(url,_req);
  }
  
  getPostByTitle(_req):any{
    const headers = {"Content-Type": "application/json"}
    let url = `${environment.faqsApi}/api/post/getPostByTitle`;
    return this.httpClient.post(url,_req,{headers});
  }
  
}
