import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ConceptboardService} from '../../components/conceptBoard/conceptboard.service';

export interface AddLink {
  parentId: string;
  parentType:string
}

@Component({
  selector: 'app-externallink',
  templateUrl: './externallink.component.html',
  styleUrls: ['./externallink.component.scss']
})
export class ExternallinkComponent implements OnInit {

  externalLinkForm: FormGroup;
  id :any;
  urlPattern:any='^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';

  constructor(
      private _formBuilder: FormBuilder,
      private sanitizer: DomSanitizer,
      private conceptBoardService: ConceptboardService,
      private toastr: ToastrService,
      public dialogRef: MatDialogRef<ExternallinkComponent>,
      @Inject(MAT_DIALOG_DATA) public data: AddLink

  ) {
    this.externalLinkForm = this._formBuilder.group({
      name: ['', Validators.required],
      externalUrl: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.id = this.data.parentId;
  }

  saveLink() {

    if(this.externalLinkForm.invalid)
    {return;}
    if(this.externalLinkForm.dirty){

      let Obj = [
        {
          "description": this.externalLinkForm.value.description,
          "name": this.externalLinkForm.value.name,
          "externalUrl": this.externalLinkForm.value.externalUrl,
          "mediaType": "EXTERNALURL",
        }
      ]

      if(this.data.parentType == 'CONCEPT'){
        this.conceptBoardService.addExternalMediaToConceptBoard(this.id,Obj).subscribe((res: { payload: any; }) => {
              this.toastr.success("Your External Link has been Added.","");
              this.dialogRef.close(true);
            },
            (error) => {
              this.toastr.error(" An error occured while adding this Link . Please Retry!","");
            }

        );
      }
      /*else if(this.data.parentType == 'PROJECT'){
        this.newProjectService.addExternalMediaToProject(this.id,Obj).subscribe((res: { payload: any; }) => {
              this.toastr.success("Your External Link has been uploaded.","");
              this.dialogRef.close(true);
            },
            (error) => {
              this.toastr.error(" An error occured while adding this Link . Please Retry!","");
            }

        );
      }*/


    }

  }

  closeModal(){
    this.dialogRef.close();
  }

}
