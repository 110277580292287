import {Component, NgModule, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../../../../src/app/services/login.service';
import { LocalStorageService } from 'angular-web-storage';
import { UserProfileService } from '../../../services/user-profile.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';

@Component({
	selector: 'app-personal-profile',
	templateUrl: './personal-profile.component.html',
	styleUrls: ['./personal-profile.component.scss']
})
export class PersonalProfileComponent implements OnInit {

	personalProfileForm: FormGroup;
	myProfile: any;
	social: any;
	profileImageURL:string="";

	constructor(
		private formBuilder: FormBuilder,
		private loginService: LoginService,
		public localStorageService: LocalStorageService,
		public userProfileService: UserProfileService,
		private toastr: ToastrService,
		public dialog: MatDialog,


	) {
		this.myProfile = this.loginService.getMyProfile();
	}

	get f() { return this.personalProfileForm.controls; }

	ngOnInit() {
		this.personalProfileForm = this.formBuilder.group({
			name: ['', Validators.required],
			lastName: ['', Validators.required],
			personalWebsite: [''],
			companyName: [''],
			companyWebsite: [''],
			designation: [''],
			industryType: [''],
			facebook: [''],
			google: [''],
			twitter: [''],
			youtube: [''],
			linkedIn: [''],
			instagram: ['']
		})

		this.myProfile = this.loginService.getMyProfile();
		this.profileImageURL = this.GetImageURL(this.myProfile.userProfile.profilePicture);
		this.social = this.myProfile.userProfile.social;

		this.personalProfileForm.setValue({
			"name": this.myProfile.userProfile.name == null ? '' : this.myProfile.userProfile.name,
			"lastName": this.myProfile.userProfile.lastName == null ? '' : this.myProfile.userProfile.lastName,
			"personalWebsite": this.myProfile.userProfile.personalWebsite == null ? '' : this.myProfile.userProfile.personalWebsite,
			"companyName": this.myProfile.userProfile.companyName == null ? '' : this.myProfile.userProfile.companyName,
			"companyWebsite": this.myProfile.userProfile.companyWebsite == null ? '' : this.myProfile.userProfile.companyWebsite,
			"designation": this.myProfile.userProfile.designation == null ? '' : this.myProfile.userProfile.designation,
			"industryType": this.myProfile.userProfile.industryType == null ? '' : this.myProfile.userProfile.industryType,

			"facebook": this.social == null ? '' : this.social.facebook == null ? '' : this.social.facebook,
			"google": this.social == null ? '' : this.myProfile.userProfile.google == null ? '' : this.social.google,
			"twitter": this.social == null ? '' : this.myProfile.userProfile.twitter == null ? '' : this.social.twitter,
			"youtube": this.social == null ? '' : this.myProfile.userProfile.youtube == null ? '' : this.social.youtube,
			"linkedIn": this.social == null ? '' : this.myProfile.userProfile.linkedIn == null ? '' : this.social.linkedIn,
			"instagram": this.social == null ? '' : this.myProfile.userProfile.instagram == null ? '' : this.social.instagram
		})
	}

	UpdatePersonalDetails() {
		if (this.personalProfileForm.invalid) {
			return;
		}

		let personalinfoRequest: any = {
			"name": "string",
			"lastName": "string",
			"personalWebsite": "string",
			"companyName": "string",
			"companyWebsite": "string",
			"designation": "string",
			"industryType": "string",
			"facebook": "string",
			"google": "string",
			"twitter": "string",
			"youtube": "string",
			"linkedIn": "string",
			"instagram": "string",
			"profilePicture": "string"
		}

		personalinfoRequest.name = this.personalProfileForm.value.name;
		personalinfoRequest.lastName = this.personalProfileForm.value.lastName;
		personalinfoRequest.personalWebsite = this.personalProfileForm.value.personalWebsite;
		personalinfoRequest.companyName = this.personalProfileForm.value.companyName;
		personalinfoRequest.companyWebsite = this.personalProfileForm.value.companyWebsite;
		personalinfoRequest.designation = this.personalProfileForm.value.designation;
		personalinfoRequest.industryType = this.personalProfileForm.value.industryType;
		personalinfoRequest.profilePicture = this.profileImageURL;
		personalinfoRequest.facebook = this.personalProfileForm.value.facebook;
		personalinfoRequest.google = this.personalProfileForm.value.google;
		personalinfoRequest.twitter = this.personalProfileForm.value.twitter;
		personalinfoRequest.youtube = this.personalProfileForm.value.youtube;
		personalinfoRequest.linkedIn = this.personalProfileForm.value.linkedIn;
		personalinfoRequest.instagram = this.personalProfileForm.value.instagram;

		this.userProfileService.updatePersonalInformation(personalinfoRequest).subscribe(res => {
			this.toastr.success("Contact Details updated Successfully", "");
			this.loginService.setMyUserProfile(res.payload);
			this.ngOnInit();
		},
			(error) => {
				//console.log(error);
			});
	}

	changeProfilePicture(){
		const dialogRef = this.dialog.open(ProfilePictureComponent, {
			panelClass: "cdk-image-container-pane"
		});
		return dialogRef.afterClosed().subscribe(result =>{
			if(result != null) {

				this.profileImageURL = this.GetImageURL(result);
                var ls = this.localStorageService.get('currentProfile');
                var cp = ls.userProfile;
                cp.profilePicture = result;
                ls.userProfile = cp;
                this.localStorageService.set('currentProfile', ls);
                this.userProfileService.changeUserProfileProfilePhoto(result).subscribe(res => {
                        this.toastr.success('Your Profile Picture Has been Uploaded', '');
                    },
                    (error) => {
						this.toastr.error("Oops, we messed up. Try again please","");
                      //  console.log(error);
                    });

			}
    },
			(error) => {
				this.toastr.error("Oops, we messed up. Try again please","");
			//	console.log(error);
			});
	}

	GetImageURL(stringurl:string)
	{
		if(stringurl != null)
		{
			return stringurl.replace("CHANGE_MY_SIZE",'100x100')
		}
	}



}
