import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {RegistrationService} from '../../../../../src/app/services/registration.service';
import {ToastrService} from 'ngx-toastr';
import swal from 'sweetalert'


@Component({
    selector: 'app-customer-registration',
    templateUrl: './customer-registration.component.html',
    styleUrls: ['./customer-registration.component.scss']
})
export class CustomerRegistrationComponent implements OnInit {

    otpGenerated: any;
    otpFormGroup: FormGroup;
    isOptional = false;
    selectedStep = 0;
    passwordFormGroup: FormGroup;
    contactDetaild: FormGroup;
    otpReference: any;
    id: any;

    constructor(private _formBuilder: FormBuilder, private registrationService: RegistrationService, private toastr: ToastrService) {
    }

    ngOnInit() {
		// ------------- Company Details ---------- //
		this.contactDetaild = this._formBuilder.group({
			name: ['', Validators.required],
            mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[6-9]\\d{9}')]],
            email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
        });

        this.otpFormGroup = this._formBuilder.group({
            otpCode: ['', Validators.required]
        });

        this.passwordFormGroup = this._formBuilder.group({

            password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,20}')]],
            password1: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,20}')]]

        });


    }

    generateOTP() {
        //Function from Ashish to generate an OTP from Registration
    }

    checkPasswords() { // here we have the 'passwords' group
        let pass = this.passwordFormGroup.controls.password.value;
        let confirmPass = this.passwordFormGroup.controls.password1.value;
        return pass === confirmPass ? false :  true;
    }

    saveContactDetaild() {

        var contactDetaildObj = this.contactDetaild.value;
        contactDetaildObj.userType = 'B2C_CUSTOMER';
        this.registrationService.saveContactDetailsB2C(contactDetaildObj).subscribe(res => {
                this.otpReference = res.payload.otpReference;
                this.selectedStep = this.selectedStep + 1;
                this.toastr.success('An OTP has been generated and sent to your Mobile Number', '');
            },
            (error) => {
                //console.log(error)
                this.toastr.error('This Phone number is already resgitered','');
            });
    }

    validateOtp() {

        var otpObj: any = {};//this.otpFormGroup.value;
        otpObj.mobile = this.contactDetaild.value.mobile;
        otpObj.otpReference = this.otpReference;
        otpObj.otp = this.otpFormGroup.value.otpCode;

        this.registrationService.validateOtpB2C(otpObj).subscribe(res => {
            this.id = res.payload.id;
            this.selectedStep = this.selectedStep + 1;
        });
    }

    savePassword() {
       // console.log("heelo")
        if (this.passwordFormGroup.invalid) {
            return;
        }
        if (this.checkPasswords()) {
            this.toastr.error('The Passwords you have entered do not match. Please retry.', '');
            return;
        }

        var passwordObj = this.passwordFormGroup.value;
        passwordObj.id = this.id;
        this.registrationService.setPasswordB2C(passwordObj).subscribe(res => {

                this.otpReference = res.payload.otpReference;
                this.selectedStep = this.selectedStep + 1;
            },
            (error) => {
               // console.log(error.responseMessage)
                this.toastr.error('There is Something Problem !... ');
            });
    }

    public hasError = (controlName: string, errorName: string, type: string) => {
        if (type == 'CD') {
            return this.contactDetaild.controls[controlName].hasError(errorName);
        }
        if (type == 'OTP') {
            return this.otpFormGroup.controls[controlName].hasError(errorName);
        }
        if (type == 'PWD') {
            return this.passwordFormGroup.controls[controlName].hasError(errorName);
        }
    };

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    // async sendOtp(){
    //     const response=await this.registrationService.getOtpDTOForB2C(this.contactDetaild.value)
    // }

}
