import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private httpClient: HttpClient) { }

  getOwnerShipType():any{
    let url = `${environment.registrationApi}/api/ownershipType/getAllOwnershipTypes`;
    return this.httpClient.get(url);
  }

  getUserType(){
    let url= `${environment.registrationApi}/api/userType/getAllUserTypes`;
    return this.httpClient.get(url);
  }

  getCountryStateCityMaster(){
    let url= `${environment.masterApi}/api/country/state/city`;
    return this.httpClient.get(url);
  }

  getStyleMasters():any{
    let url =`${environment.masterApi}/api/styleMaster/getStyleMasters`;
    return this.httpClient.get(url);
    }

  getExploreMenu():any{
    let url =`${environment.exploreApi}/api/featuredKeywordMenu/api/getAllFeaturedKeywordMenu`;
    return this.httpClient.get(url);
  }

  getExploreFilter():any{
    let url =`${environment.exploreApi}/api/featuredKeywordFilter/api/getAllFeaturedKeywordFilter`;
    return this.httpClient.get(url);
  }

  getMediaSearch(_req):any{
    let url =`${environment.exploreApi}/api/extra/paginateEnvelopedB2CMediaSearch`;
    return this.httpClient.post(url,_req);   
  }
 

  getExploreColors():any{
    let url = `${environment.exploreApi}/getAllEnvelopedSpacifiiColor`;
    return this.httpClient.get(url);
  }

  getProjectByProjectId(projectId: any) {
		let url = `${environment.exploreApi}/api/extra/${projectId}/getEnvelopedProjectByProjectId`;
		return this.httpClient.get(url);
	}

}
