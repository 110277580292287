import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageDimension } from '../pipes/imageDimension.pipe';
import {PublicboardsComponent} from '../components/sharedComponents/publicboards/publicboards.component';
import { Timeago } from '../pipes/timeago.pipe';
import {EstimateHomeControlComponent} from '../components/sharedComponents/estimate-home-control/estimate-home-control.component';
import {ExportColorCanvasComponent} from '../components/filters/component/export-color-canvas/export-color-canvas.component';
import {MaterialModule} from '../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConceptBoardComponent} from '../components/conceptBoard/components/concept-board/concept-board.component';
import {AddConceptBoardComponent} from '../components/conceptBoard/components/add-concept-board/add-concept-board.component';
import {RouterModule} from '@angular/router';
import { ProjectCommentsComponent } from '../components/project-comments/project-comments.component';
import {OpenVideoComponent} from '../components/conceptBoard/components/open-video/open-video.component';
import {PdfviewerComponent} from '../components/conceptBoard/components/objectviewer/objecttypes/pdfviewer/pdfviewer.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ObjectviewerComponent} from '../components/conceptBoard/components/objectviewer/objectviewer.component';
import {AddPanoramaComponent} from '../components/conceptBoard/components/add-panorama/add-panorama.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {PanoModule} from '../pano/pano.module';
import { ExternallinkComponent } from './externallink/externallink.component';
import { SpacifiivideoComponent } from './spacifiivideo/spacifiivideo.component';

@NgModule({
  declarations: [

    ImageDimension,
    PublicboardsComponent,
    Timeago,
    PublicboardsComponent,
    EstimateHomeControlComponent,
    ExportColorCanvasComponent,
    ConceptBoardComponent,
    AddConceptBoardComponent,
    ProjectCommentsComponent,
    OpenVideoComponent,
    PdfviewerComponent,
    ObjectviewerComponent,
    ExternallinkComponent,
    SpacifiivideoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    PdfViewerModule
  ],
  entryComponents:
      [
        ExportColorCanvasComponent,
        AddConceptBoardComponent,
        PdfviewerComponent,
        ObjectviewerComponent,
        OpenVideoComponent


      ],
  exports:[
    ImageDimension,
    PublicboardsComponent,
    Timeago,
    PublicboardsComponent,
    EstimateHomeControlComponent,
    ExportColorCanvasComponent,
    ConceptBoardComponent,
    ProjectCommentsComponent,
    OpenVideoComponent,
    PdfviewerComponent,
    ObjectviewerComponent,
    ExternallinkComponent,
    SpacifiivideoComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA]
})
export class SharedModuleModule { }
