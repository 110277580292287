import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EstimateService {

  constructor(private httpClient: HttpClient) { }



  getAllPropertyTypeConfigurationMaster(): any {
		let url = `${environment.leadManagement}/api/propertyTypeConfigurationMaster/getAllPropertyTypeConfigurationMaster`;
		return this.httpClient.get(url);
	}
}
