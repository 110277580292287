import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '../../../../node_modules/@angular/common/http';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConceptboardService {


	currentImageId:any;
	conceptBoardId:any;

	constructor(private httpClient: HttpClient){}

	getCurrentImageId(){
		return this.currentImageId;
	}

	getConceptBoardId(){
		return this.conceptBoardId;
	}

	setCurrentImageId(_param:any){
		this.currentImageId = _param;
	}

	setConceptBoardId(_param:any){
		this.conceptBoardId = _param;
	}
	

    // Concept Board Visibility Options
	getConceptBoardVisibilityOption(){
		return ["PUBLIC","PRIVATE"];
	}

	// Add New Concept Board
	addNewConceptBoard(_reqData):any{
		let url = `${environment.exploreApi}/api/conceptBoard/addConceptBoard`;
		return this.httpClient.post(url, _reqData);
	}	

	// Get Concept Board Id
	getConceptBoardById(conceptBoardId: any):any{		

		let url = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/getConceptBoardEnvelopedById`;
		return this.httpClient.get(url);
	}

	//SetName and Description of Image in Conceptboard

	setImageNameofConceptBoard(_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMedia/updateConceptBoardMedia`;
		return this.httpClient.post(myUrl,_reqData);
	}

	//RemoveKeywordFromMedia
	removeKeywordFromMedia(mediaId: string,_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMedia/${mediaId}/removeKeywordsToConceptBoardMedia`;
		return this.httpClient.post(myUrl,_reqData);
	}

	//AddKeywordFromMedia
	addKeywordToMedia(mediaId: string,_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMedia/${mediaId}/addKeywordsToConceptBoardMedia`;
		return this.httpClient.post(myUrl,_reqData);
	}

	// Add Images To Concept Board
	addMediaToProject(_reqData, conceptBoardId,type): any {
		var myUrl = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/uploadMediasToConceptBoard`;
		if(type === "360")
		{
			myUrl = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/uploadMedias360ToConceptBoard`;
		}
		const uploadReq = new HttpRequest('POST', myUrl, _reqData, {
			reportProgress: true
		});
		return this.httpClient.request(uploadReq);
	}


	// Set Featured Image of Concept Board
	conceptBoardFeaturedImage(_reqData): any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/updateConceptBoardFeaturedImage`;
		return this.httpClient.post(myUrl,_reqData);
	}	

	// Update Concept Board Details
	updateConceptBoardDetails(_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/updateConceptBoard`;
		return this.httpClient.post(myUrl,_reqData);
	}

	// Delete Featured Media
	delteMediaFromConceptBoard(_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMedia/updateConceptBoardMedia`;
		return this.httpClient.post(myUrl,_reqData);
	}

	// Display Featured Image on Dashboard Concept Board
	getConceptBoardViaSearch(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/paginateConceptBoardSearch`;
		return this.httpClient.post(myUrl,_reqData);
	}

	paginatePublicConceptBoardSearch(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/paginatePublicConceptBoardSearch`;
		return this.httpClient.post(myUrl,_reqData);
	}


	getConceptBoardMap() :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/getMyConceptBoardMap`;
		return this.httpClient.get(myUrl);
	}
	// Add Concept Board From Explore
	addConceptBoardFromExplore(_reqData: any, conceptBoardId: any):any{		
		var myUrl = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/addExploreMediaToConceptBoard`;
		return this.httpClient.post(myUrl,_reqData);
	}

	//Get Shared Concept Boards - With Me
	getSharedConceptBoardForMe(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/conceptBoardSharedWithMe`;
		return this.httpClient.post(myUrl, _reqData);
	}


	//Get Shared Concept Boards - With Me
	addUpdateEmailsToConceptBoardShare(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardShare/addUpdateEmailsToConceptBoardShare`;
		return this.httpClient.post(myUrl, _reqData);
	}

	//Get Shared Concept Boards - With Me
	getShareListForconceptBoard(_reqData) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardShare/getShareListForconceptBoard`;
		return this.httpClient.post(myUrl, _reqData,options);
	}


	deleteConceptBoard(_reqData) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardShare/deleteConceptBoard`;
		return this.httpClient.post(myUrl, _reqData,options);
	}

	getShareListWithProfileForconceptBoard(_reqData) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardShare/getShareListWithProfileForconceptBoard`;
		return this.httpClient.post(myUrl, _reqData,options);
	}

	saveConceptBoardMediaUserAction(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMediaUserAction/saveConceptBoardMediaUserAction`;
		return this.httpClient.post(myUrl, _reqData);
	}


	getAllCommentsForMedia(mediaId) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMediaUserAction/${mediaId}/getAllConceptBoardMediaComments`;
		return this.httpClient.get(myUrl);
	}


	///SHORTLISTED SECTION

	//THIS API IS USED TO GET ALL THE IMAGES THAT ARE SHORTLISTED
	getMyShortListedImagesForConceptBoard(_reqData) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardShortList/getMyShortListedImagesForConceptBoard`;
		return this.httpClient.post(myUrl, _reqData,options);
	}

	shortListConceptBoardMedia(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardShortList/shortListConceptBoardMedia`;
		return this.httpClient.post(myUrl, _reqData);
	}


	unShortListConceptBoardMedia(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardShortList/unShortListConceptBoardMedia`;
		return this.httpClient.post(myUrl, _reqData);
	}


	getMediaShortListMappingForConceptBoardByEmailId(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardShortList/getMediaShortListMappingForConceptBoardByEmailId`;
		return this.httpClient.post(myUrl, _reqData);
	}


	getShortListedImageForConceptBoardByEmailId(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardShortList/getShortListedImageForConceptBoardByEmailId`;
		return this.httpClient.post(myUrl, _reqData);
	}

	addEmailsIdForSharingConceptBoard(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardShare/addEmailsIdForSharingConceptBoard`;
		return this.httpClient.post(myUrl, _reqData);
	}

	removeEmailsIdForSharingConceptBoard(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardShare/removeEmailsIdForSharingConceptBoard`;
		return this.httpClient.post(myUrl, _reqData);
	}

	//CONCEPT BOARD ACTIONS

	isConceptBoardLikedByMe(conceptBoardId) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/${conceptBoardId}/isConceptBoardLikedByMe`;
		return this.httpClient.get(myUrl);
	}


	getAllConceptBoardReviews(conceptBoardId) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/${conceptBoardId}/getAllConceptBoardReviews`;
		return this.httpClient.get(myUrl);
	}


	getAllConceptBoardQuestions(conceptBoardId) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/${conceptBoardId}/getAllConceptBoardQuestions`;
		return this.httpClient.get(myUrl);
	}


	getAllConceptBoardComments(conceptBoardId) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/${conceptBoardId}/getAllConceptBoardComments`;
		return this.httpClient.get(myUrl);
	}

	saveConceptBoardUserAction(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/saveConceptBoardUserAction`;
		return this.httpClient.post(myUrl, _reqData);
	}

	updateConceptBoardUserAction(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/updateConceptBoardUserAction`;
		return this.httpClient.post(myUrl, _reqData);
	}

	updateConceptBoardMediaUserAction(_reqData) :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMediaUserAction/updateConceptBoardMediaUserAction`;
		return this.httpClient.post(myUrl, _reqData);
	}

	answerConceptBoardQuestion(questionId: any, _reqData: any) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/question/${questionId}/answerConceptBoardQuestion`;
		return this.httpClient.post(myUrl,_reqData,options);
	}

	answerConceptBoardMediaQuestion(questionId: any, _reqData: any) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardMediaUserAction/question/${questionId}/answerConceptBoardMediaQuestion`;
		return this.httpClient.post(myUrl,_reqData,options);
	}

	deleteConceptBoardUserAction(_reqData: any) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardUserAction/deleteConceptBoardUserAction`;
		return this.httpClient.post(myUrl,_reqData,options);
	}

	deleteConceptBoardMediaUserAction(_reqData: any) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardMediaUserAction/deleteConceptBoardMediaUserAction`;
		return this.httpClient.post(myUrl,_reqData,options);
	}

	downloadFile(url){
		return this.httpClient.post(url,{
			responseType : 'blob',
			headers : new HttpHeaders().append('content-type','application/json')
		});
	}

	// UPLOAD VIDEO IN CONCEPT BOARD
	addExternalMediaToConceptBoard(conceptBoardId: any, _reqData: any) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoard/conceptBoard/${conceptBoardId}/addExternalMediaToConceptBoard`;
		return this.httpClient.post(myUrl,_reqData,options);
	}


	//REMOVE INFOSPOT FROM IMAGE AND CONCEPTBORD
	removeInfoSpotsFromConceptBoardMedia(conceptBoardId: any,mediaId:any, _reqData: any) :any{
		var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
		var myUrl = `${environment.exploreApi}/api/conceptBoardMedia/conceptBoard/${conceptBoardId}/media/${mediaId}/removeInfoSpotsFromConceptBoardMedia`;
		return this.httpClient.post(myUrl,_reqData,options);
	}


	// Concept Board Visibility Options
	/*getConceptBoardVisibilityOption(){
		return ["PUBLIC","PRIVATE"];
	}*/

	/*// Add New Concept Board
	addNewConceptBoard(_reqData):any{
		let url = `${environment.exploreApi}/api/conceptBoard/addConceptBoard`;
		return this.httpClient.post(url, _reqData);
	}

	// Get Concept Board Id
	getConceptBoardById(conceptBoardId: any):any{
		let url = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/getConceptBoardEnvelopedById`;
		return this.httpClient.get(url);
	}*/

	/*// Add Images To Concept Board
	addMediaToProject(_reqData, conceptBoardId): any {
		var myUrl = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/uploadMediasToConceptBoard`;
		const uploadReq = new HttpRequest('POST', myUrl, _reqData, {
			reportProgress: true
		});
		return this.httpClient.request(uploadReq);
	}

	// Set Featured Image of Concept Board
	conceptBoardFeaturedImage(_reqData): any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/updateConceptBoardFeaturedImage`;
		return this.httpClient.post(myUrl,_reqData);
	}

	// Update Concept Board Details
	updateConceptBoardDetails(_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/updateConceptBoard`;
		return this.httpClient.post(myUrl,_reqData);
	}

	// Delete Featured Media
	delteMediaFromConceptBoard(_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMedia/updateConceptBoardMedia`;
		return this.httpClient.post(myUrl,_reqData);
	}

	getConceptBoardMap() :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/getMyConceptBoardMap`;
		return this.httpClient.get(myUrl);
	}*/

	//To BE REMOVED
	//NEW SERVICES

	/*// Concept Board Visibility Options
	getConceptBoardVisibilityOption(){
		return ["PUBLIC","PRIVATE"];
	}*/

	/*// Add New Concept Board
	addNewConceptBoard(_reqData):any{
		let url = `${environment.exploreApi}/api/conceptBoard/addConceptBoard`;
		return this.httpClient.post(url, _reqData);
	}

	// Get Concept Board Id
	getConceptBoardById(conceptBoardId: any):any{
		let url = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/getConceptBoardEnvelopedById`;
		return this.httpClient.get(url);
	}

	// Add Images To Concept Board
	addMediaToProject(_reqData, conceptBoardId): any {
		var myUrl = `${environment.exploreApi}/api/conceptBoard/${conceptBoardId}/uploadMediasToConceptBoard`;
		const uploadReq = new HttpRequest('POST', myUrl, _reqData, {
			reportProgress: true
		});
		return this.httpClient.request(uploadReq);
	}

	// Set Featured Image of Concept Board
	conceptBoardFeaturedImage(_reqData): any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/updateConceptBoardFeaturedImage`;
		return this.httpClient.post(myUrl,_reqData);
	}

	// Update Concept Board Details
	updateConceptBoardDetails(_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/updateConceptBoard`;
		return this.httpClient.post(myUrl,_reqData);
	}

	// Delete Featured Media
	delteMediaFromConceptBoard(_reqData):any{
		var myUrl = `${environment.exploreApi}/api/conceptBoardMedia/updateConceptBoardMedia`;
		return this.httpClient.post(myUrl,_reqData);
	}

	getConceptBoardMap() :any{
		var myUrl = `${environment.exploreApi}/api/conceptBoard/getMyConceptBoardMap`;
		return this.httpClient.get(myUrl);
	}
*/

	
}
