import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {UserProfileService} from '../../../../services/user-profile.service';

@Component({
	selector: 'app-profile-picture',
	templateUrl: './profile-picture.component.html',
	styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

	imageChangedEvent: any = '';
	croppedImage: any = '';
	selectedFile: File;
	fileName: string;
	finalImage: Blob;
	uploaded:boolean =false;


	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<ProfilePictureComponent>,
		public userProfileService: UserProfileService
	) { }

	ngOnInit() {
	}

	fileChangeEvent(event: any): void {
		this.selectedFile = event.target.files[0];
		this.imageChangedEvent = event;
		this.fileName = this.selectedFile.name;
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
		var block = this.croppedImage.split(";");
		var realData = block[1].split(",")[1];
		// Get the content type of the image
		var contentType = block[0].split(":")[1];// In this case "image/gif"
		this.finalImage = this.b64toBlob(realData,contentType);

	}

	onUpload() {
		
		let input = new FormData();

		input.append("uploadingFiles", this.finalImage, this.fileName);
		this.userProfileService.addMediaToProfile(input, "USERPROFILE").subscribe(res => {
				this.dialogRef.close(res.payload[0].url);

			}
		)
	}

	b64toBlob(b64Data, contentType) {
		contentType = contentType || '';

		var sliceSize =  512;

		var byteCharacters = window.atob(b64Data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays, {type: contentType});
		return blob;
	}

	imageLoaded(){}
	loadImageFailed(){}



}
