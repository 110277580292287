import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inhouse-headers',
  templateUrl: './inhouse-headers.component.html',
  styleUrls: ['./inhouse-headers.component.scss']
})
export class InhouseHeadersComponent implements OnInit {

  blogVideos = [
		{
			url: "https://d5xltud2zi4gz.cloudfront.net/frontend/customer/blog/7.jpg"
		}
	];
  @Input() selected: string;
  constructor() { }

  ngOnInit() {
  }


}
