import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from '../../../services/registration.service';
import { MasterService } from '../../../services/master.service';
import { ApiResponse } from '../../../services/api-response';
import {ToastrService} from 'ngx-toastr';
import { LocalStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-partner-registration',
  templateUrl: './partner-registration.component.html',
  styleUrls: ['./partner-registration.component.scss']
})
export class PartnerRegistrationComponent implements OnInit {

  companyDetails: FormGroup;
  otpFormGroup: FormGroup;
  isOptional = false;
  selectedStep = 0;
  passwordFormGroup: FormGroup;
  contactDetaild: FormGroup;
  otpReference: any;
  id: any;
  ownerShipTypes: any;
  userTypes: any;
  countries: any;
  countriesMap: any;
  states: any[] =[];
  statesMap: any;
  citites: any[] = [];
  cititesMap: any;
  companyId: any;
  payload: any;
  resendcount:any=0;
  designer:any;
  
  constructor(
    private _formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private masterService: MasterService,
    private toastr: ToastrService,
    private localstorageService:LocalStorageService
  ) { }

  ngOnInit() {
  this.designer = this.localstorageService.get('INTERIOR_DESIGNER');
  this.localstorageService.remove('INTERIOR_DESIGNER');
    this.masterService.getOwnerShipType().subscribe(res => {
      this.ownerShipTypes = res.payload;
      var apiResponse = <ApiResponse>res;
      var data = apiResponse.payload;
      this.ownerShipTypes = Object.entries(data).map(([key, value]) => ({ key, value }));
    })

    this.masterService.getUserType().subscribe(res => {
      var apiResponse = <ApiResponse>res;
      var data = apiResponse.payload;
      if(this.designer === null){
        this.userTypes = Object.entries(data).map(([key, value]) => ({ key, value }));
      }
      else{
        var data1 = {INTERIOR_DESIGNER: "INTERIOR_DESIGNER"};
        this.userTypes =  Object.entries(data1).map(([key, value]) => ({ key, value }));
      }
    })

    this.masterService.getCountryStateCityMaster().subscribe(res => {
      // this.userType = res.payload;
      var apiResponse = <ApiResponse>res;
      var countriesMap = apiResponse.payload;
      this.countriesMap = countriesMap;
      this.countries = Object.entries(countriesMap).map(([key, value]) => ({ key, value }));

      this.onCountryChange('INDIA');
      this.onStateChange('RAJASTHAN');
    })


    //------------- Company Details ----------//
    this.contactDetaild = this._formBuilder.group({
      userType: ['',Validators.required],
      name: ['', Validators.required],
      companyName: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[6-9]\\d{9}')]],
      email: ['',[Validators.required, Validators.email]]
    });

    this.otpFormGroup = this._formBuilder.group({
      otpCode: ['', Validators.required]
    });

    this.passwordFormGroup = this._formBuilder.group({
      profileId: ['', Validators.required],
      password:['',[Validators.required, Validators.minLength(6), Validators.pattern("((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{6,20})")]],
      password1:['',[Validators.required, Validators.minLength(6), Validators.pattern("((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{6,20})")]]
    })

    this.companyDetails = this._formBuilder.group({
      ownerShipType: [''],
      address1: ['', Validators.required],
      address2: [''],
      state: [''],
      city: [''],
      country: [''],
      pinCode: ['', Validators.required],
      companyWebsite: ['']
    });



  }


  saveContactDetaild() {

    var contactDetaildObj = this.contactDetaild.value;
    this.registrationService.saveContactDetails(contactDetaildObj).subscribe(res => {
    //  console.log(res);
      this.otpReference = res.payload.otpReference;
      this.selectedStep = this.selectedStep + 1;
    })
  }

  generateOTP(){
    if(this.resendcount < 3) {
      var contactDetaildObj = this.contactDetaild.value;
      this.registrationService.saveContactDetails(contactDetaildObj).subscribe(res => {
        //console.log(res);
        this.otpReference = res.payload.otpReference;
        this.toastr.success("Your OTP has been sent to the new Mobile Number!","");
        this.resendcount = this.resendcount+1;
      })
    }
    else{
      this.toastr.error("You have exceeded the Number of OTP Generation Attempts. PLease try again later","");
    }
  }

  validateOtp() {

    var otpObj: any = {};
    otpObj.mobile = this.contactDetaild.value.mobile;
    otpObj.otpReference = this.otpReference;
    otpObj.otp = this.otpFormGroup.value.otpCode;
    this.registrationService.validateOtp(otpObj).subscribe(res => {
      //console.log(res);
      this.id = res.payload.userProfile.id
      this.companyId = res.payload.companyProfile.companyId;
      this.selectedStep = this.selectedStep + 1;
    })
  }
  savePassword() {

    if(this.passwordFormGroup.invalid){
      return;
    }
    if(this.checkPasswords()){
      this.toastr.error("The Passwords you have entered do not match. Please retry.","");
      return;
    }

    var passwordObj = this.passwordFormGroup.value;
    passwordObj.id = this.id;
    this.registrationService.setPassword(passwordObj).subscribe(res => {
    //  console.log(res);
      this.selectedStep = this.selectedStep + 1;
        },
        (error) => {
          this.toastr.error(error,"");
        });
  }

  checkPasswords() { // here we have the 'passwords' group
    let pass = this.passwordFormGroup.controls.password.value;
    let confirmPass = this.passwordFormGroup.controls.password1.value;
    return pass === confirmPass ? null : { notSame: true }
  }

  saveCompanyDetails() {

    var companyObj = this.companyDetails.value;
    companyObj.companyId = this.companyId;
    companyObj.userId = this.id;
    this.registrationService.saveCompany(companyObj).subscribe(res => {
      //console.log(res);
      this.selectedStep = this.selectedStep + 1;
    })
  }


  onCountryChange(dcountry: any) {

    let something = Object.keys(this.countriesMap);
    let other = Object.values(this.countriesMap);
    for (var i = 0; i < something.length; i++) {
      if (something[i] == dcountry) {
        let arr = [];
        this.statesMap = other[i];

      }
    }
    this.states = Object.entries(this.statesMap).map(([key, value]) => ({ key, value }));

  }
  onStateChange(dstate: any) {


    let something = Object.keys(this.statesMap);


    let other = Object.values(this.statesMap);

    for (var i = 0; i < something.length; i++) {
      if (something[i] == dstate) {
        this.cititesMap = other[i];

      }
    }
    this.citites = Object.entries(this.cititesMap).map(([key, value]) => ({ key, value }));
  }


  public hasError = (controlName: string, errorName: string, type:string) =>{
    if(type=="CD"){
      return this.contactDetaild.controls[controlName].hasError(errorName);
    }
    if(type=="OTP"){
      return this.otpFormGroup.controls[controlName].hasError(errorName);
    }

    if(type=="PWD"){
      return this.passwordFormGroup.controls[controlName].hasError(errorName);
    }
  }
}
