import { Pipe, PipeTransform } from '@angular/core';
import {CommonModule} from "@angular/common";
@Pipe({
  name: 'contentDimension'
})
export class ContentDimensionPipe implements PipeTransform {

  transform(value: string, dimension:number): string {
    return value.substring(0,dimension);
  }

}
