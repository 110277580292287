import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { AuthserviceService } from './authservice.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LoginService } from './login.service';
import { MatDialog } from '@angular/material';

@Injectable()
export class InterceptorService implements HttpInterceptor {

	myProfile: any;
	flagOpenToIn: boolean = true;

	constructor(private authserviceService: AuthserviceService,
		private toastr: ToastrService,
		private loginService: LoginService,
		private router: Router,
		public dialog: MatDialog) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		var someUrl: string = request.url;
		if (someUrl.includes("generateToken")) {
			return next.handle(request)
				.pipe(
					tap(event => {
						if (event instanceof HttpResponse) {
						}
					}, error => {

					})
				)
		}

		var mytoken = this.authserviceService.getToken();
		request = request.clone({
			setHeaders: {
				Authorization: `Bearer ${mytoken}`
			}
		});
		return next.handle(request)
			.pipe(
				tap(event => {
					if (event instanceof HttpResponse) {
					}
				}, error => {
					if (error.status === 401) {
						if (this.flagOpenToIn) {
							this.flagOpenToIn = false;
							this.toastr.error(`${environment.errorMessageOnLogout}`, "");
							setTimeout(() => {
								this.flagOpenToIn = true;
							}, 2000);
							this.loginService.setMyProfile(null);
							this.loginService.setLogin(false);
						}
						this.dialog.closeAll();
						this.router.navigate(['/login/customer'],{queryParams:{redirectUrl:document.location.href}});
					}
					else {
						// TODO: response for foll is erorring out with 500
						// https://betaapi.spacifii.com:8443/konstruct-content/api/post/getFeaturedHomePost"
						if(error.error.responseMessage){
							this.toastr.error(error.error.responseMessage);
						}
						if (error.responseMessage) {
							this.toastr.error(error.responseMessage);
						}
					}
				})
			)

	};
}
