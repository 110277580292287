import { Component, OnInit, Inject } from '@angular/core';
import { TwoDShowcaseComponent } from '../two-d-showcase/two-d-showcase.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogDataMedia } from '../../filters/filters.component';
import { MasterService } from '../../../../app/services/master.service';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit {
  mainColors: any;
  selectedColor:any;
  constructor(
    public dialog: MatDialog,
    private masterService: MasterService,
		public dialogRef: MatDialogRef<TwoDShowcaseComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if(this.data.colors) {
      this.mainColors = this.data.colors;
    }else{
      this.getSetColors();
    }
  }

  closeModal(){
    this.dialogRef.close();
  }

  getSetColors() {
    this.masterService.getExploreColors().subscribe((res: any) => {
      this.mainColors = res.payload;
    },
      (error: any) => {
        console.log("Error", error);
      })
  }

  slecteColor(_color){
    this.selectedColor = _color;
  }

  slecteFinalColor(_color){
    this.dialogRef.close(_color);
  }

}
