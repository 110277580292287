import { Component, OnInit, Inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, RippleGlobalOptions } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../src/app/services/master.service';
import { LoginService } from '../../../../src/app/services/login.service';
import { LocalStorageService } from 'angular-web-storage';
import { Route, Router } from '@angular/router';
import { AuthserviceService } from '../../../../src/app/services/authservice.service';
import { ConceptboardService } from '../conceptBoard/conceptboard.service';
import { AddConceptBoardComponent } from '../conceptBoard/components/add-concept-board/add-concept-board.component';
import {environment} from '../../../environments/environment';
import {DesignConsultComponent} from '../design-consult/design-consult.component';
import { AddtoCartService } from '../product/addToCart.service';
 
export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: 'app-main-nav',
	templateUrl: './main-nav.component.html',
	styleUrls: ['./main-nav.component.scss'],
})

export class MainNavComponent implements OnInit {
	nothingInAll;
	animal: string;
	cartProductCount: number = 0;
	name: string;
	cartItems;
	totalAmmount;
	exploreMenuObject: any;
	conceptBoards: any;
	changeText: boolean;
	spaceObject: [] = [];
	styleObject: [] = [];
	prodObject: [] = [];
	selectedExploreMenu: any;
	selectedEstimateMenu: any;
	selectedProductMenu: any;
	propertyTypeConfigurationMasters: any;
	propertyTypeSelected: any;
	propertySubTypeSelected: any;
	selectedProperty = true;
	menuItems = [];
	menuItemsDesigner = [];
	menuItemsCustomer = [];
	isLoggedIn: boolean = false;
	isDesignerLoggedIn: boolean;
	isCustomerLoggedIn: boolean;
	isNotDesignerNotCustomerLoggedIn: boolean;
	myProfile: any;
	selectedIndex = 1;
	ProductMenuTrigger;
	public defaultImage = 'assets/images/default_project.png';
	userType:any;
	companyProfileBoolean:boolean = false;
	showroom = environment.showroom;
	conceptboard = environment.conceptboard;
	constructor(
		private breakpointObserver: BreakpointObserver,
		private localStorageService: LocalStorageService,
		private router: Router,
		private addToCartService: AddtoCartService,
		public dialog: MatDialog,
		private conceptBoardService: ConceptboardService,
		private authserviceService: AuthserviceService,
		private masterService: MasterService,
		
		private loginService: LoginService) {	
		this.isLoggedIn = this.loginService.getLogin();
		this.myProfile = this.loginService.getMyProfile();
		
		this.menuItems = [
			{
				routerLinkName: "/home",
				name: "home",
				icon: "home"
			},
			{
				routerLinkName: "/notification",
				name: "Notification",
				icon: "notifications_none"
			},
			// {
			// 	routerLinkName: "/product",
			// 	name: "product-and-service",
			// 	icon: "shopping_cart"
			// },
			// {
			// 	routerLinkName: "/about-us",
			// 	name: "About Us",
			// 	icon: "help_outline"
			// },
			{
				routerLinkName: "/recently",
				name: "Recently Spacifii",
				icon: "chrome_reader_mode"
			},
			{
				routerLinkName: "/faq",
				name: "FAQ's",
				icon: "forum"
			},
			{
				routerLinkName: "/contact-us",
				name: "Contact Us",
				icon: "call"
			},
			{
				routerLinkName: "/blog",
				name: "Blogs",
				icon: "library_books"
			},
			//  {
			// 	routerLinkName: "/concept-board/all",
			// 	name: "Concept Board",
			// 	icon: "filter_none"
			// }
		];
		this.menuItemsDesigner = [
			{
				routerLinkName: "/dashboard",
				name: "dashboard",
				icon: "home"
			},
			{
				routerLinkName: "/designer/myappoinments",
				name: "appointments",
				icon: "home"
			},
			{
				routerLinkName: "/designer/designer-my-board",
				name: "My Boards",
				icon: "home"
			},
			{
				routerLinkName: "/designer/myprojects",
				name: "My Projects",
				icon: "home"
			},
			{
				routerLinkName: "/designer/review",
				name: "Review",
				icon: "home"
			},
		];
		this.menuItemsCustomer = [
			{
				routerLinkName: "/dashboard",
				name: "dashboard",
				icon: "home"
			},
			{
				routerLinkName: "/product",
				name: "product-and-service",
				icon: "shopping_cart"
			},
			{
				routerLinkName: "/customer/customer-my-board",
				name: "My Boards",
				icon: "home"
			},
			{
				routerLinkName: "/customer/customer-my-designers",
				name: "Designers",
				icon: "home"
			},
			// {
			// 	routerLinkName: "/customer/customer-quiz",
			// 	name: "Quiz",
			// 	icon: "home"
			// },
			{
				routerLinkName: "/customer/customer-my-documents",
				name: "My Documents",
				icon: "home"
			},
			{
				routerLinkName: "/customer/customer-payment-detail",
				name: "Payment Detail",
				icon: "home"
			},
		];
		
	}

	ProductElement = [
		{	"cssID" : 'TOP',
			"categoreyName": "Furniture",
			"categoreyDisplay": "Sofas",
			"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
			"childCategories": [
				{
					"cssID" : 'CHILD_1',
					"categoreyName": "Sofas & Loungers",
					"categoreyDisplay": "Sofas",
					"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
					"childCategories": [
						{
							"cssID" : 'CHILD_2',
							"categoreyName": "Three Seater Sofas",
							"categoreyDisplay": "Sofas",
	
						},
						{
							"categoreyName": "Tow Seater Sofas",
							"categoreyDisplay": "Sofas",
	
						},
						{
							"categoreyName": "One Seater Sofas",
							"categoreyDisplay": "Sofas",
	
						},
						{
							"categoreyName": "Sofas Seater",
							"categoreyDisplay": "Sofas",
	
						},
						{
							"categoreyName": "L Shaped",
							"categoreyDisplay": "Sofas",
	
						},
						{
							"categoreyName": "Sofa cum Beds",
							"categoreyDisplay": "Beds",
	
						},
						{
							"categoreyName": "Recliners",
							"categoreyDisplay": "Beds",
	
						}
					]
				},
				{
					"categoreyName": "Accent Chairs",
					"categoreyDisplay": "Chairs",
					"childCategories": [
						{
							"categoreyName": "Wing Chairs",
							"categoreyDisplay": "Chairs",
	
						},
						{
							"categoreyName": "Arm Chairs",
							"categoreyDisplay": "Chairs",
	
						},
						{
							"categoreyName": "Rocking Chairs",
							"categoreyDisplay": "Chairs",
	
						},
						{
							"categoreyName": "Cafe Chairs",
							"categoreyDisplay": "Chairs",
	
						},
						{
							"categoreyName": "Iconic Chairs",
							"categoreyDisplay": "Chairs",
	
						}
					]
				},
				{
					"categoreyName": "Bar Furniture",
					"categoreyDisplay": "Furniture",
					"childCategories": [
						{
							"categoreyName": "Bar Cabintes",
							"categoreyDisplay": "Chairs",
	
						},
						{
							"categoreyName": "Win Racks",
							"categoreyDisplay": "Chairs",
	
						},
						{
							"categoreyName": "Bar Chairs and Tools",
							"categoreyDisplay": "Chairs",
	
						},
						{
							"categoreyName": "Bar Chairs and Table Sets",
							"categoreyDisplay": "Chairs",
	
						},
					]
				},
				{
					"categoreyName": "Wardrobes",
					"categoreyDisplay": "Furniture",
					"childCategories": [
						{
							"categoreyName": "2 Door Wardrobes ",
							"categoreyDisplay": "Furniture",
	
						},
						{
							"categoreyName": "3 Door Wardrobes",
							"categoreyDisplay": "Furniture",
	
						},
						{
							"categoreyName": "4 Door Wardrobes",
							"categoreyDisplay": "Furniture",
	
						},
						{
							"categoreyName": "1 Door Wardrobes",
							"categoreyDisplay": "Furniture",
	
						},
					]
				},
				{
					"categoreyName": "Beds",
					"categoreyDisplay": "Furniture",
					"childCategories": [
						{
							"categoreyName": "Queen Sized Beds ",
							"categoreyDisplay": "Furniture",
	
						},
						{
							"categoreyName": "King Sized Beds",
							"categoreyDisplay": "Furniture",
	
						},
						{
							"categoreyName": "Single Beds Beds",
							"categoreyDisplay": "Furniture",
	
						},
						{
							"categoreyName": "Poster Beds",
							"categoreyDisplay": "Furniture",
	
						},
					]
				},
			]
		},
	
		{
			"cssID" : 'TOP',
			"categoreyName": "Modular Kitchen",
			"categoreyDisplay": "Modular Kitchen",
			"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
			"childCategories": [
				{
					"cssID" : 'CHILD_1',
					"categoreyName": "Modular Kitchens",
					"categoreyDisplay": "Modular Kitchens",
					"childCategories": [
						{
							"cssID" : 'CHILD_2',
							"categoreyName": "Straight Lines",
							"categoreyDisplay": "Straight Lines",
	
						},
						{
							"categoreyName": "Parallels",
							"categoreyDisplay": "Parallels",
	
						},
						{
							"categoreyName": "L Shaped",
							"categoreyDisplay": "L Shaped",
	
						},
						{
							"categoreyName": "U Shaped",
							"categoreyDisplay": "U Shaped",
	
						},
						{
							"categoreyName": "Island",
							"categoreyDisplay": "Island",
	
						},
					]
				},
				{
					"categoreyName": "CookTops",
					"categoreyDisplay": "CookTops",
					"childCategories": [
						{
							"categoreyName": "Gas Stoves",
							"categoreyDisplay": "Gas Stoves",
	
						},
						{
							"categoreyName": "Hobs",
							"categoreyDisplay": "Hobs",
	
						},
						{
							"categoreyName": "Induction Cooktops",
							"categoreyDisplay": "Induction Cooktops",
	
						},
					]
				},
				{
					"categoreyName": "Chimneys",
					"categoreyDisplay": "Chimneys",
					"childCategories": [
						{
							"categoreyName": "Hood Chimneys",
							"categoreyDisplay": "Hood Chimneys",
	
						},
						{
							"categoreyName": "Straight Line Chimneys",
							"categoreyDisplay": "Straight Line Chimneys",
	
						},
					]
				},
				{
					"categoreyName": "Ovens",
					"categoreyDisplay": "Ovens",
					"childCategories": [
						{
							"categoreyName": "Microwave Ovens ",
							"categoreyDisplay": "Microwave Ovens",
	
						},
						{
							"categoreyName": "OTG",
							"categoreyDisplay": "OTG",
	
						},
						{
							"categoreyName": "Built-in Ovens",
							"categoreyDisplay": "Built-in Ovens",
	
						},
					]
				},
				{
					"categoreyName": "Modular Wardrobes",
					"categoreyDisplay": "Modular Wardrobes",
					"childCategories": [
						{
							"categoreyName": "Cabinet",
							"categoreyDisplay": "Hinged",
	
						},
						{
							"categoreyName": "Sliding",
							"categoreyDisplay": "Sliding",
	
						},
						{
							"categoreyName": "Single Beds Beds",
							"categoreyDisplay": "Furniture",
	
						},
						{
							"categoreyName": "Poster Beds",
							"categoreyDisplay": "Furniture",
	
						},
					]
				},
			]
		},
	
		{
			"cssID" : 'TOP',
			"categoreyName": "Kid's Furniture",
			"categoreyDisplay": "Kid's Furniture",
			"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
			"childCategories": [
				{
					"cssID" : 'CHILD_1',
					"categoreyName": "Beds",
					"categoreyDisplay": "Beds",
					"childCategories": [
						{
							"cssID" : 'CHILD_2',
							"categoreyName": "Single Beds",
							"categoreyDisplay": "Single Beds",
	
						},
						{
							"categoreyName": "Double Beds",
							"categoreyDisplay": "Double Beds",
	
						},
						{
							"categoreyName": "Bunk Beds",
							"categoreyDisplay": "Bunk Beds",
	
						},
					]
				},
				{
					"categoreyName": "Mattresses",
					"categoreyDisplay": "Mattresses",
					"childCategories": [
						{
							"categoreyName": "Single Bed Mattresses",
							"categoreyDisplay": "Single Bed Mattresses",
	
						},
						{
							"categoreyName": "Double Bed Mattresses",
							"categoreyDisplay": "Double Bed Mattresses",
	
						},
					]
				},
				{
					"categoreyName": "Seating",
					"categoreyDisplay": "Seating",
					"childCategories": [
						{
							"categoreyName": "Bean Bags",
							"categoreyDisplay": "Bean Bags",
	
						},
						{
							"categoreyName": "Stools",
							"categoreyDisplay": "Stools",
	
						},
					]
				},
				{
					"categoreyName": "Study",
					"categoreyDisplay": "Study",
					"childCategories": [
						{
							"categoreyName": "Book Shelves",
							"categoreyDisplay": "Book Shelves",
	
						},
						{
							"categoreyName": "Book Cases",
							"categoreyDisplay": "Book Cases",
	
						},
						{
							"categoreyName": "Tables & Chair Sets",
							"categoreyDisplay": "Tables & Chair Sets",
	
						},
					]
				},
				{
					"categoreyName": "Storage Unit",
					"categoreyDisplay": "Storage Unit",
					"childCategories": [
						{
							"categoreyName": "Cabinet",
							"categoreyDisplay": "Cabinet",
	
						},
						{
							"categoreyName": "Tunks & Boxes",
							"categoreyDisplay": "Tunks & Boxes",
	
						}
					]
				},
			]
		},
	
		{
			"cssID" : 'TOP',
			"categoreyName": "Carpets & Furnishing",
			"categoreyDisplay": "Carpets & Furnishing",
			"categoreyImage": "https://d5xltud2zi4gz.cloudfront.net/400x250/conceptBoard/40833addee3ec49e645d2f8106be64c21553962584934.JPG",
			"childCategories": [
				{
					"cssID" : 'CHILD_1',
					"categoreyName": "Carpets",
					"categoreyDisplay": "Carpets",
					"childCategories": [
						{
							"cssID" : 'CHILD_2',
							"categoreyName": "Machine Made",
							"categoreyDisplay": "Machine Made",
	
						},
						{
							"categoreyName": "Hand Made",
							"categoreyDisplay": "Hand Made",
	
						},
					]
				},
				{
					"categoreyName": "Dhurries",
					"categoreyDisplay": "Dhurries",
					"childCategories": [
						{
							"categoreyName": "Cotton Dhurries",
							"categoreyDisplay": "Cotton Dhurries",
	
						},
						{
							"categoreyName": "Wool Dhurries",
							"categoreyDisplay": "Wool Dhurries",
	
						},
						{
							"categoreyName": "Jute & Hemp Dhurries",
							"categoreyDisplay": "Jute & Hemp Dhurries",
						}
					]
				},
				{
					"categoreyName": "Runners",
					"categoreyDisplay": "Runners",
					"childCategories": [
						{
							"categoreyName": "Synthetic Runners",
							"categoreyDisplay": "Synthetic Runners",
	
						},
						{
							"categoreyName": "Cotton Runners",
							"categoreyDisplay": "Cotton Runners",
	
						},
						{
							"categoreyName": "Jute Runners",
							"categoreyDisplay": "Jute Runners",
						},
						{
							"categoreyName": "Wool Runners",
							"categoreyDisplay": "Wool Runners",
						}
					]
				},
				{
					"categoreyName": "Cushions",
					"categoreyDisplay": "Cushions",
					"childCategories": [
						{
							"categoreyName": "Cushion Cover",
							"categoreyDisplay": "Cushion Cover",
	
						},
						{
							"categoreyName": "Cuddle Cushion",
							"categoreyDisplay": "Cuddle Cushion",
	
						},
						{
							"categoreyName": "Floor Cushion",
							"categoreyDisplay": "Floor Cushion",
	
						},
					]
				},
				{
					"categoreyName": "Bolsters",
					"categoreyDisplay": "Bolsters",
					"childCategories": [
						{
							"categoreyName": "Bolsters",
							"categoreyDisplay": "Bolsters",
	
						},
						{
							"categoreyName": "Bolsters Cover",
							"categoreyDisplay": "Bolsters Cover",
	
						}
					]
				},
			]
		},
	]
	ngOnInit() {

		//this.b2bSite = environment.b2bSite+'/dashboard';
		//this.getAllOnwerShipTypes();
		//this.getAllpropertyTypeConfigurationMasters();
		 let profile = this.localStorageService.get("loginProfile");
		 if(profile != null){
			if(profile =="B2C_CUSTOMER") 
			{
				 this.isCustomerLoggedIn = true;
				 this.isDesignerLoggedIn = false;
				 this.isNotDesignerNotCustomerLoggedIn = false;
			}
			else if(profile =="INTERIOR_DESIGNER"){
				this.isDesignerLoggedIn = true;
				this.isCustomerLoggedIn = false;
				this.isNotDesignerNotCustomerLoggedIn = false;
			}
			else{
				this.isDesignerLoggedIn = false;
			 	this.isCustomerLoggedIn = false;
				 this.isNotDesignerNotCustomerLoggedIn = true;
			}
		 }
		 else{
		 	this.isDesignerLoggedIn = false;
			 this.isCustomerLoggedIn = false;
			 this.isNotDesignerNotCustomerLoggedIn = false;
		 }

		this.masterService.getExploreMenu().subscribe((res: { payload: any; }) => {
			this.exploreMenuObject = res.payload;
			this.selectedExploreMenu = this.exploreMenuObject[0];

		});
		let searchObject = {
			"page": 1,
			"size": 20
		};

		if (this.loginService.isLoggedIn) {
			this.conceptBoardService.getConceptBoardViaSearch(searchObject).subscribe((res: { payload: { content: any; }; }) => {
				if (res.payload.content) {
					this.conceptBoards = res.payload.content;
				}
			});
		}
		this.addToCartService.getProducts().subscribe(data => {
			this.cartItems = data;
			this.cartProductCount = data.length;
		//	console.log(this.cartItems);
			this.totalAmmount = this.addToCartService.getTotalPrice();
		//	console.log(this.totalAmmount);
		  })
	}

	removeItemFromCart(productId) {
		/* this.cartItems.map((item, index) => {
		  if (item.id === productId) {
			this.cartItems.splice(index, 1);
		  }
		});
	
		this.mySharedService.setProducts(this.cartItems); */
	
		this.addToCartService.removeProductFromCart(productId);
	
	  }
	
	  emptyCart() {
		this.addToCartService.emptryCart();
	  }
	

	// getAllpropertyTypeConfigurationMasters() {
	// 	this.estimateService.getAllPropertyTypeConfigurationMaster().subscribe(res => {
	// 		this.propertyTypeConfigurationMasters = res.payload;
	// 	});
	// }

	setPropertyType(_input: String, _input2: String,subtype: { active: boolean; }) {
		this.propertySubTypeSelected = _input2;
		this.propertyTypeSelected = _input;
		subtype.active = !subtype.active;  
	}

	selectMenuFromExplore(_menu) {
		this.selectedExploreMenu = _menu;
	}

	selectMenuFromEstimate() {
		this.selectedEstimateMenu;
	}
	selectMenuFromProduct(_menu){
		this.selectedProductMenu = _menu;
	}

	leaveTohideMenu(event, current) {
		if (!(event.relatedTarget && event.relatedTarget.className && event.relatedTarget.className.includes('hover-identity'))) {
			current.closeMenu();
		}
	}
	
	showDiv(index: number) {
		this.selectedIndex = index;
	}

	stopClosing(event) {

		if (event.target.classList.contains('hover-identity') || event.target.classList.contains('hover-identity')) {
			event.stopPropagation();
		}
	}

	

	logOut() {
		this.authserviceService.removeToken();
		this.isLoggedIn = false;
		this.myProfile = null;
		this.authserviceService.setToken('');
		this.localStorageService.set("loginProfile",'');
		this.loginService.setMyProfile('');
		this.loginService.logOut();		
		this.setMyProfile('');
		this.isDesignerLoggedIn = false;
		this.isCustomerLoggedIn = false;
		this.isNotDesignerNotCustomerLoggedIn = false;
		this.router.navigate(['/home']);
		this.companyProfileBoolean = false;
	}

	setMyProfile(_profile: any) {
		this.localStorageService.set("currentProfile", _profile);
		this.myProfile = _profile;
	}


	getMyProfile() {
		this.myProfile = this.localStorageService.get("currentProfile");
		return this.myProfile;
	}

	public setLogin(input: boolean) {
		this.isLoggedIn = input;	
	}

	public getLogin() {
		return this.isLoggedIn;
	}
	

	routeToConceptBoards(){
		this.router.navigateByUrl('concept-board/all');
	}

	// redirectToProfile() {
	// 	this.router.navigate(['/user-profile']);
	// }

	abouts = [
		{
			id: 'Company',
			routerLinkName: '/about-us',
			icon: 'business'
		},
		{
			id: 'How it works',
			routerLinkName: '/how-it-works',
			icon: 'settings'
		},
		{
			id: 'Offerings',
			routerLinkName: '/offerings',
			icon: 'local_offer'
		},
		{
			id: 'Our Team',
			routerLinkName: '/our-team',
			icon: 'supervisor_account'
		},
		{
			id: 'Our Story',
			routerLinkName: 'spacifii-story',
			icon: 'import_contacts'
		}
	]


	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
		.pipe(
			map(result => result.matches)
		);

	openDialog(): void {
		const dialogRef = this.dialog.open(MySearchDialogComponentDialog, {
			width: '250px',
			data: { name: this.name, animal: this.animal }
		});

		dialogRef.afterClosed().subscribe(result => {

			this.animal = result;
		});
	}

	addNewConceptBoard() {
		const dialogRef = this.dialog.open(AddConceptBoardComponent, {
			panelClass: 'concept-modalbox'
		});
		dialogRef.afterClosed().subscribe();

	}


	openB2CForm() {
		const dialogRef = this.dialog.open(DesignConsultComponent, {
			maxHeight:window.outerHeight + 'px'
			//panelClass: 'concept-modalbox'
		});
		dialogRef.afterClosed().subscribe();
	}
}

@Component({
	selector: 'my-serach-dialog.component',
	templateUrl: 'my-serach-dialog.component.html',
	styleUrls: ['./main-nav.component.scss'],
})
export class MySearchDialogComponentDialog {

	constructor(
		private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<MySearchDialogComponentDialog>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) { }




	searchFormGroup() {
		return this.formBuilder.group({
			searchFaq: ['', [Validators.required]],
		});
	}

	ngOnInit() {
		this.faqSearchFormGroup = this.searchFormGroup();
		this.faqSearchFormGroup = this.formBuilder.group({
			searchFaq: ['', Validators.required]
		});
	}

	closeModal(): void {
		this.dialogRef.close();
	}

	faqSearchFormGroup: FormGroup;
}
