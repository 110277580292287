import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-customer-tracker',
  templateUrl: './customer-tracker.component.html',
  styleUrls: ['./customer-tracker.component.scss']
})
export class CustomerTrackerComponent implements OnInit {

  panelOpenState: boolean;

  tracker: any =[
    {
      type: "Messages",

    },
    {
			type: "Requirements",

		},
		{
			type: "Agreement",

    },
    {
      type: "Desgin",

    },
    {
      type: "Show Pending Activities/Tasks",

    },
    {
      type: "Show Estimate",

    },
    {
      type: "Show Walkthrough",

    },
    {
      type: "Holiday List",

    },
    {
      type: "Commercial Documents",

    },
    {
      type: "Payments",

    },
    {
      type: "Plans",

    },
    {
      type: "Violations",

    }
  ]
  
  trackerIcons: any = [
		{
			type: "Messages",
			icon: "email"
		},
		{
			type: "Requirements",
			icon: "border_color"
		},
		{
			type: "Agreement",
			icon: "insert_drive_file"
    },
    {
      type: "Desgin",
      icon: "create"
    },
    {
      type: "Show Pending Activities/Tasks",
      icon: "view_list"
    },
    {
      type: "Show Estimate",
      icon: "calculator"
    },
    {
      type: "Show Walkthrough",
      icon: "walk"
    },
    {
      type: "Holiday List",
      icon: "calendar-text"
    },
    {
      type: "Commercial Documents",
      icon: "file-document-box",
    },
    {
      type: "Payments",
      icon: "currency-usd"
    },
    {
      type: "Plans",
      icon: "home"
    },
    {
      type: "Violations",
      icon: "mdi mdi-exclamation"
    }
  ]
  

	@ViewChild("trackerSidebar") trackerSidebar: any;
	@ViewChild("prevtracker") prevtracker: any;
	@ViewChild("nexttracker") nexttracker: any;
	@ViewChild("trackerHeight") trackerHeight: any;
	@ViewChild("trackerHeading") trackerHeading: any;
	@ViewChild("trackerIconsWrap") trackerIconsWrap: any;
  @ViewChild("trackerMatIcon") trackerMatIcon: any;
  
  constructor() { }
  
  hideSidebar() {

		this.trackerSidebar.nativeElement.classList.add("hide");
		this.prevtracker.nativeElement.classList.add("hide");
		this.prevtracker.nativeElement.classList.remove("show");
		this.nexttracker.nativeElement.classList.remove("hide");
		this.nexttracker.nativeElement.classList.add("show");
		this.trackerHeight.nativeElement.classList.add("tracker-height");
		this.trackerHeading.nativeElement.classList.add("hide");
		this.trackerIconsWrap.nativeElement.classList.remove("hide");
		this.trackerIconsWrap.nativeElement.classList.add("show");

		this.trackerIconsWrap.nativeElement.classList.add("bg-icon");
	}

	showSidebar() {
		this.trackerSidebar.nativeElement.classList.remove("hide");

		this.prevtracker.nativeElement.classList.add("show");
		this.prevtracker.nativeElement.classList.remove("hide");
		this.nexttracker.nativeElement.classList.remove("show");
		this.nexttracker.nativeElement.classList.add("hide");
		this.trackerHeight.nativeElement.classList.remove("tracker-height");
		this.trackerHeading.nativeElement.classList.remove("hide");
		this.trackerIconsWrap.nativeElement.classList.remove("show");
		this.trackerIconsWrap.nativeElement.classList.add("hide");

		this.trackerIconsWrap.nativeElement.classList.remove("bg-icon");
	}
  ngOnInit() {
  }

  getIcon(_inputzz: String) {
    for (let c of this.trackerIcons) {
      if (c.type == _inputzz) {
        return c.icon;
      }
    }
  }
}
