import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation} from 'ngx-gallery';
import {SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {Router, ActivatedRoute} from '@angular/router';
import {ProductComponent} from '../product/product.component';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    FAQsContent: any = '<p>FAQs</p>';
    SpacificationContent: any = '<p>Specification</p>';
    productDetails: any;

    /** productDetails =
     {
			id: '1',
			name: 'Three Seat Sofa',
			longName: 'Long Three Seat Sofa',
			description: 'Description of Long Three Seat Sofa',
			longDescription: 'The collections are a series of modern designs, which are simple yet striking and represent the ideals of minimalism and cutting excess. The designs are a perfect blend of functionality and exceptional aesthetics. Each piece is crafted with passion and reflects quality and style, addressing the needs of a wide range of audience.',
			ownerName: 'SPACIFII',
			spacifiiPrize: '4000',
            originalPrize: '5000',
            discountPercentage: '10',
			image: [
              
                'assets/product/three-seater-sofa.jpg',
                'assets/product/three-seater-sofa.jpg',
                'assets/product/three-seater-sofa.jpg',
                'assets/product/three-seater-sofa.jpg'
                    				
			],
			details: [
				{
					key: 'Color',
					value: 'Red'
				},
				{
					key: 'Size',
					value: 'Three Seater'
                },
                {
					key: 'Dimensions',
					value: 'H 33 x W 85 x D 35; Seating Height-16; Seating Depth : 21.5 (all dimensions in inches)'
				},
				{
					key: 'Primary Material',
					value: 'Fabric'
                },
                {
					key: 'Weight',
					value: '52 KG'
				},
				{
					key: 'Warranty',
					value: '36 Months Warranty'
                },
                {
					key: 'Assembly',
					value: 'Carpenter Assembly'
				},
				{
					key: 'Room Type',
					value: 'Living Room'
                },
                {
					key: 'Height',
					value: '33.0 inches'
                },
                {
					key: 'Width',
					value: '85.0 inches'
                },
                {
					key: 'Depth',
					value: '35.0 inches'
                },
                {
					key: 'Seating Height',
					value: '16'
				},
			],
			overview: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using'
        }
     */


    similarProducts: any[] = [];
    recentlyViewedProducts: any[] = [];

    constructor(private router: Router, private route: ActivatedRoute,
                private productComponent: ProductComponent) {
    }

    productId;

    ngOnInit() {

        this.route.params.subscribe((params) => {

            this.productId = params['dynamicParam'];
            this.getSelectedProduct();
        });


        this.galleryOptions = [
            {
                width: '600px',
                height: '400px',
                thumbnailsColumns: 4,
                imageAnimation: NgxGalleryAnimation.Slide,
                previewZoom: true,
                previewRotate: true,
                arrowPrevIcon: 'fa fa-angle-left',
                arrowNextIcon: 'fa fa-angle-right',
                closeIcon: 'fa fa-times',
                fullscreenIcon: 'fa fa-arrows-alt',
                spinnerIcon: 'fa fa-spinner fa-3x fa-fw',
                previewFullscreen: true,
                previewBullets:true
            },
            // max-width 800
            {
                breakpoint: 768,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20,
                thumbnailsColumns: 3,
            },
            // max-width 400
            {
                breakpoint: 600,
                preview: false,
                thumbnailsColumns: 2,
            }
        ];


    }

    public config: SwiperConfigInterface = {};

    ngAfterViewInit() {
        this.config = {
            direction: 'horizontal',
            slidesPerView: 6,
            navigation: true,
            loop: false,
            effect: 'slide',
            spaceBetween: 10
        };
    }

    getSelectedProduct() {
        this.productDetails = this.productComponent.getProductDetailById(this.productId);
        this.updateGalleryImages();
        this.updateRecentlyViewed();
        this.updateSimilarProduct();
    }

    updateGalleryImages() {
        this.galleryImages = [];

        for (let c of this.productDetails.image) {
            var someObj = {
                small: c,
                medium: c,
                big: c
            };
            this.galleryImages.push(someObj);
        }

    }

    updateRecentlyViewed() {
        this.recentlyViewedProducts = [];
        this.recentlyViewedProducts = this.productComponent.getProductDetails();
    }

    updateSimilarProduct() {
        this.similarProducts = [];
        for (var i = 1; i < 5; i++) {
            this.similarProducts.push(this.recentlyViewedProducts[i]);
        }
        window.scrollTo(0, 0);

    }

    updateMyproductDetails(_input: string) {
        this.productId = _input;
        this.getSelectedProduct();

    }

}
