import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss']
})
export class PdfviewerComponent implements OnInit {
  @Input() docData: any;

  documentURL:any;

  constructor() { }

  ngOnInit() {
    this.initMethod();
  }

  initMethod(){
    this.documentURL =  this.docData.altUrl;
  }

  ngOnChanges(){
    this.initMethod();
  }

}
