import { Component,  ViewChild, AfterViewInit, NgModule } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-offerings',
  templateUrl: './offerings.component.html',
  styleUrls: ['./offerings.component.scss'],

})
export class OfferingsComponent implements AfterViewInit{

  public show: boolean = true;

  public testimonials = [
    {
      text: "'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'",
      image: 'assets/why-people-love-us/22.jpg',
      name: "Mihir Trivedi",
      detail: "Spacifii Mumbai - 01"
    },
    {
      text: "'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.'",
      image: 'assets/why-people-love-us/me.jpg',
      name: "Rohit Kanekar",
      detail: "Spacifii Mumbai - 02"
    }



  ];

  public type: string = 'component';

  public disabled: boolean = false;

  public config: SwiperConfigInterface = {};


  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,

  };

  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      pagination: this.pagination,
      autoplay: false,
      loop: true,
      effect: "slide",
      autoHeight: true
    }
  }
  
  constructor() { }
  

}

