import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecentlyService } from '../recently.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-recently',
  templateUrl: './recently.component.html',
  styleUrls: ['./recently.component.scss']
})
export class RecentlyComponent implements OnInit {

  recentlyImages= [
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    },
    {
      image: 'https://d5xltud2zi4gz.cloudfront.net/frontend/customer/we-create-great-things/18.png',
      title: 'Aavaas-The White house',
      location: 'Modern|Mumbai'
    }
  ]
  urlFilterKey: string = 'all';
  recentlySpacifiiProjectObj: any;
  defaultImage: any;
  constructor(
    private route: ActivatedRoute,
    private recentService: RecentlyService
  ) { 
    this.defaultImage = environment.defaultRecentlyPojectImage;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if(params['recentlyType']){
        this.urlFilterKey= params['recentlyType'];					
      }else{
        this.urlFilterKey = 'all';
      }
    });
    let _filter = {
      "page": 1,
      "size": 1000
    }
    this.getRecentlySpacifii(_filter);
  }

  getRecentlySpacifii(_filter){
    this.recentService.getRecentlySpacifii(_filter).subscribe((res:any)=>{
      this.recentlySpacifiiProjectObj = res.payload;
    },
    (error)=>{
      console.log("Error while feaching recently...");
    })
  }

}
