
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {QuizService} from '../../services/quiz.service';
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  constructor(private quizService:QuizService) { }
  form:FormGroup;
  ngOnInit() {
    this.form = new FormGroup({
      propertyType: new FormControl(),
      aboutUser: new FormControl(),
      roomType: new FormControl(),
      userName: new FormControl(),
      userCity: new FormControl(),
      userEmail: new FormControl(),
    });

  }
  
  submitForm() {
    const value = this.form.value;
    this.quizService.quizSave(this.form.value)
      .subscribe(arg => {
          console.log("submitted",arg);        
      });
 
    console.log('current form value: ', value);
  }


  moveToSelectedTab(tabName: string) {
    for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
        if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) {
            (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
        }
      }
  }
}
