import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AddConceptBoardComponent } from '../add-concept-board/add-concept-board.component';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import {ConceptboardService} from '../../conceptboard.service';
import {ConfirmationBoxComponent} from '../../../confirmation-box/confirmation-box.component';
import {ToastrService} from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import {LoginService} from '../../../../services/login.service';


@Component({
	selector: 'app-concept-board',
	templateUrl: './concept-board.component.html',
	styleUrls: ['./concept-board.component.scss']
})

export class ConceptBoardComponent implements OnInit {

	@Input() oPage: any;
	public defaultImage = environment.defaultConceptBoardImage;
	conceptBoards: any;
	publicConceptBoards: any;
	sharedwithMeConceptBoards:any;
	disabledConceptBoards:any;
	globalConceptBoards:any;
	addNewConceptBoardFormGroup: FormGroup;
	subjectId:any;
	myProfile:any;
	userProfile:any

	constructor(
		public dialog: MatDialog,
		private conceptBoardService: ConceptboardService,
		private loginService:LoginService,
		private router: Router,
		private toastr: ToastrService
		) { }

	ngOnInit() {

		this.myProfile = this.loginService.getMyProfile();
		//this.userProfile = this.myProfile.companyProfile;
		this.subjectId = this.myProfile.userProfile.subjectId;
		this.BindConceptBoards();
	}

	BindConceptBoards(){

		let searchObject = {
			"page": 1,
			"size": 12,
			"searchQuery": {
				"where": { "active": [true] }
			},
		};
		this.conceptBoardService.getConceptBoardViaSearch(searchObject).subscribe(res => {
			if (res.payload.content) {
				this.conceptBoards = res.payload.content;
			}
		});

		let publicSearchObject = {
			"page": 1,
			"searchQuery": {
				"where": { "conceptBoardVisibility": ["PUBLIC"],
					"active": [true]
				}
			},
			"size": 12
		};
		this.conceptBoardService.getConceptBoardViaSearch(publicSearchObject).subscribe(res => {
			if (res.payload.content) {
				this.publicConceptBoards = res.payload.content;
			}
		});

		let sharedsCBObject = {
			"page": 1,
			"searchQuery": {
				"where": { "active": [true]
				}
			},
			"size": 12
		};
		this.conceptBoardService.getSharedConceptBoardForMe(sharedsCBObject).subscribe(res => {

			if (res.payload != null) {
				this.sharedwithMeConceptBoards = res.payload.content;
			}
		});

		let disableSearchObject = {
			"page": 1,
			"searchQuery": {
				"where": { "active": [false] }
			},
			"size": 12
		};
		this.conceptBoardService.getConceptBoardViaSearch(disableSearchObject).subscribe(res => {
			if (res.payload) {
				this.disabledConceptBoards = res.payload.content;
			}
		});

		let GLOBALSearchObject = {
			"page": 1,
			"searchQuery": {
				"where": { "active": [true] }
			},
			"size": 12
		};
		this.conceptBoardService.paginatePublicConceptBoardSearch(GLOBALSearchObject).subscribe(res => {
			if (res.payload.content) {
				this.globalConceptBoards = res.payload.content;
			//	console.log(this.globalConceptBoards);
			}
		});


	}

	addNewConceptBoard() {
		const dialogRef = this.dialog.open(AddConceptBoardComponent, {
			panelClass: 'add-concept-board-modal'
		});
		dialogRef.afterClosed().subscribe();
	}


	disableConceptBoard(conceptBoard: any) {
		var todelete = false;
		const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
			data: {
				headerText: ' Concept Board',
				mainBodyText: "Are you sure you want to disable this Concept Board ?",
				confirmationButtonText: "Disable Concept Board"
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			todelete = result;
			if(todelete) {
				conceptBoard.active = false;
				this.conceptBoardService.updateConceptBoardDetails(conceptBoard).subscribe(res => {

						this.toastr.success("Concept Board Disabled", "");
						this.ngOnInit();
					},
					(error) => {
					});
			}
		});
	}


	enableConceptBoard(conceptBoard: any) {
		var todelete = false;
		const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
			data: {
				headerText: ' Concept Board',
				mainBodyText: "Are you sure you want to enable this Concept Board ?",
				confirmationButtonText: "Enable Concept Board"
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			todelete = result;
			if(todelete) {
				conceptBoard.active = true;
				this.conceptBoardService.updateConceptBoardDetails(conceptBoard).subscribe(res => {

						this.toastr.success("Concept Board Enabled", "");
						this.ngOnInit();
					},
					(error) => {
					});
			}
		});
	}

	makeConceptBoardPrivate(conceptBoard: any) {
		var todelete = false;
		const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
			data: {
				headerText: ' Concept Board',
				mainBodyText: "Are you sure you want to make this Concept Board Private?",
				confirmationButtonText: "Make Concept Board Private"
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			todelete = result;
			if(todelete) {
				conceptBoard.conceptBoardVisibility = "PRIVATE";
				this.conceptBoardService.updateConceptBoardDetails(conceptBoard).subscribe(res => {

						this.toastr.success("Concept Board is now Private", "");
						this.ngOnInit();
					},
					(error) => {
					});
			}
		});
	}

	makeConceptBoardPublic(conceptBoard: any) {
		var todelete = false;
		const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
			data: {
				headerText: ' Concept Board',
				mainBodyText: "Are you sure you want to make this Concept Board Public?",
				confirmationButtonText: "Make Concept Board Public"
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			todelete = result;
			if(todelete) {
				conceptBoard.conceptBoardVisibility = "PUBLIC";
				this.conceptBoardService.updateConceptBoardDetails(conceptBoard).subscribe(res => {

						this.toastr.success("Concept Board is now Public", "");
						this.ngOnInit();
					},
					(error) => {
					});
			}
		});
	}
}
