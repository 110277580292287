import {Component, OnInit, Inject, Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {EmbedVideoService} from 'ngx-embed-video/dist';

@Component({
	selector: 'app-open-video',
	templateUrl: './open-video.component.html',
	styleUrls: ['./open-video.component.scss']
})

export class OpenVideoComponent implements OnInit {
	@Input('currentMediaObject') currentMediaObject: any;
	url: any ;
	name: any ;
	url_html:any;


	constructor(
		private sanitizer: DomSanitizer,
		private embedService: EmbedVideoService
			)
	{


	/*	this.url = this.currentMediaObject.url;
		this.url_html = this.embedService.embed(this.url);*/
	}

	ngOnInit() {
		this.initMethod();
	}

	ngOnChanges(){
		this.initMethod();
	}

	initMethod(){
		this.name = this.currentMediaObject.name;
		this.url_html = this.embedService.embed(this.currentMediaObject.url,{
			query: { portrait: 0, color: '333' },
			attr: { width: 1024, height: 768 }
		});
	}
	validateURL(url: string) {
		if (url == undefined) { return; }
		url = url.replace("watch?v=", "v/");
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	/*closeModal(): void {
		this.dialogRef.close();
	}*/

}
