import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { environment } from '../../../src/environments/environment';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  constructor(private httpClient: HttpClient) { }


  async getOtpDTOForB2C(_reqData){
    return await this.httpClient.post(`${environment.registrationApi}/api/registration/registerB2C`,_reqData).toPromise()
  }

  saveContactDetailsB2C(_reqData):any{
    return this.httpClient.post(`${environment.registrationApi}/api/registration/registerB2C`,_reqData)
  }

  saveContactDetails(_reqData): any{
    return this.httpClient.post(`${environment.registrationApi}/api/registration/registerB2B`, _reqData);
  }

  validateOtpB2C(_reqData): any {
    return this.httpClient.post(`${environment.registrationApi}/api/registration/validateB2COTP`, _reqData);
  }

  validateOtp(_reqData): any {
    return this.httpClient.post(`${environment.registrationApi}/api/registration/validateB2BOTP`, _reqData);
  }
  setPassword(_reqData):any {
    return this.httpClient.post(`${environment.registrationApi}/api/registration/setPasswordForB2B`, _reqData);
  }

  setPasswordB2C(_reqData):any {
    return this.httpClient.post(`${environment.registrationApi}/api/registration/setPasswordForB2C`, _reqData);
  }

  saveCompany(_reqData):any {
    return this.httpClient.post(`${environment.registrationApi}/api/registration/saveCompanyInformation`, _reqData);
  }
}
