import { Component, OnInit, Input } from '@angular/core';
import { BlogService } from '../../pages/blog/blog.service';

@Component({
	selector: 'app-featured-articles',
	templateUrl: './featured-articles.component.html',
	styleUrls: ['./featured-articles.component.scss']
})
export class FeaturedArticlesComponent implements OnInit {
	@Input() spacifiiDesign:any;
	@Input() mySize:any;
	
	//POSIBLE VALUE OF SYLE IS "HOVER", "NORMAL", "RIBBON"
	topBlogObject: any;
	featuredHomeObject: any;
	featuredHomeRequestObject: any = {};
	hoverStyle :any = false;
	normalStyle : any = false;
	ribbonStyle: any = false;

	constructor(
		private blogService: BlogService
	) { 
		this.featuredHomeRequestObject = {
			"page": 1,
			"size": this.mySize
		};
	}

	ngOnInit() {


		
	switch(this.spacifiiDesign){
		case("HOVER"):{
			this.hoverStyle = true;
			this.normalStyle = false;
			this.ribbonStyle = false;
			break;

		} case("NORMAL"):{
			this.hoverStyle = false;
			this.normalStyle = true;
			this.ribbonStyle = false;
			break;
		} case("RIBBON"):{
			this.hoverStyle = false;
			this.normalStyle = false;
			this.ribbonStyle = true;
			break;
		}
	}

		// this.blogService.getFeaturedHomePost().subscribe((res) => {

		// 	this.topBlogObject = res.payload;
		// },
		// 	(error) => {
		// 	});
		//GET FEATURED API
		this.featuredHomeRequestObject.size = this.mySize 	 	;

		// this.blogService.getFeaturedBlog(this.featuredHomeRequestObject).subscribe((res) => {

		// 	this.featuredHomeObject = res.payload;
		// },
		// 	(error) => {
		// 	});
	}
	

}
